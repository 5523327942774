import { chain } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { buildStudyItemViewProps, StudyItemViewProps } from '../item';
import { StudyListControllerConfig } from './studyListConfig';
import { StudyListController } from './studyListInterface';

export function createStudyListController(
    config: StudyListControllerConfig = {}
): StudyListController {
    return {
        useProps(context, props) {
            const items = useMemo(
                () => chain(props.item.items).value(),
                [props.item.items]
            );

            const itemPropsById = useMemo(
                () =>
                    items.reduce(
                        (acc, item, index) => ({
                            ...acc,
                            [item.dataset.id]: buildStudyItemViewProps(context, item),
                        }),
                        {} as Record<string, StudyItemViewProps | undefined>
                    ),
                [context, items]
            );
            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.dataset.id];
                    assert(itemProps, `no item props`);
                    return itemProps;
                },
            };
        },
    };
}
