import { useMemo } from 'react';
import { isPeerGroupPending } from '../../../domain/peers';
import { useOrganizationContextV2, useWorkspaceContextV2 } from '../../../context';
import { AssetOnboardingState, AnyOnboardingAssetStep } from '../../../domain/onboarding';
import { OnboardingAssetStateServiceConfig } from './assetConfig';
import { OnboardingAssetStateService } from './assetInterface';
import { useSearchParams } from 'react-router-dom';
import { fromPairs } from 'lodash';
import { OnboardingAssetOptionsSchema } from './assetSchema';
import { OnboardingAssetOptions } from './assetModel';

export function createOnboardingAssetStateService(
    config: OnboardingAssetStateServiceConfig
): OnboardingAssetStateService {
    const { repository } = config;
    return {
        useLookup(context) {
            const {
                data: { workspaces },
            } = useOrganizationContextV2();
            const { peergroups: query, connections } = useWorkspaceContextV2();

            const [searchParams] = useSearchParams();

            const onboardingOptions = useMemo(() => {
                const raw = fromPairs([...searchParams.entries()]);
                const parsed = OnboardingAssetOptionsSchema.safeParse(raw);
                if (!parsed.success) {
                    return null;
                }
                const options: OnboardingAssetOptions = parsed.data;
                return options;
            }, [searchParams]);

            const preferences = repository.preference.useLookup(context, {}, {});
            // console.log('preferences', preferences.data);

            const shouldRedirectToPeers = useMemo(
                () =>
                    query.data?.every(isPeerGroupPending) &&
                    connections.data?.some((item) => item.status === 'ready'),
                [query.data, connections.data]
            );

            const onboardingstate = useMemo((): AssetOnboardingState => {
                let steps: AnyOnboardingAssetStep[] = [
                    {
                        id: 'mapping',
                        slug: 'mapping',
                        status: 'force',
                        config: null,
                    },
                ];
                // if (shouldRedirectToPeers) {
                steps = [
                    ...steps,
                    {
                        id: 'peer_groups',
                        slug: 'peer_groups',
                        status: shouldRedirectToPeers ? 'force' : 'required',
                        config: null,
                    },
                ];

                if (
                    preferences.data?.layout !== 'light' &&
                    onboardingOptions?.mode !== 'report'
                ) {
                    // skip the metric step for light layout mode as we currently
                    // use all available metrics for generating the audit report
                    steps = [
                        ...steps,
                        {
                            id: 'metrics',
                            slug: 'metrics',
                            status: 'required',
                            config: null,
                        },
                    ];
                }

                if (
                    preferences.data?.layout === 'light' ||
                    onboardingOptions?.mode === 'report'
                ) {
                    steps = [
                        ...steps,
                        {
                            id: 'report',
                            slug: 'report',
                            status: 'force',
                            config: null,
                        },
                    ];
                } else {
                    steps = [
                        ...steps,
                        {
                            id: 'prepare',
                            slug: 'prepare',
                            status: 'force',
                            config: null,
                        },
                    ];
                }
                return {
                    scope: 'asset',
                    asset: {
                        id: context.workspace.id as number,
                    },
                    steps,
                };
            }, [workspaces, preferences.data]);

            // console.log('onboarding state', onboardingstate);

            return {
                status: 'loaded',
                data: onboardingstate,
            };
        },
    };
}
