import React, { Fragment, Suspense } from 'react';
import {
    Box,
    Spinner,
    BoxProps,
    VStack,
    Center,
    Tooltip,
    HStack,
    Text,
    Tag,
    GridProps,
    Grid,
    GridItem,
    GridItemProps,
    Button,
    useDisclosure,
    Modal,
    ModalContent,
    ModalBody,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    Heading,
    IconButton,
    Icon,
    StackDivider,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverCloseButton,
    PopoverHeader,
    Stack,
    useToken,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';
import { HiAdjustmentsVertical } from 'react-icons/hi2';
import { PiUserFocus } from 'react-icons/pi';
import {
    SettingsIcon,
    InfoOutlineIcon,
    SmallCloseIcon,
    CloseIcon,
} from '@chakra-ui/icons';
import { GRAY } from '../../../../config';
import {
    getElements,
    fetchElements,
    getAllElements,
    getAllChildrenProps,
} from '../../../../util';
import { DashboardLayoutFilters } from './DashboardLayoutFilters';
import { DashboardLayoutMain } from './DashboardLayoutMain';
import { DashboardLayoutSegment } from './DashboardLayoutSegment';
import { DashboardLayoutActions } from './DashboardLayoutActions';
import { DashboardLayoutCompetitiveSet } from './DashboardLayoutCompetitiveSet';
import { DashboardLayoutStatus } from './DashboardLayoutStatus';
// import { DashboardSettingsList, DashboardSettingsItem } from './DashboardLayoutSettings';
import type { DashboardLayoutProps } from '../dashboardLayoutProps';
import { DashboardLayoutDate } from './DashboardLayoutDate';
import { DashboardControlItem, DashboardControlList } from './DashboardLayoutControl';
import { DashboardLayoutGroup } from './DashboardLayoutGroup';
import { HiOutlineFilter } from 'react-icons/hi';

export interface DashboardLayoutWrapperProps extends DashboardLayoutProps {
    style?: Partial<{
        wrapper: Omit<GridProps, 'children' | 'h'>;
        main: Omit<GridItemProps, 'children' | 'area'>;
        sidebar: Omit<GridItemProps, 'children' | 'area'>;
        scroll: Pick<BoxProps, '__css'>;
        competitive: Omit<GridItemProps, 'children' | 'area'>;
        segment: Omit<GridItemProps, 'children' | 'area'>;
        controls: Omit<GridProps, 'children' | 'area'>;
        actions: Omit<GridItemProps, 'children' | 'area'>;
        filters: Omit<GridItemProps, 'children' | 'area'>;
        cards: Omit<GridItemProps, 'children' | 'area'>;
    }>;
    children: Array<React.ReactElement | null>;
    stickyControls: boolean;
    showGroups: boolean;
    mode: 'fixed' | 'dynamic';
    onModeChange(value: 'fixed' | 'dynamic'): void;
}

export const DashboardLayoutWrapper: React.FC<DashboardLayoutWrapperProps> = (props) => {
    // const disclosure = useDisclosure();

    const { Filters, Date, Main } = fetchElements(props.children, {
        Filters: DashboardLayoutFilters,
        Date: DashboardLayoutDate,
        Main: DashboardLayoutMain,
    });

    const { Segment, CompetitiveSet, Actions, ControlList, Group } = getElements(
        props.children,
        {
            CompetitiveSet: DashboardLayoutCompetitiveSet,
            Segment: DashboardLayoutSegment,
            Group: DashboardLayoutGroup,
            Actions: DashboardLayoutActions,
            ControlList: DashboardControlList,
        }
    );

    const { Status } = getElements(props.children, {
        Status: DashboardLayoutStatus,
    });

    // const { SettingsItem } = getAllChildrenProps(SettingsList?.props.children, {
    //     SettingsItem: DashboardSettingsItem,
    // });

    const { ControlItem } = getAllChildrenProps(ControlList?.props.children, {
        ControlItem: DashboardControlItem,
    });

    // const VisibleSettingsItems = SettingsItem.filter(props.isVisible);
    const VisibleControlItems = ControlItem.filter(props.isVisible);

    if (
        // SettingsItem.length > 1 ||
        VisibleControlItems.length > 1
    ) {
        throw new Error('multiple settings items not yet implemented');
    }

    // const showSidebar = Segment || CompetitiveSet;
    const showSidebar = true;

    const scrollbarBg = useToken('colors', 'whiteAlpha.400');
    const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');

    const modes = ['dynamic', 'fixed'] as const;

    return (
        <>
            <Grid
                {...props.style?.wrapper}
                templateAreas={`
                    "sidebar main"
                `}
                templateColumns="minmax(18rem, 14.2vw) 1fr"
                // templateColumns={props.style?.wrapper.gridtem}
                // templateColumns={
                //     showSidebar
                //         ? props.style?.wrapper?.gridTemplateColumns ??
                //           props.style?.wrapper?.templateColumns
                //         : '1fr'
                // }
                h="full"
            >
                {showSidebar && (
                    <GridItem area="sidebar" h="full" position="relative">
                        <Grid
                            top={0}
                            pb="16rem"
                            position="sticky"
                            align-self="start"
                            zIndex={2}
                            overflowY="auto"
                            {...props.style?.sidebar}
                            {...props.style?.scroll}
                            css={{
                                scrollbarGutter: 'stable both-edges',
                                '&::-webkit-scrollbar': {
                                    '-webkit-appearance': 'none',
                                    width: '0.5rem',
                                },
                                '&::-webkit-scrollbar-track': {},
                                '&::-webkit-scrollbar-corner': {
                                    '-webkit-appearance': 'none',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: '1rem',
                                    background: scrollbarBg,
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    // background: scrollbarBg,
                                    background: scrollbarHoverBg,
                                },
                            }}
                            templateAreas={`
                            "header"
                            "segment"
                            "footer"
                        `}
                            h="100vh"
                            gap={8}
                        >
                            <GridItem
                                {...props.style?.competitive}
                                area="header"
                                top={0}
                                position="sticky"
                                align-self="start"
                                zIndex={3}
                            >
                                <VStack alignItems="flex-start" spacing={4}>
                                    <HStack spacing={2}>
                                        <Text
                                            color={props.style?.competitive?.color}
                                            fontWeight="bold"
                                            fontSize="md"
                                            textTransform="uppercase"
                                        >
                                            Competitive set
                                        </Text>
                                        <Tooltip
                                            label={
                                                <Text maxW={64}>
                                                    Choose which types of companies to
                                                    benchmark against
                                                </Text>
                                            }
                                        >
                                            <InfoOutlineIcon />
                                        </Tooltip>
                                    </HStack>
                                    <Tag
                                        w="full"
                                        variant="outline"
                                        boxShadow="none"
                                        minH={12}
                                        borderWidth="2px"
                                        borderStyle="solid"
                                        borderColor="whiteAlpha.300"
                                    >
                                        <Center w="full">
                                            <Suspense
                                                fallback={
                                                    <HStack
                                                        w="full"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Spinner h={4} w={4} />
                                                    </HStack>
                                                }
                                            >
                                                {CompetitiveSet}
                                            </Suspense>
                                        </Center>
                                    </Tag>
                                </VStack>
                            </GridItem>
                            <GridItem {...props.style?.segment} area="segment">
                                {!props.showGroups && Segment}
                                {props.showGroups && (
                                    <Tabs
                                        index={modes.findIndex(
                                            (item) => item === props.mode
                                        )}
                                        onChange={(index) => {
                                            const mode = modes[index];
                                            props.onModeChange(mode);
                                        }}
                                        isFitted={true}
                                    >
                                        <TabList>
                                            <Tab
                                                id="dynamic"
                                                fontWeight="medium"
                                                _focus={{ outline: 'none' }}
                                            >
                                                <Tooltip
                                                    openDelay={300}
                                                    placement="top"
                                                    hasArrow={true}
                                                    p={3}
                                                    bg="#1D1C21"
                                                    label="Build competitive-set dynamically using filters"
                                                >
                                                    <HStack spacing={2} fontSize="md">
                                                        <Icon
                                                            fontSize="md"
                                                            as={HiOutlineFilter}
                                                        />
                                                        <Text>Dynamic</Text>
                                                    </HStack>
                                                </Tooltip>
                                            </Tab>
                                            <Tab
                                                id="fixed"
                                                fontWeight="medium"
                                                _focus={{ outline: 'none' }}
                                            >
                                                <Tooltip
                                                    openDelay={300}
                                                    placement="top"
                                                    hasArrow={true}
                                                    p={3}
                                                    bg="#1D1C21"
                                                    label="Build competitive-set manually by searching for companies directly"
                                                >
                                                    <HStack spacing={2} fontSize="md">
                                                        <Icon
                                                            fontSize="lg"
                                                            as={PiUserFocus}
                                                        />
                                                        <Text>Fixed</Text>
                                                    </HStack>
                                                </Tooltip>
                                            </Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel id="dynamic" p={0} pt={4}>
                                                {Segment}
                                            </TabPanel>
                                            <TabPanel id="fixed" p={0} pt={4}>
                                                {Group}
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                )}
                            </GridItem>
                        </Grid>
                    </GridItem>
                )}
                <GridItem area="main" h="full">
                    <Grid
                        {...props.style?.main}
                        templateAreas={`
                            "controls"
                            "cards"
                        `}
                        h="full"
                    >
                        <GridItem
                            h="full"
                            area="controls"
                            {...props.style?.controls}
                            top={0}
                            position={props.stickyControls ? 'sticky' : undefined}
                            align-self="start"
                            zIndex={2}
                        >
                            <Grid
                                templateAreas={
                                    Actions
                                        ? `
                                    "actions"
                                    "filters"        
                                `
                                        : `"filters"`
                                }
                                gridTemplateRows="min-content min-content"
                            >
                                {Actions && (
                                    <GridItem
                                        area="actions"
                                        h="full"
                                        {...props.style?.actions}
                                    >
                                        <HStack justifyContent="flex-end" spacing={2}>
                                            <Box>{Actions}</Box>
                                        </HStack>
                                    </GridItem>
                                )}
                                <GridItem
                                    area="filters"
                                    h="full"
                                    {...props.style?.filters}
                                    {...ControlList?.props.as?.props}
                                >
                                    <Stack
                                        h="full"
                                        // alignItems={{ lg: 'center' }}
                                        align="start"
                                        spacing={{ sm: 3, lg: 8 }}
                                        justify={{ lg: 'space-between' }}
                                        direction={{ sm: 'column-reverse', lg: 'row' }}
                                    >
                                        <HStack
                                            h="full"
                                            align="center"
                                            {...Filters.props.as?.props}
                                        >
                                            {Filters}
                                        </HStack>
                                        <HStack
                                            justify={{
                                                sm: 'space-between',
                                                lg: 'flex-end',
                                            }}
                                            spacing={3}
                                            divider={
                                                <StackDivider
                                                    css={{
                                                        marginTop: '0.5rem !important',
                                                        marginBottom: '0.5rem !important',
                                                    }}
                                                    borderColor={{
                                                        sm: 'transparent',
                                                        lg: 'whiteAlpha.300',
                                                    }}
                                                    borderWidth="1px"
                                                />
                                            }
                                        >
                                            {VisibleControlItems.map((item) => (
                                                <React.Fragment key={item.id}>
                                                    {item.children}
                                                </React.Fragment>
                                            ))}
                                            <Box {...Date.props.as?.props}>{Date}</Box>
                                        </HStack>
                                    </Stack>
                                </GridItem>
                            </Grid>
                        </GridItem>
                        <GridItem
                            area="cards"
                            {...props.style?.cards}
                            {...props.style?.scroll}
                            h="full"
                            overflowY="auto"
                        >
                            {Status && <Box mb={3}>{Status}</Box>}
                            <Box {...Main.props.as?.props}>{Main}</Box>
                        </GridItem>
                    </Grid>
                </GridItem>
            </Grid>
            <Modal
                isOpen={props.disclosure.isOpen}
                onClose={props.disclosure.onClose}
                closeOnEsc={true}
                closeOnOverlayClick={true}
            >
                <ModalOverlay />
                <ModalContent borderRadius="sm" minW="33vw">
                    <ModalBody p={0}>
                        <Grid>
                            <GridItem bg="blackAlpha.500" py={3} px={4}>
                                <HStack justifyContent="space-between">
                                    <Heading fontSize="md">Advanced settings</Heading>
                                    <IconButton
                                        size="xs"
                                        aria-label="close modal"
                                        onClick={props.disclosure.onClose}
                                        icon={<CloseIcon />}
                                        bg="transparent"
                                        border="none"
                                        _hover={{ bg: 'whiteAlpha.100' }}
                                        _active={{ bg: 'whiteAlpha.100' }}
                                        _focus={{ outline: 'none' }}
                                    />
                                </HStack>
                            </GridItem>
                            <GridItem p={8}>
                                {/* <VStack alignItems="flex-start" w="full">
                                    {VisibleSettingsItems.map((item) => (
                                        <Fragment key={item.id}>{item.children}</Fragment>
                                    ))}
                                </VStack> */}
                            </GridItem>
                        </Grid>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
