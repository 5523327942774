import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { IntegrationDefinition, MappingConnection } from '../../../../../domain/assets';
import { assert } from '../../../../../util/assert';
import { IntegrationItemAggregate } from '../../../../../view';
import { SettingIntegrationConfig } from '../integrationConfig';
import { SettingIntegrationListLoader } from './listInterface';

export function createSettingIntegrationListLoader(
    config: Pick<SettingIntegrationConfig, 'repository'>
): SettingIntegrationListLoader {
    const { repository } = config;
    const limit = 500;
    return {
        useLoad(context) {
            const integrations = repository.integration.useFind(
                context,
                {},
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            const definitions = repository.definition.useFind(
                context,
                {},
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            const mappings = repository.mappings.useFind(
                context,
                {
                    integrations: integrations.data?.items ?? [],
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );

            const mappingsByIntegrationId = useMemo(() => {
                return groupBy(
                    mappings.data?.items,
                    (item) => item.integration.id
                ) as Record<string, MappingConnection[] | undefined>;
            }, [mappings.data?.items]);

            const definitionById = useMemo(
                () =>
                    definitions.data?.items.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.id]: item,
                        }),
                        {} as Record<string, IntegrationDefinition | undefined>
                    ) ?? {},
                [definitions.data?.items]
            );

            assert(integrations.status === 'success', 'expected suspense query');
            return useMemo(
                () => ({
                    status: 'loaded',
                    data: {
                        total: integrations.data.items.length,
                        limit,
                        items: integrations.data.items.flatMap(
                            (item): IntegrationItemAggregate[] => {
                                const definition =
                                    definitionById[item.definitionId] ?? null;
                                const mappings = mappingsByIntegrationId[item.id] ?? null;
                                if (!definition) {
                                    return [];
                                }
                                return [
                                    {
                                        integration: item,
                                        definition,
                                        mappings: mappings ?? [],
                                    },
                                ];
                            }
                        ),
                    },
                }),
                [integrations.data, mappingsByIntegrationId, definitionById]
            );
        },
    };
}
