import { chain } from 'lodash';
import { useMemo } from 'react';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { SubscriptionEditAlertListProps } from '../../../../../view/billing';
import { PageIds } from '../../../../../config';
import { SettingSubscriptionConfig } from '../subscriptionConfig';
import { SettingSubscriptionEditController } from './editInterface';
import { PLAN_URL_KEY } from './editConstant';

export function createSettingSubscriptionEditController(
    init: Pick<SettingSubscriptionConfig, 'controller'>,
    config: SettingAccountItemProviderConfig
): SettingSubscriptionEditController {
    const {
        controller: { subscription },
    } = init;
    const {
        api: { controller: pageController },
    } = config;
    return {
        useProps(context, deps, item, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ACCOUNT_SUBSCRIPTION,
                    breadcrumbs: [
                        {
                            label: 'Subscription',
                        },
                    ],
                },
            });

            const pageStatusProps = pageProps.getStatusProps();

            const editProps = subscription.edit.useProps(context, deps.edit, {
                item,
                status: pageStatusProps?.isDisabled
                    ? {
                          description: pageStatusProps.description,
                          error: pageStatusProps.error,
                          isDisabled: pageStatusProps.isDisabled,
                      }
                    : undefined,
            });
            const formProps = editProps.getFormStateProps();
            const viewAlertProps = editProps.getAlertListProps();
            const pageAlertProps = pageProps.getAlertProps();

            return {
                button: {
                    manage: {
                        onClick() {
                            props.onPortal();
                        },
                    },
                },
                getEditProps() {
                    return editProps;
                },
                getAlertProps(): SubscriptionEditAlertListProps {
                    if (pageAlertProps.items.length > 0) {
                        return pageAlertProps;
                    }
                    return viewAlertProps;
                },
                getFormProps() {
                    return {
                        onSubmit: formProps.handleSubmit(async (values) => {
                            try {
                                const response = await formProps.onSubmit(values);
                                deps.toast({
                                    kind: 'success',
                                    description: 'Subscription updated',
                                });
                                const [searchParams, setSearchParams] = deps.searchParams;
                                // clear the default plan in the url parameter after subscribing to a new plan
                                searchParams.delete(PLAN_URL_KEY);
                                setSearchParams(searchParams);
                                return response;
                            } catch (error) {
                                console.error(error);
                                deps.toast({
                                    kind: 'error',
                                    description: 'An error occured',
                                });
                                throw error;
                            }
                        }),
                    };
                },
            };
        },
    };
}
