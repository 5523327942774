import pluralize from 'pluralize';
import { useMemo } from 'react';
import { buildStatusProps } from '../../../../view/common';
import { PeerControllerConfig } from '../../base';
import { PeerMemberItemViewProps } from '../item';
import { getPeerMemberListStatus } from './peerMemberListHelper';
import { PeerMemberListController } from './peerMemberListInterface';
import { PeerMemberListViewProps } from './peerMemberListProps';

export function createPeerMemberListController(
    config: PeerControllerConfig
): PeerMemberListController {
    const {
        factory: {
            company: { build: getCompanyProps },
        },
    } = config;
    return {
        useProps(item, props): PeerMemberListViewProps {
            const invitations = item.items.filter((item) => !!item.invitation);
            const members = item.items.filter((item) => !!item.member);

            const count = {
                all: invitations.length + members.length,
                // member: members.length,
                // invitation: invitations.length,
            };

            const plural = {
                all: pluralize('company', count.all),
                // member: pluralize('member', count.member),
                // member: pluralize('member', count.member),
                // invitation: pluralize('invitation', count.invitation),
            };

            const status = useMemo(() => getPeerMemberListStatus(item), [item]);

            return {
                status:
                    status === 'ready'
                        ? buildStatusProps({
                              id: 'competitive_set_member',
                              value: status,
                              kind: 'success',
                              label: 'Ready',
                              description: null,
                              ordinal: 0,
                          })
                        : buildStatusProps({
                              id: 'competitive_set_member',
                              kind: 'warning',
                              value: status,
                              label: 'Companies are being processed',
                              description: `If there aren't the minimum companies needed (5), then that means we are working to get the companies that aren't yet on Varos to share their data so you're able to get the perfect view. How long it takes depends on their interest, but usually we'll have a good view within 1-2 weeks.`,
                              ordinal: 1,
                          }),
                count: {
                    // label:
                    //     count.invitation > 0
                    //         ? `${count.all} ${plural.all} (${count.invitation} pending)`
                    //         : `${count.all} ${plural.all}`,
                    label: `${count.all} ${plural.all}`,
                    member: {
                        label:
                            count.all === 0
                                ? 'No companies'
                                : `${count.all} ${plural.all}`,
                        // label:
                        //     count.member === 0
                        //         ? 'No members'
                        //         : `${count.member} ${plural.member}`,
                    },
                    invitation: null,
                    // count.invitation === 0
                    //     ? null
                    //     : {
                    //           label: `${count.invitation} pending ${plural.invitation}`,
                    //       },
                },
                items: item.items,
                getItemProps(item): PeerMemberItemViewProps {
                    if (item.invitation) {
                        return {
                            id: item.company.company.id,
                            company: getCompanyProps({ metadata: {} }, item.company),
                            status: null,
                            // status: buildStatusProps({
                            //     kind: 'warning',
                            //     label: 'Processing',
                            //     description: `Company has been invited to Varos and will become a member of the group once registered`,
                            //     ordinal: 0,
                            // }),
                        };
                    }
                    return {
                        id: item.company.company.id,
                        company: getCompanyProps({ metadata: {} }, item.company),
                        status: null,
                    };
                },
            };
        },
    };
}
