import { PeerControllerConfig } from '../../base';
import { PeerCompanyEditController } from '../../company';
import { PeerMemberEditViewProps } from '../../member';
import { PeerGroupCreateController } from './peerGroupCreateInterface';

export function createPeerGroupCreateController(
    config: PeerControllerConfig & {
        company: PeerCompanyEditController;
    }
): PeerGroupCreateController {
    const {
        company: { useProps: useCompanyProps },
    } = config;

    return {
        useProps(context, item, props): PeerMemberEditViewProps {
            const company = useCompanyProps(context, item.company, props.company);
            return {
                company,
            };
        },
    };
}
