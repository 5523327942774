import thumbnail from '../../../../images/report_thumbnail_creative_strategy.png';
import screenshot_number_of_ads from '../../../../images/report_detail_creative_stratey_number_of_ads.png';
import screenshot_number_of_new_ads from '../../../../images/report_detail_creative_stratey_number_of_new_ads.png';
import screenshot_percent_agency from '../../../../images/report_detail_creative_stratey_percent_agency.png';
import screenshot_percent_creartive_production from '../../../../images/report_detail_creative_stratey_percent_creative_production.png';
import { ReportV2Dto } from '../../../api';
import { DatasetSlugs } from '../../dataset';

const dimensionsWithDescriptions = [
    {
        key: 'creative_development_agency_percentage',
        title: 'Percentage of creative development executed by an agency',
        description: null,
    },
    {
        key: 'current_creative_projects',
        title: 'Types of creative projects currently working on',
        description: null,
    },
    {
        key: 'brand_building_vs_growth',
        title: 'Focus on brand-building vs growth/performance',
        description: null,
    },
    {
        key: 'marketing_budget_for_creative_production',
        title: 'Percentage of marketing budget dedicated to creative production',
        description: null,
    },
    {
        key: 'inspiration_for_creatives',
        title: 'Sources of inspiration for creatives',
        description: null,
    },
];

export const CreativeStrategyReport: ReportV2Dto = {
    id: 'creative_strategy',
    name: 'Creative Strategy',
    tagline: null,
    description: `See how top performers execute their creative strategy`,
    category: 'marketing',
    dataset: DatasetSlugs.CREATIVE_STRATEGY,
    traits: [],
    tags: ['strategy', 'creative'],
    dependencies: [
        {
            kind: 'survey',
            dataset: DatasetSlugs.CREATIVE_STRATEGY,
        },
    ],
    thumbnail: {
        description: null,
        url: thumbnail,
    },
    images: [
        {
            description: null,
            url: screenshot_number_of_ads,
        },
        {
            description: null,
            url: screenshot_number_of_new_ads,
        },
        {
            description: null,
            url: screenshot_percent_creartive_production,
        },
        {
            description: null,
            url: screenshot_percent_agency,
        }
    ],
    visualizations: dimensionsWithDescriptions.map((dimension) => ({
        key: dimension.key,
        kind: 'bar',
        name: dimension.title,
        description: dimension.description,
        aggregation: 'count',
        breakdown: {
            keys: [dimension.key],
        },
    })),
};
