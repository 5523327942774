import { z } from 'zod';

// Enums

export const AccountInvitationStatusSchema = z.union([
    z.literal('pending'),
    z.literal('ready'),
    z.literal('completed'),
    z.literal('canceled'),
]);

// Sources

export const AccountInvitationGroupSourceSchema = z.object({
    kind: z.literal('group'),
    group_ids: z.array(z.string()),
});

export const AccountInvitationAssetSourceSchema = z.object({
    kind: z.literal('asset'),
    asset_ids: z.array(z.number()),
});

export const AccountInvitationSourceSchema = z.discriminatedUnion('kind', [
    AccountInvitationGroupSourceSchema,
    AccountInvitationAssetSourceSchema,
]);

// Models

function getBaseInvitation() {
    return {
        inviter_user_id: z.number(),
        inviter_organization_id: z.number(),
        status: AccountInvitationStatusSchema,
        source: z.nullable(AccountInvitationSourceSchema),
    };
}

export const AccountFuzzyInvitationSchema = z.object({
    ...getBaseInvitation(),
    kind: z.literal('fuzzy'),
    text: z.string(),
});

export const AccountCompanyInvitationSchema = z.object({
    ...getBaseInvitation(),
    kind: z.literal('company'),
    company_id: z.string(),
});

export const AccountInternalInvitationSchema = z.object({
    ...getBaseInvitation(),
    kind: z.literal('internal'),
    email: z.string(),
    role: z.enum(['standard', 'admin']),
    first_name: z.nullable(z.string()),
    last_name: z.nullable(z.string()),
    invitee_user_id: z.nullable(z.number()),
    sent_count: z.number(),
    last_sent: z.date({ coerce: true }),
});

export const AccountExternalInvitationSchema = z.object({
    ...getBaseInvitation(),
    kind: z.literal('external'),
    email: z.string(),
    organization_name: z.optional(z.string()),
    url: z.optional(z.string()),
    first_name: z.nullable(z.string()),
    last_name: z.nullable(z.string()),
    company_id: z.nullable(z.string()),
    invitee_user_id: z.nullable(z.number()),
    sent_count: z.number(),
    last_sent: z.date({ coerce: true }),
});

export const AccountAnyInvitationSchema = z.discriminatedUnion('kind', [
    AccountFuzzyInvitationSchema,
    AccountCompanyInvitationSchema,
    AccountInternalInvitationSchema,
    AccountExternalInvitationSchema,
]);

// Responses

export const AccountInvitationSchema = z.object({
    id: z.string(),
    data: AccountAnyInvitationSchema,
    created_at: z.date({ coerce: true }),
    updated_at: z.optional(z.date({ coerce: true })),
});

export const AccountInvitationListSchema = z.object({
    data: z.array(AccountInvitationSchema),
});
