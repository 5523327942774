import { useMemo } from 'react';
import { PeerLoaderConfig } from '../../base';
import { PeerGroupCreateLoader } from './peerGroupCreateInterface';
import { PeerGroupCreateAggregate } from './peerGroupCreateModel';
import { PeerCompanyEditLoader } from '../../company';

export function createPeerGroupCreateLoader(
    config: PeerLoaderConfig & {
        company: PeerCompanyEditLoader;
    }
): PeerGroupCreateLoader {
    const { company: companyLoader } = config;
    return {
        useLoad(context, props): PeerGroupCreateAggregate {
            const companyData = companyLoader.useLoad(context, {
                form: props.form,
            });
            const aggregate = useMemo((): PeerGroupCreateAggregate => {
                return {
                    company: companyData,
                };
            }, [companyData]);
            return aggregate;
        },
    };
}
