import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { IntegrationStatus } from '../../../../domain/assets';
import { IntegrationListConfig } from './integrationListConfig';
import { IntegrationListController } from './integrationListInterface';
import {
    AssetListCollectionProps,
    AssetListSearchProps,
    IntegrationListViewProps,
} from './integrationListProps';
import { chain } from 'lodash';
import pluralize, { plural } from 'pluralize';
import { IntegrationItemControllerProps } from '../item';

export function createIntegrationListController(
    config: IntegrationListConfig
): IntegrationListController {
    const STATUS_ORDER_ASC: IntegrationStatus[] = [
        'pending',
        'disconnected',
        'analyzing',
        'importing',
        'syncing',
        'ready',
    ];
    return {
        useProps(context, deps, props): IntegrationListViewProps {
            const itemPropsById = useMemo(
                () =>
                    props.items.data.items.reduce((acc, item) => {
                        const itemProps: IntegrationItemControllerProps = {
                            item,
                        };
                        return {
                            ...acc,
                            [item.integration.id]: itemProps,
                        };
                    }, {} as Record<string, IntegrationItemControllerProps | undefined>),
                [props.items.data]
            );
            const filtered = useMemo(
                () =>
                    chain(props.items.data.items)
                        .filter((item) => {
                            return item.definition.mode === 'live';
                        })
                        .orderBy(
                            (item) => {
                                return [
                                    item.mappings.length > 0 ? 1 : -1,
                                    STATUS_ORDER_ASC.findIndex(
                                        (candidate) =>
                                            candidate == item.integration.status
                                    ),
                                    Number(item.integration.createdAt),
                                ];
                            },
                            ['desc', 'asc', 'desc']
                        )
                        .value(),
                [props.items.data.items]
            );

            const searchTerm = useMemo(
                () => deps.state.value.search.toLocaleLowerCase(),
                [deps.state.value.search]
            );

            const searched = useMemo(
                () =>
                    chain(filtered)
                        .filter(
                            (item) =>
                                (item.integration.key
                                    ?.toLowerCase()
                                    .includes(searchTerm) ||
                                    item.definition.name
                                        .toLowerCase()
                                        .includes(searchTerm)) ??
                                true
                        )
                        // .orderBy((item) => item.definition.name, 'asc')
                        .value(),
                [filtered, searchTerm]
            );

            const searchProps = useMemo<AssetListSearchProps>(
                () => ({
                    value: deps.state.value.search,
                    onChange(event) {
                        deps.state.onChange({
                            ...deps.state.value,
                            search: event.target.value,
                        });
                    },
                }),
                [deps.state.value.search]
            );

            const collectionProps = useMemo<AssetListCollectionProps>(
                () =>
                    searched.length === 0
                        ? {
                              status: 'empty',
                              label: null,
                          }
                        : {
                              status: 'loaded',
                              label: `${searched.length} found`,
                              //   label: `${searched.length} ${pluralize(
                              //       'integration',
                              //       searched.length
                              //   )} found`,
                          },
                [searched]
            );

            return {
                items: searched,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.integration.id];
                    assert(
                        itemProps,
                        `no item props found for integration ${item.integration.id}`
                    );
                    return itemProps;
                },
                getSearchProps() {
                    return searchProps;
                },
                getCollectionProps() {
                    return collectionProps;
                },
            };
        },
    };
}
