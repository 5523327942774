import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { OrganizationResource } from './organizationInterface';
import { OrganizationSchema } from './organizationSchema';

export function createOrganizationResource(client: AxiosInstance): OrganizationResource {
    return {
        async get(context, organizationId) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};

            const response = await client.get<unknown>(
                `/api/v1/organizations/${organizationId}`,
                {
                    params,
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );

            const parsed = OrganizationSchema.parse(response.data);
            return parsed;
        },
        async update(context, organizationId, payload) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};

            const response = await client.patch(
                `/api/v1/organizations/${organizationId}`,
                payload,
                {
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );

            const parsed = OrganizationSchema.parse(response.data);
            return parsed;
        },
    };
}
