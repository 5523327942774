import { AxiosInstance } from 'axios';
import { evaluateFilter } from '../../../../api';
import { MetadataRule } from '../../../../models/Metadata';
import { castConditionToApi } from '../../../../v2/impl';
import { LegacyAuthenticationScheme } from '../../common';
import { CompetitiveSetResource } from './competitiveInterface';
import { CompetitiveSetSchema } from './competitiveSchema';

export function createCompetitiveSetResource(
    client: AxiosInstance
): CompetitiveSetResource {
    return {
        async create(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const response = await evaluateFilter(
                client,
                query.asset,
                {
                    operator: 'and',
                    sub_rules: query.condition
                        // .filter((condition) => condition.value)
                        .map((condition): MetadataRule => {
                            const mapped = castConditionToApi(condition);
                            return {
                                metadata_definition_key: mapped.key,
                                operator: mapped.operator,
                                value: mapped.value as any,
                            };
                        }),
                },
                // dashboard does not matter
                // 'facebookads-v2',
                query.dashboard,
                {
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );

            const parsed = CompetitiveSetSchema.parse(response.data);

            return parsed.evaluated_quality;
        },
    };
}
