import { buildStudyItemViewProps } from '../../../../view/studies';
import { PageIds } from '../../../../config';
import { StudiesDetailRouteConfig } from './studiesReportDetailConfig';
import { StudiesDetailController } from './studiesReportDetailInterface';
import { useMemo } from 'react';

export function createStudiesDetailController(
    config: Pick<StudiesDetailRouteConfig, 'controller'>
): StudiesDetailController {
    const {
        controller: { page: pageController, help: helpController, image: imageConroller },
    } = config;
    return {
        useProps(context, item, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.REPORTS_DETAIL,
                    breadcrumbs: [],
                },
            });

            const imageProps = imageConroller.useProps(item.image, {});

            const helpProps = helpController.useProps({
                id: 'request_a_report',
            });

            const studyProps = useMemo(
                () => buildStudyItemViewProps(context, item.study),
                [context, item.study]
            );

            return {
                page: pageProps,
                image: imageProps,
                study: studyProps,
                help: helpProps,
                links: {
                    response:
                        studyProps.status.access.kind === 'available'
                            ? {
                                  to: `/u/assets/${context.workspace.id}/reporting/reports/${item.study.report.id}/data`,
                              }
                            : {
                                  to: `/u/assets/${context.workspace.id}/reporting/reports/${item.study.report.id}`,
                              },
                },
            };
        },
    };
}
