import { useEffect, useMemo, useRef, useState } from 'react';
import { BsPercent } from 'react-icons/bs';
import { HiOutlineHashtag } from 'react-icons/hi';
import { assert } from '../../../../util/assert';
import { buildMetricItemProps } from '../../../../view';
import { StudiesBaseRouteConfig } from '../../base';
import { StudiesResponseItemController } from './studiesResponseItemInterface';
import { StudiesResponseItemViewProps } from './studiesResponseItemProps';

export function createStudiesResponseItemController(
    config: Pick<StudiesBaseRouteConfig, 'controller'>
): StudiesResponseItemController {
    const RelativeIcon = BsPercent;
    const AbsoluteIcon = HiOutlineHashtag;
    return {
        useProps(context, item, props): StudiesResponseItemViewProps {
            const [property, ...rest] = item.dataset.configuration.schema.filter(
                (candidate) =>
                    item.visualization.breakdown?.properties.some(
                        (property) => property.key === candidate.key
                    )
            );

            assert(property, () => {
                console.info(
                    'item.dataset.configuration.schema',
                    item.dataset.configuration.schema
                );
                return `no property matches visualization breakdown properties ${item.visualization.breakdown?.properties.map((item) => item.key).join(', ')}`;
            });

            const visualizationKey = useMemo(() => {
                return JSON.stringify({ ...item.visualization, ...props.control });
            }, [item.visualization, props.control]);

            return {
                errorBoundary: {
                    key: visualizationKey,
                },
                metric: {
                    label: item.visualization.name ?? 'N/A',
                    description: item.visualization.description,
                },
                question: {
                    label: property.name,
                },
                segment: {
                    options: [
                        {
                            onClick: props.segment.onChange.bind(null, 'all'),
                            isSelected: props.segment.value === 'all',
                            label: 'All responses',
                            isDisabled: false,
                            disabledReason: null,
                            Icon: AbsoluteIcon,
                        },
                        // {
                        //     onClick: props.segment.onChange.bind(null, 'peers'),
                        //     isSelected: props.segment.value === 'peers',
                        //     label: 'Peer group',
                        //     isDisabled: props.control.segment.length === 0,
                        //     disabledReason: `No peer group filters specified`,
                        //     Icon: RelativeIcon,
                        // },
                    ],
                },
                mode:
                    item.visualization.kind === 'bar' &&
                    item.visualization.aggregation === 'count'
                        ? {
                              options: [
                                  {
                                      onClick: props.mode.onChange.bind(null, 'absolute'),
                                      isSelected: props.mode.value === 'absolute',
                                      label: 'Responses',
                                      Icon: AbsoluteIcon,
                                  },
                                  {
                                      onClick: props.mode.onChange.bind(null, 'relative'),
                                      isSelected: props.mode.value === 'relative',
                                      label: 'Percentage',
                                      Icon: RelativeIcon,
                                  },
                              ],
                          }
                        : null,
            };
        },
    };
}
