import { compose } from 'lodash/fp';
import { createAutohideMiddleware } from './autohide';
import { ApplicationMiddlewareConfig } from './middlewareConfig';
import { createPreloadImagesMiddleware } from './preload-images';
import { createCompanyRecommendationEnhancer } from './recommendation';

export function createApplicationMiddleware(config: ApplicationMiddlewareConfig) {
    return compose(
        createAutohideMiddleware(config.autohide),
        createPreloadImagesMiddleware(),
        createCompanyRecommendationEnhancer()
    );
}
