import { useState, useEffect, useMemo } from 'react';
import { PeerGroupCreateStateProvider } from './peerGroupCreateInterface';
import { PeerGroupCreateStateValues } from './peerGroupCreateModel';

export function createPeerGroupCreateStateProvider(): PeerGroupCreateStateProvider {
    return {
        useState(context) {
            const [value, setValue] = useState<PeerGroupCreateStateValues>({
                search: '',
            });
            return {
                value,
                onChange: setValue,
            };
        },
    };
}
