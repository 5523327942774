import React from 'react';
import { PeerRouteConfig } from '../../base';
import {
    PeerCompanySelectController,
    PeerCompanySelectLoader,
} from './peerCompanySelectInterface';
import {
    PeerCompanySelectContainerProps,
    PeerCompanySelectViewProps,
} from './peerCompanySelectProps';

export function createPeerCompanySelectContainer(
    config: PeerRouteConfig,
    loader: PeerCompanySelectLoader,
    controller: PeerCompanySelectController,
    View: React.FC<PeerCompanySelectViewProps>
): React.FC<PeerCompanySelectContainerProps> {
    const {
        context: { useContext },
    } = config;
    return (props) => {
        const context = useContext();
        const data = loader.useLoad(context, props);
        const viewProps = controller.useProps(context, data, props);
        return <View {...viewProps} />;
    };
}
