import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { OptionItem, OptionListItemProps } from '../item';
import { OptionListController } from './optionListInterface';
import { OptionListControllerProps } from './optionListProps';

export function createOptionListController<
    TValue extends string,
>(): OptionListController<TValue> {
    function buildItemProps<TValue extends string>(
        props: OptionListControllerProps<TValue>,
        item: OptionItem<TValue>
    ): OptionListItemProps {
        return {
            id: item.value,
            title: item.label,
            description: item.description ?? null,
            isDisabled: item.isDisabled,
            isSelected: props.value !== null && props.value === item.value,
            Icon: item.Icon,
            container: {
                onClick: props.onChange.bind(null, item.value),
                cursor: 'pointer',
            },
        };
    }
    return {
        useProps(props) {
            const itemPropsByValue = useMemo(() => {
                return props.items.reduce(
                    (acc, item) => ({
                        ...acc,
                        [item.value]: buildItemProps(props, item),
                    }),
                    {} as Record<string, OptionListItemProps | undefined>
                );
            }, [props]);
            return {
                items: props.items,
                getItemProps(item) {
                    const itemProps = itemPropsByValue[item.value];
                    assert(itemProps, 'no item props');
                    return itemProps;
                },
            };
        },
    };
}
