import {
    Box,
    ButtonGroup,
    Text,
    Tooltip,
    Button,
    Icon,
    Flex,
    VStack,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { OnboardingOrganizationRouteStrategy } from '../../../route';
import { OnboardingIntegrationConfig } from './onboardingIntegrationConfig';
import { AiOutlineArrowRight } from 'react-icons/ai';
import {
    IntegrationCardListView,
    OnboardingIntegrationItemView,
} from '../../../../views';
export function createOnboardingIntegrationItem(
    config: OnboardingIntegrationConfig
): OnboardingOrganizationRouteStrategy {
    const { router, service, loader, hooks } = config;
    const controller = config.provider.createController(config);
    return {
        id: 'integration',
        name: 'Add integrations',
        slug: 'integration',
        create(config) {
            const {
                Layout: { Container, Content },
            } = config;
            return {
                Component: observer((props) => {
                    const organization = hooks.useOrganization();
                    const auth = hooks.useAuth();
                    const viewProps = controller.useProps({ auth, organization }, {});
                    const formProps = viewProps.getFormProps();
                    return (
                        <Container
                            title="Connect data sources"
                            description={
                                <Text>
                                    Connect all your data sources to get both platform
                                    specific and blended benchmarks. It takes just a few
                                    seconds for each
                                </Text>
                            }
                            rightContent={
                                <ButtonGroup>
                                    <Tooltip
                                        placement="top"
                                        hasArrow={true}
                                        label={
                                            <Box p={2}>
                                                Connect at least one integration to
                                                continue
                                            </Box>
                                        }
                                        isDisabled={formProps.isSubmittable}
                                    >
                                        <span>
                                            <Button
                                                colorScheme="green"
                                                onClick={formProps.onSubmit}
                                                isDisabled={!formProps.isSubmittable}
                                                rightIcon={
                                                    <Icon as={AiOutlineArrowRight} />
                                                }
                                            >
                                                Continue
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </ButtonGroup>
                            }
                        >
                            <Content isLoading={false}>
                                <Box py={6}>
                                    <IntegrationCardListView
                                        listProps={{ spacing: 5 }}
                                        {...viewProps.getViewListProps()}
                                        renderItem={({ definition }) => (
                                            <OnboardingIntegrationItemView
                                                key={definition.id}
                                                {...viewProps.getViewItemProps(
                                                    definition
                                                )}
                                            />
                                        )}
                                    />
                                </Box>
                            </Content>
                        </Container>
                    );
                }),
            };
        },
    };
}
