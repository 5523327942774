import { PluginIcons } from '../../../config';
import { PluginDto } from '../../../api';
import { PluginEntity } from '../../../domain';
import { castViewPropertyToDomain } from '../../api';

export function toDomainPlugin(plugin: PluginDto): PluginEntity | Error {
    const iconUrl = PluginIcons[plugin.id];
    if (!iconUrl) {
        return new Error(
            `plugin' ${plugin.id}' does not have an icon url specified, skipping...`,
            PluginIcons
        );
    }
    return {
        ...plugin,
        iconUrl,
        traits: [
            ...plugin.traits.map(castViewPropertyToDomain),
            // {
            //     key: 'asset',
            //     type: {
            //         kind: 'asset',
            //     },
            //     name: 'Company',
            //     description: null,
            //     constraints: [],
            //     isDisabled: false,
            //     disabledReason: null,
            // },
        ],
        isDisabled: false,
        disabledReason: null,
    };
}
