import { useForm } from 'react-hook-form';
import { PeerMemberEditFormProvider } from './peerMemberEditInterface';
import { PeerMemberEditFormValues } from './peerMemberEditModel';

export function createPeerMemberEditFormProvider(): PeerMemberEditFormProvider {
    return {
        useForm(context) {
            const form = useForm<PeerMemberEditFormValues>({
                defaultValues: {
                    companies: [],
                },
            });
            return form;
        },
    };
}
