import { useMemo } from 'react';
import { getCollectionItems, LoadedLookup, lookupFromQuery } from '../../../../base';
import { assert } from '../../../../util/assert';
import { applyFiltersToReport } from '../../../../app';
import { PeerFilterListAggregate } from '../../../../view/peer';
import { isReferenceType } from '../../../../domain/attributes';
import { keyBySafe } from '../../../../util';
import { resolveDatasetTypes } from '../../../../app/datasets';
import { StudiesBaseRouteConfig } from '../../base';
import { StudiesResponseDetaillRouteDetailLoader } from './studiesResponseDetailInterface';
import {
    StudiesResponseDetailAggregate,
    StudiesResponseDetailItemAggregate,
} from './studiesResponseDetailModel';

export function createStudiesResponseDetailLoader(
    config: Pick<StudiesBaseRouteConfig, 'repository'>
): StudiesResponseDetaillRouteDetailLoader {
    const {
        repository: {
            asset: assetRepository,
            member: memberRepository,
            submission: submissionRepository,
            dataset: datasetRepository,
            report: reportRepository,
            count: countRepository,
        },
    } = config;
    return {
        useLoad(context, props) {
            const assetQuery = assetRepository.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );

            const reportQuery = reportRepository.useLookup(context, props.report, {
                suspense: true,
            });

            const submissionsQuery = submissionRepository.useFind(
                context,
                {
                    asset: { id: context.workspace.id as number },
                },
                {
                    suspense: true,
                }
            );

            assert(submissionsQuery.status === 'success', 'expected suspese');
            assert(reportQuery.status === 'success', 'expected suspese');

            const report = useMemo(() => {
                return applyFiltersToReport(reportQuery.data, props.control);
            }, [reportQuery.data.dataset.id, reportQuery.data, props.control]);

            const peer = useMemo(
                (): PeerFilterListAggregate => ({
                    traits: report?.traits ?? [],
                    status: null,
                }),
                []
            );

            assert(
                report,
                `no matching report found for dataset '${reportQuery.data.dataset.id}'`
            );

            const datasetQuery = datasetRepository.useLookup(
                context,
                reportQuery.data.dataset,
                {
                    suspense: true,
                }
            );

            const memberQuery = memberRepository.useFind(
                context,
                {
                    workspace: context.workspace,
                    types:
                        datasetQuery.data?.configuration.schema.flatMap((item) =>
                            isReferenceType(item.type) ? item.type : []
                        ) ?? [],
                },
                { suspense: true, staleTime: Infinity }
            );

            assert(memberQuery.status === 'success', 'expected suspese');
            assert(assetQuery.status === 'success', 'expected suspese');
            assert(datasetQuery.status === 'success', 'expected suspese');

            const countQuery = countRepository.useLookup(
                context,
                [
                    {
                        kind: 'data_ingestion.dataset_member',
                        object: datasetQuery.data.id,
                    },
                ],
                { suspense: true }
            );
            assert(countQuery.status === 'success', 'expected suspese');

            // assert(groupQuery.status === 'success', 'expected suspese');

            // console.log('memberQuery', memberQuery.data);
            // console.log('datasetQuery', datasetQuery.data);

            const resolvedDataset = useMemo(
                () => resolveDatasetTypes(datasetQuery.data, memberQuery.data),
                [datasetQuery.data, memberQuery.data]
            );

            const submissionsByDataset = useMemo(
                () => keyBySafe(submissionsQuery.data, (item) => item.dataset.id),
                [submissionsQuery.data]
            );

            const countByDataset = useMemo(
                () => keyBySafe(countQuery.data, (item) => item.object),
                [countQuery.data]
            );

            // console.log('resolvedDataset', resolvedDataset);

            const lookup = useMemo((): LoadedLookup<StudiesResponseDetailAggregate> => {
                return {
                    status: 'loaded',
                    data: {
                        report: {
                            asset: {
                                id: context.workspace.id as number,
                            },
                            report,
                            dataset: resolvedDataset,
                            count: {
                                respondents: countByDataset[resolvedDataset.id] ?? null,
                            },
                            view: null,
                            submission: submissionsByDataset[resolvedDataset.id] ?? null,
                        },
                        peer,
                        // submission: {
                        //     // group,
                        //     submission: submissionsByDataset[resolvedDataset.id] ?? null,
                        //     dataset: resolvedDataset,
                        //     data: null,
                        //     respondents: null,
                        // },
                        items: report.visualizations.map(
                            (visualization): StudiesResponseDetailItemAggregate => ({
                                dataset: resolvedDataset,
                                report,
                                visualization,
                            })
                        ),
                    },
                };
            }, [
                context,
                submissionsByDataset,
                countByDataset,
                resolvedDataset,
                assetQuery.data,
                report,
                context.workspace.id,
                // groupQuery.data,
                // datasetViews,
                // datasetMetrics,
            ]);

            return lookup;
        },
    };
}
