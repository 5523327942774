import { CardIds } from '../../../card';
import { QueryDto } from '../../../../api';

export const SHOPIFY_ORDERS_QUERY_OVERRIDES: Pick<
    QueryDto,
    'source' | 'projections' | 'aggregations' | 'flags'
> = {
    source: {
        // view: 1421,
        view: 'shopify_v2_orders',
    },
    projections: [
        {
            name: 'absolute',
            columns: [
                // {
                //     key: 'segment',
                // },
                {
                    key: 'repeated_orders_ratio',
                    type: {
                        kind: 'percent',
                        fraction: true,
                    },
                },
                {
                    key: 'refunds_rate',
                    type: {
                        kind: 'percent',
                        fraction: true,
                    },
                },
                {
                    key: 'aov',
                    type: {
                        kind: 'currency',
                        currency: 'usd',
                        constraints: [],
                    },
                },
            ],
        },
        {
            name: 'relative',
            columns: [
                // {
                //     key: 'segment',
                // },
                {
                    key: 'repeated_orders_ratio',
                    expr: '(repeated_orders_ratio - compare(repeated_orders_ratio)) / compare(repeated_orders_ratio)',
                    type: {
                        kind: 'movement',
                    },
                },
                {
                    key: 'refunds_rate',
                    expr: '(refunds_rate - compare(refunds_rate)) / compare(refunds_rate)',
                    type: {
                        kind: 'movement',
                    },
                },
                {
                    key: 'aov',
                    expr: '(aov - compare(aov)) / compare(aov)',
                    type: {
                        kind: 'movement',
                    },
                },
            ],
        },
        {
            name: 'previous',
            columns: [
                // {
                //     key: 'segment',
                // },
                {
                    key: 'repeated_orders_ratio',
                    expr: 'compare(repeated_orders_ratio)',
                    type: {
                        kind: 'percent',
                        fraction: true,
                    },
                },
                {
                    key: 'refunds_rate',
                    expr: 'compare(refunds_rate)',
                    type: {
                        kind: 'percent',
                        fraction: true,
                    },
                },
                {
                    key: 'aov',
                    expr: 'compare(aov)',
                    type: {
                        kind: 'currency',
                        currency: 'usd',
                        constraints: [],
                    },
                },
            ],
        },
    ],
    aggregations: [
        {
            title: 'Repeating Order Ratio',
            name: 'repeated_orders_ratio',
            expr: {
                kind: 'binary',
                operator: '/',
                left: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: 'repeated_count',
                },
                right: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: 'orders_count',
                },
            },
        },
        {
            title: 'Refunds Rate',
            name: 'refunds_rate',
            inverse: true,
            expr: {
                kind: 'binary',
                operator: '/',
                left: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: 'count_included_refund',
                },
                right: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: 'orders_count',
                },
            },
        },
        {
            title: 'AOV',
            name: 'aov',
            expr: {
                kind: 'binary',
                operator: '/',
                left: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: 'sum_revenue',
                },
                right: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: 'orders_count',
                },
            },
        },
    ],
};

export function SHOPIFY_ABANDONED_CHECKOUT_QUERY_OVERRIDES(
    base: QueryDto
): Pick<QueryDto, 'source' | 'projections' | 'aggregations' | 'filters'> {
    return {
        source: {
            // view: 1422,
            view: 'shopify_v2_abandoned_checkout',
        },
        filters: base.filters?.filter((filter) =>
            ['TS', 'SHIPPING_ADDRESS_COUNTRY_CODE'].includes(filter.key.toUpperCase())
        ),
        projections: [
            {
                name: 'absolute',
                columns: [
                    // {
                    //     key: 'segment',
                    // },
                    {
                        key: 'abandoned_checkout_rate',
                        type: {
                            kind: 'percent',
                            fraction: true,
                        },
                    },
                ],
            },
            {
                name: 'relative',
                columns: [
                    // {
                    //     key: 'segment',
                    // },
                    {
                        key: 'abandoned_checkout_rate',
                        expr: '(abandoned_checkout_rate - compare(abandoned_checkout_rate)) / compare(abandoned_checkout_rate)',
                        type: {
                            kind: 'movement',
                        },
                    },
                ],
            },
            {
                name: 'previous',
                columns: [
                    // {
                    //     key: 'segment',
                    // },
                    {
                        key: 'abandoned_checkout_rate',
                        expr: 'compare(abandoned_checkout_rate)',
                        type: {
                            kind: 'percent',
                            fraction: true,
                        },
                    },
                ],
            },
        ],
        aggregations: [
            {
                title: 'Abandoned Checkout Rate',
                name: 'abandoned_checkout_rate',
                inverse: true,
                expr: {
                    kind: 'binary',
                    operator: '-',
                    left: {
                        kind: 'integer',
                        value: 1,
                    },
                    right: {
                        kind: 'binary',
                        operator: '/',
                        left: {
                            kind: 'aggregation',
                            type: 'sum',
                            column: 'order_count_ab_checkout_rate',
                        },
                        right: {
                            kind: 'binary',
                            operator: '+',
                            left: {
                                kind: 'aggregation',
                                type: 'sum',
                                column: 'ab_checkouts_count',
                            },
                            right: {
                                kind: 'aggregation',
                                type: 'sum',
                                column: 'order_count_ab_checkout_rate',
                            },
                        },
                    },
                },
            },
        ],
    };
}

export function SHOPIFY_REVENUE_GROWTH_QUERY_OVERRIDES(
    base: QueryDto
): Pick<QueryDto, 'source' | 'projections' | 'aggregations' | 'filters'> {
    return {
        // flags: { compare: true },
        source: {
            // view: 1420,
            view: 'shopify_v2_orders',
        },
        filters: base.filters?.filter((filter) =>
            ['TS', 'SHIPPING_ADDRESS_COUNTRY_CODE', 'NEW_VS_REPEAT'].includes(filter.key)
        ),
        projections: [
            {
                name: 'absolute',
                columns: [
                    // {
                    //     key: 'segment',
                    // },
                    {
                        key: 'sum_revenue',
                        type: {
                            kind: 'currency',
                            currency: 'usd',
                            constraints: [],
                        },
                    },
                ],
            },
            {
                name: 'relative',
                columns: [
                    {
                        key: 'sum_revenue',
                        expr: '(sum_revenue - compare(sum_revenue)) / compare(sum_revenue)',
                        type: {
                            kind: 'movement',
                        },
                    },
                ],
            },
            {
                name: 'previous',
                columns: [
                    // {
                    //     key: 'segment',
                    // },
                    {
                        key: 'sum_revenue',
                        expr: 'compare(sum_revenue)',
                        type: {
                            kind: 'currency',
                            currency: 'usd',
                            constraints: [],
                        },
                    },
                ],
            },
        ],
        aggregations: [
            {
                title: 'Revenue',
                name: 'sum_revenue',
                expr: {
                    kind: 'aggregation',
                    type: 'sum',
                    column: 'sum_revenue',
                },
            },
        ],
    };
}
