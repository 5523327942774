import { assert } from '../../../util/assert';
import { AnyVisualization } from '../../../domain/visualization';
import { StudyConstant } from '../../../config';
import { FormSubmissionEntity } from '../../forms';
import { CountEntity } from '../../platform';
import { ReportV2Entity } from './reportEntity';
import { ReportControl, ReportDependencyStatus, ReportAccessStatus } from './reportModel';
import { isReportStudyDependency } from './reportGuard';

export function applyFiltersToReport(
    report: ReportV2Entity,
    control: ReportControl
): ReportV2Entity {
    return {
        ...report,
        visualizations: report.visualizations.map((item): AnyVisualization => {
            return {
                ...item,
                period: control.date,
            };
        }),
    };
}

export function getReportDependencyStatus(item: {
    report: ReportV2Entity;
    submission: FormSubmissionEntity | null;
}): ReportDependencyStatus {
    const hasSubmission = item.report.dependencies.some(
        (dependency) =>
            dependency.kind === 'survey' &&
            dependency.dataset === item.submission?.dataset.id
    );
    // HACK right now we only support study-based reports for which
    // the depenency requirement is assumed to always be whether or not
    // a submission has been made
    return hasSubmission ? 'fulfilled' : 'unfulfilled';
}

export function getReportAccessStatus(item: {
    report: ReportV2Entity;
    submission: FormSubmissionEntity | null;
    count: {
        respondents: CountEntity | null;
    };
}): ReportAccessStatus {
    const [first, ...rest] = item.report.dependencies.filter(isReportStudyDependency);
    assert(rest.length === 0, 'multi study reports not yet implemented');
    const dependencyStatus = getReportDependencyStatus(item);

    if (dependencyStatus === 'unfulfilled') {
        return 'missing_dependencies';
    }

    if (first) {
        // it's a study, also check the minimum dataset size
        assert(
            item.count.respondents,
            'report study aggregates must include the respondents count'
        );
        const hasEnoughData =
            item.count.respondents.value > StudyConstant.MIN_NUMBER_OF_RESPONSES;
        return hasEnoughData ? 'available' : 'not_enough_data';
    }

    return 'available';
}
