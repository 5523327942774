import { Integration, IntegrationStatus } from '../../../domain/assets';
import { IntegrationAdapter } from '../../../app/assets';
import { IntegrationDto } from '../../../api';
import { IntegrationImplConfig } from './integrationImplConfig';

export function createIntegrationImpl(config: IntegrationImplConfig): IntegrationAdapter {
    const { api } = config;
    return {
        async find(context, query) {
            const response = await api.asset.integrations.list(context, query);
            return {
                total: response.data.length,
                items: response.data.flatMap((item): Integration[] => {
                    const status = getStatus(item);
                    // console.log('item', item.implementation.slug, status, item);
                    if (!status) {
                        console.warn(
                            `unexpected integration status ${status}, skipping...`
                        );
                        return [];
                    }
                    return [
                        {
                            id: item.id,
                            key: item.key,
                            definitionId: item.implementation.integration_definition_id,
                            status: status,
                            lastSyncedStartedAt:
                                typeof item.sync_status === 'object'
                                    ? item.sync_status?.started_at ?? null
                                    : null,
                            lastSyncedFinishedAt:
                                item.last_sync ??
                                (typeof item.sync_status === 'object'
                                    ? item.sync_status?.closed_at ?? null
                                    : null),
                            createdAt: item.created_at,
                            updatedAt: item.updated_at,
                        },
                    ];
                }),
                limit: query.page_size,
            };
        },
    };
}

function getStatus(item: IntegrationDto): IntegrationStatus | null {
    const [collection] = item.last_mappable_collection ?? [null];
    // return 'analyzing';
    // if (item.implementation.slug === 'fake_facebookads') {
    //     return 'analyzing';
    // }
    if (collection?.result.state === 'not_available_yet') {
        return 'analyzing';
    }
    if (item.is_active && !item.is_connected) {
        return 'disconnected';
    }
    // if (item.error)
    if (item.is_historical_syncing && item.last_sync === null) {
        return 'importing';
    }
    if (item.is_active && item.is_connected && item.last_sync !== null) {
        return 'ready';
    }
    if (item.is_data_synced) {
        return 'ready';
    }
    if (item.last_error_reason) {
        return 'disconnected';
    }
    if (item.last_mappable_collection === null) {
        return 'analyzing';
    }
    // if (item.is_historical_syncing) {
    //     return 'syncing';
    // }
    return 'syncing';
}
