import { chain } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { PeerControllerConfig } from '../../base';
import { PeerCompanySearchController } from './peerCompanyMatchInterface';
import { PeerCompanySearchViewProps } from './peerCompanyMatchProps';

export function createPeerCompanySearchController(
    config: PeerControllerConfig
): PeerCompanySearchController {
    const {
        controller: {
            common: {
                search: { useProps: useSearchProps },
                filter: { useProps: useFilterProps },
            },
            company: {
                list: { useProps: useCompanyListProps },
            },
        },
    } = config;

    const DEFAULT_MAX_ITEMS_SHOWN = 100;

    return {
        useProps(context, item, props): PeerCompanySearchViewProps {
            const { limit = DEFAULT_MAX_ITEMS_SHOWN } = props;
            const company = useCompanyListProps(context, item.list, props.company);
            const search = useSearchProps(item.search, props.search);
            const filter = useFilterProps(item.filter, props.filter);

            const filtered = useMemo(() => {
                let acc = chain(company.items).filter(
                    // hide selected items from list
                    (item) => !props.selected.has(item.company.id)
                );
                if (limit !== null) {
                    acc = acc.take(limit);
                }
                return acc.value();
            }, [company.items, props.selected, limit]);

            const matchCountLabel =
                item.paging.total >= item.paging.limit
                    ? `${company.items.length}+`
                    : `${company.items.length}`;

            return {
                filter,
                search,
                collection: {
                    label:
                        company.items.length > 0
                            ? `${matchCountLabel} ${pluralize('company', company.items.length)} matched`
                            : `No companies matched`,
                },
                items: filtered,
                getItemProps(item) {
                    const itemProps = company.getItemProps(item);
                    return {
                        id: itemProps.id,
                        company: itemProps,
                        select: {
                            id: itemProps.id,
                            value: itemProps.id,
                            isSelected: props.selected.has(item.company.id),
                            button: {
                                onClick() {
                                    props.onAdd(item);
                                },
                            },
                        },
                    };
                },
            };
        },
    };
}
