import { assertNever } from '../../../../util';
import { getPeerSetStatus } from '../../../../app';
import { buildStatusProps, StatusProps } from '../../../../view/common';
import { PeerSetStatusAggregate } from './peerSetStatusModel';

export function buildPeerSetStatusProps(item: PeerSetStatusAggregate): StatusProps {
    const status = getPeerSetStatus(item);
    if (status.kind === 'eligible') {
        return buildStatusProps({
            id: 'comeptitive_set',
            value: status.kind,
            kind: 'success',
            label: 'Eligible',
            description: null,
            ordinal: 0,
        });
    }
    if (status.kind === 'creating') {
        return buildStatusProps({
            id: 'comeptitive_set',
            value: status.kind,
            kind: 'warning',
            label: 'Creating',
            description: `The group has been created and is awaiting responses from invited companies to meet the minimum member requirement`,
            ordinal: 1,
        });
    }
    if (status.kind === 'updating') {
        return buildStatusProps({
            id: 'comeptitive_set',
            value: status.kind,
            kind: 'warning',
            label: 'Processing',
            description: `The group have pending membership changes and invitations are being processed`,
            ordinal: 2,
        });
    }
    if (status.kind === 'ineligible') {
        return buildStatusProps({
            id: 'comeptitive_set',
            value: status.kind,
            kind: 'warning',
            label: 'Ineligible',
            description: `The group does not have enough members yet and needs more companies to be added to become functional`,
            ordinal: 3,
        });
    }
    assertNever(status);
}
