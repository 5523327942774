import React, { useMemo } from 'react';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { assert } from '../../../util/assert';
import { PluginRepository, useDashboardApi, useWorkspaceFeatureApi } from '../../../app';
import { BenchmarkListController, BenchmarkPluginItemProps } from '../../../view';
import { FeatureMiddleware } from '../featureInterface';

export function createFeaturePluginMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceController(
            controller: BenchmarkListController
        ): BenchmarkListController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const disabled = useMemo(
                        () =>
                            viewProps.plugins.disabled.map(
                                (item): BenchmarkPluginItemProps => ({
                                    ...item,
                                    link: { to: `/u/settings/subscription` },
                                })
                            ),
                        [viewProps.plugins.disabled]
                    );
                    return {
                        ...viewProps,
                        plugins: { ...viewProps.plugins, disabled: disabled },
                    };
                },
            };
        }

        function enhanceRepository(repository: PluginRepository): PluginRepository {
            return {
                ...repository,
                useFind(context, query, options) {
                    // HACK for some reason we're not inside the workspace context when loading
                    // the home page so we need to manually fetch it for now
                    const features = instance.repository.platform.feature.useLookup(
                        context,
                        { suspense: true, staleTime: Infinity }
                    );
                    assert(features.status === 'success', 'expected suspense');
                    const configuration = useMemo(() => {
                        const included = new Set(
                            features.data.plugin?.config?.include ?? []
                        );
                        const excluded = new Set(
                            features.data.plugin?.config?.exclude ?? []
                        );
                        return { included, excluded };
                    }, [features.data.plugin?.config]);
                    return repository.useFind(context, query, {
                        ...options,
                        select(data) {
                            const transformed = options.select?.(data) ?? data;
                            return transformed.map((item) => {
                                if (
                                    configuration.included.has(item.id) ||
                                    !configuration.excluded.has(item.id)
                                ) {
                                    return item;
                                }
                                return {
                                    ...item,
                                    isDisabled: true,
                                    disabledReason: `${item.name} requires a paid subscription add-on`,
                                };
                            });
                        },
                    });
                },
            };
        }

        const instance = create({
            ...config,
            repository: {
                ...config.repository,
                platform: {
                    ...config.repository.platform,
                    createPlugin(...args) {
                        const repository = config.repository.platform.createPlugin(
                            ...args
                        );
                        return enhanceRepository(repository);
                    },
                },
            },
            module: {
                ...config.module,
                createMetricModule(...args) {
                    const module = config.module.createMetricModule(...args);
                    return {
                        ...module,
                        Benchmark: {
                            ...module.Benchmark,
                            List: {
                                ...module.Benchmark.List,
                                controller: enhanceController(
                                    module.Benchmark.List.controller
                                ),
                            },
                        },
                    };
                },
            },
        });
        return instance;
    };
}

export function createFeaturePluginsStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            route: {
                ...config.route,
                createDashboardRoute(routeConfig) {
                    const Component = config.route.createDashboardRoute(routeConfig);
                    return () => {
                        const dashboardApi = useDashboardApi();
                        const api = useWorkspaceFeatureApi();
                        const featureset = api.getFeatureset();
                        const dash = dashboardApi.getDashboard();
                        const isAllowed = React.useMemo(
                            () =>
                                featureset.plugin?.enabled &&
                                (featureset.plugin?.config?.include?.includes(
                                    dash.pluginId
                                ) ||
                                    !featureset.plugin?.config?.exclude?.includes(
                                        dash.pluginId
                                    )),
                            [featureset.plugin]
                        );
                        React.useEffect(() => {
                            if (featureset.plugin) {
                                if (!isAllowed) {
                                    api.restrict(featureset.plugin);
                                }
                            }
                        }, [isAllowed]);
                        return isAllowed ? <Component /> : <></>;
                    };
                },
            },
        });
    };
}
