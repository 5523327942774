import type { MemberEntity } from '../../../domain/attributes';
import { AnyConstraintExpression } from '../constraint';
import {
    isComplexType,
    isCurrencyType,
    isDateType,
    isEnumMemberWithChildren,
    isEnumType,
    isIntegerType,
    isReferenceType,
    isTreeType,
    isStringType,
} from './typeGuard';
import {
    AnyResolvedType,
    AnyType,
    EnumMember,
    EnumMemberWithChildren,
    EnumType,
    TreeType,
} from './typeModel';

export function getTypeConstraints(
    type: AnyResolvedType
): Array<AnyConstraintExpression | undefined> {
    if (isTreeType(type)) {
        return [
            {
                operator: 'in',
                value: flattenEnumMembers(type.members).map((item) => item.value),
            },
        ];
    }
    if (isCurrencyType(type)) {
        return [...type.constraints.map((item) => item.expression)];
    }
    return [];
}

export function resolveType(members: MemberEntity[], type: AnyType): AnyResolvedType {
    if (isReferenceType(type)) {
        const member = members.find((candidate) => candidate.id === type.id);
        return <TreeType>{
            kind: 'tree',
            members: member?.members,
        };
    }
    return type;
}

export function resolveTypes(
    members: MemberEntity[],
    types: AnyType[]
): AnyResolvedType[] {
    return types.map((type) => {
        return resolveType(members, type);
    });
}

export function flattenEnumMembers(
    members: (TreeType | EnumType)['members']
): Array<EnumMember & { parent: EnumMemberWithChildren | null }> {
    return members.flatMap((member) => {
        if (!isEnumMemberWithChildren(member)) {
            return [{ ...member, parent: null }];
        }
        const { children, ...rest } = member;
        return [
            { ...rest, parent: null },
            ...children.map((child) => ({ ...child, parent: member })),
        ];
    });
}

export function mapEnum<TType extends Pick<TreeType | EnumType, 'members'>>(
    type: TType,
    callback: <TMember extends EnumMember | EnumMemberWithChildren>(
        member: TMember,
        parent: EnumMemberWithChildren | null
    ) => TMember
): TType {
    return {
        ...type,
        members: type.members.map((parent) => {
            if (!isEnumMemberWithChildren(parent)) {
                return callback(parent, null);
            }
            return callback(
                {
                    ...parent,
                    children: parent.children.map((child) => callback(child, parent)),
                },
                null
            );
        }),
    };
}

export function getTypeKind(type: AnyType): string {
    if (isComplexType(type)) {
        return type.kind;
    }
    return type;
}

export function getTypeDescription(type: AnyType) {
    if (isEnumType(type)) {
        return type.description ?? null;
    }
    if (isStringType(type)) {
        return `A string type`;
    }
    if (isIntegerType(type)) {
        return `A integer type`;
    }
    if (isCurrencyType(type)) {
        return `A currency type`;
    }
    if (isDateType(type)) {
        return `A date type`;
    }
    return null;
}
