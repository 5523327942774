import { AxiosInstance } from 'axios';
import moment from 'moment';
import { assert } from '../../../util/assert';
import { LegacyAuthenticationScheme } from '../../common';
import { createDatasetResource } from '../dataset';
import { ImportResource } from './importInterface';
import { ImportDtoSchema, ImportListDtoSchema } from './importSchema';

export function createImportResource(client: AxiosInstance): ImportResource {
    const datasets = createDatasetResource(client);
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.get('/api/v1/data-ingestion/imports', {
                params: {
                    asset_ids: [query.asset],
                    page_size: 30,
                    expand: ['dataset']
                },
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            const parsed = ImportListDtoSchema.parse(response.data);
            return {
                data: parsed.data.map(x => ({
                    ...x,
                    dataset_id: x.dataset.slug
                })
            )}
        },
        async get(context, importId) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            const response = await client.get(
                `/api/v1/data-ingestion/imports/${importId}`,
                {
                    params: {
                        expand: ['dataset'] 
                    },
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            const parsed = ImportDtoSchema.parse(response.data);
            return {
                ...parsed,
                dataset_id: parsed.dataset.slug
            };
        },
        async create(context, props) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();


            const directDatasets = await datasets.list(context, {
                slugs: [props.dataset]
            });
            
            const match = directDatasets.data.find(
                (candidate) => candidate.dataset.slug === props.dataset
            );
            assert(match, 'not dataset found that matches the requested import');

            const { dataset: _slug, ...rest } = props;

            const columns = {
                partition: match.dataset.columns.find((column) =>
                    column.flags.includes('time_partition')
                ),
                asset: match.dataset.columns.find((column) =>
                    column.flags.includes('asset')
                ),
            };


            const now = new Date();
            const response = await client.post(
                '/api/v1/data-ingestion/imports',
                {
                    dataset: match.dataset.id,
                    asset: rest.asset,
                    data: rest.data.map((row) => {
                        assert(columns.partition, 'no partition found');
                        assert(columns.asset, 'no asset found');
                        return {
                            ...row,
                            [columns.partition.name]: moment(now).format('YYYY-MM-DD'),
                            // TODO the backend should set the asset ID based on the top-level asset property
                            [columns.asset.name]: props.asset,
                        };
                    }),
                    expand: ['dataset']
                },
                {
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            const parsed = ImportDtoSchema.parse(response.data);
            return {
                ...parsed,
                dataset_id: match.dataset.slug
            };
        },
    };
}
