import { LockIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Checkbox,
    HStack,
    Icon,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import {
    AiOutlineInfoCircle,
    AiOutlinePlus,
    AiOutlinePlusCircle,
    AiOutlineWarning,
} from 'react-icons/ai';
import { BiCog } from 'react-icons/bi';
import { FaCog } from 'react-icons/fa';
import { HiOutlineCog } from 'react-icons/hi';
import { Icons } from '../../../../config';
import {
    DashboardGroupItemViewProps,
    DashboardGroupViewProps,
} from '../../../view/dashboard/group/dashboardGroupProps';
import { DashboardGroupConfig } from './dashboardGroupConfig';

export function createDashboardGroupView(
    config: Pick<DashboardGroupConfig, 'UI'>
): React.FC<DashboardGroupViewProps> {
    const {
        UI: {
            Application: { Tooltip, Link },
        },
    } = config;
    const GroupItem: React.FC<DashboardGroupItemViewProps> = (props) => {
        return (
            <HStack
                aria-disabled={props.status?.isDisabled}
                {...props.container}
                w="full"
                spacing={6}
                cursor="pointer"
                userSelect="none"
                justify="space-between"
                _disabled={{
                    cursor: 'default',
                }}
            >
                <HStack
                    aria-disabled={props.status?.isDisabled}
                    spacing={2}
                    _disabled={{ opacity: 0.5, pointerEvents: 'none' }}
                >
                    <Checkbox
                        {...props.checkbox}
                        // rely on container handler
                        pointerEvents="none"
                    />
                    <Text noOfLines={1}>{props.label}</Text>
                </HStack>
                {props.status && (
                    <Tooltip
                        {...props.status.getTooltipProps()}
                        id="dashboard_group_status_tooltip"
                        placement="top"
                        hasArrow={true}
                        p={3}
                        bg="#1D1C21"
                    >
                        <Box as="span">
                            <Icon
                                fontSize="lg"
                                as={AiOutlineWarning}
                                color={`${props.status.colorScheme}.300`}
                            />
                        </Box>
                    </Tooltip>
                )}
                {/* <Text fontWeight="medium" color="whiteAlpha.600">
                    {props.count.members.label}
                </Text> */}
            </HStack>
        );
    };
    return (props) => {
        if (props.items.length === 0) {
            return (
                <Center w="full" py={8}>
                    <VStack align="start" spacing={3}>
                        <Box p={3} bg="whiteAlpha.300" borderRadius="sm">
                            <Icon fontSize="xl" as={Icons.Peers.Groups} />
                        </Box>
                        <VStack align="start" spacing={1}>
                            <Text fontWeight="semibold">You don't have any groups</Text>
                        </VStack>
                        <Link
                            id="dashboard_group_add_link"
                            to="../../../../peers/groups/new"
                        >
                            <Button
                                size="sm"
                                colorScheme="blue"
                                rightIcon={<Icon as={AiOutlinePlus} />}
                                _focus={{ outline: 'none' }}
                            >
                                Select companies
                            </Button>
                        </Link>
                    </VStack>
                </Center>
            );
        }
        return (
            <VStack align="start" w="full">
                <HStack w="full" justify="space-between">
                    <HStack>
                        <Text fontWeight="medium">Groups</Text>
                        {/* <Tooltip
                            id="dashboard_group_filter_tooltip"
                            placement="top"
                            hasArrow={true}
                            p={3}
                            bg="#1D1C21"
                            label="Tempor fugiat aliquip et eu ut consectetur consequat magna excepteur nostrud velit"
                        >
                            <Box as="span">
                                <Icon as={AiOutlineInfoCircle} />
                            </Box>
                        </Tooltip> */}
                    </HStack>
                    <HStack>
                        {/* <Tooltip
                            placement="top"
                            hasArrow={true}
                            p={2}
                            bg="#1D1C21"
                            label="New group"
                        >
                            <Box as="span">
                                <Link
                                    id="dashboard_group_add_link"
                                    to="../../../../peers/groups/new"
                                >
                                    <Icon
                                        color="whiteAlpha.600"
                                        cursor="pointer"
                                        as={AiOutlinePlusCircle}
                                        _hover={{ color: 'whiteAlpha.700' }}
                                    />
                                </Link>
                            </Box>
                        </Tooltip> */}
                        <Tooltip
                            placement="top"
                            hasArrow={true}
                            p={2}
                            bg="#1D1C21"
                            label="Manage groups"
                        >
                            <Box as="span">
                                <Link
                                    id="dashboard_group_manage_link"
                                    to="../../../../peers/groups"
                                >
                                    <Icon
                                        color="whiteAlpha.600"
                                        cursor="pointer"
                                        as={HiOutlineCog}
                                        fontSize="lg"
                                        _hover={{ color: 'whiteAlpha.700' }}
                                    />
                                </Link>
                            </Box>
                        </Tooltip>
                    </HStack>
                </HStack>
                <VStack align="start" w="full" spacing={3}>
                    <VStack
                        fontSize="sm"
                        align="start"
                        w="full"
                        fontWeight="medium"
                        color="whiteAlpha.700"
                        spacing={3}
                    >
                        {props.items.map((item) => (
                            <GroupItem {...props.getItemProps(item)} />
                        ))}
                    </VStack>
                    {/* <HStack>
                        <Link
                            id="dashboard_group_manage_link"
                            to="../../../../peers/groups"
                        >
                            <Text
                                fontSize="sm"
                                fontWeight="semibold"
                                // color="whiteAlpha.700"
                                color="blue.400"
                                userSelect="none"
                                cursor="pointer"
                                _hover={{ textDecor: 'underline' }}
                            >
                                Manage groups
                            </Text>
                        </Link>
                    </HStack> */}
                </VStack>
            </VStack>
        );
    };
}
