import { chain } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { GroupsConfig } from '../common';
import { buildGroupItemViewProps, GroupItemViewProps } from '../item';
import { GroupListController } from './groupListInterface';

export function createGroupListController(
    config: Pick<GroupsConfig['deps'], 'infra'>
): GroupListController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, item) {
            const items = useMemo(() => chain(item.items).value(), [item.items]);

            const itemPropsById = useMemo(
                () =>
                    items.reduce(
                        (acc, item, index) => ({
                            ...acc,
                            [item.peerset.id]: buildGroupItemViewProps(
                                formatter,
                                context,
                                item,
                                index
                            ),
                        }),
                        {} as Record<string, GroupItemViewProps | undefined>
                    ),
                [context, items]
            );
            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.peerset.id];
                    assert(itemProps, `no item props`);
                    return itemProps;
                },
                emptyState:
                    items.length > 0
                        ? null
                        : {
                              title: 'No groups found',
                              description: `You have not yet created any study groups`,
                              linkProps: {
                                  to: `/u/assets/${context.workspace.id}/reports/explore`,
                              },
                              buttonProps: {
                                  children: 'Explore studies',
                              },
                          },
            };
        },
    };
}
