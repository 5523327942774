import { useMemo } from 'react';
import { getCollectionItems } from '../../../../base';
import { keyByMapSafe } from '../../../../util';
import { PeerLoaderConfig } from '../../base';
import { FILTERS } from './peerCompanyMatchConstant';
import { PeerCompanySearchLoader } from './peerCompanyMatchInterface';
import { PeerCompanySearchAggregate } from './peerCompanyMatchModel';

export function createPeerCompanySearchLoader(
    config: PeerLoaderConfig
): PeerCompanySearchLoader {
    const {
        repository: { company: companyRepository },
    } = config;
    return {
        useLoad(context, props): PeerCompanySearchAggregate {
            const companySearchQuery = companyRepository.useSearch(context, props.query, {
                suspense: true,
                keepPreviousData: true,
            });

            const companies = useMemo(
                () =>
                    companySearchQuery.status === 'success'
                        ? getCollectionItems(companySearchQuery.data)
                        : [],
                [companySearchQuery.status, companySearchQuery.data]
            );

            const assetByCompany = useMemo(
                () =>
                    keyByMapSafe(companies, (item) => {
                        const profile = item.profiles.find(
                            (candidate) => candidate.kind === 'varos'
                        );
                        const asset = profile ? { id: Number(profile.id) } : undefined;
                        return [item.id, asset];
                    }),
                [companies]
            );

            const aggregate = useMemo((): PeerCompanySearchAggregate => {
                return {
                    paging: {
                        limit: props.query.limit,
                        total: companies.length,
                    },
                    list: {
                        items: companies.map((company) => ({
                            company,
                            asset: assetByCompany[company.id] ?? null,
                        })),
                    },
                    filter: {
                        items: FILTERS,
                    },
                    search: {
                        items: companySearchQuery.isFetching
                            ? {
                                  status: 'loading',
                                  data: null,
                              }
                            : {
                                  status: 'loaded',
                                  data: {
                                      total: 0,
                                      limit: 0,
                                      items: companySearchQuery.data
                                          ? getCollectionItems(companySearchQuery.data)
                                          : [],
                                  },
                              },
                    },
                };
            }, [
                FILTERS,
                companies,
                assetByCompany,
                companySearchQuery.isFetching,
                companySearchQuery.data,
            ]);

            return aggregate;
        },
    };
}
