import {
    AiOutlineGroup,
    AiOutlineSend,
    AiOutlineUser,
    AiOutlineUsergroupAdd,
} from 'react-icons/ai';
import { MdLink, MdOutlinePersonSearch } from 'react-icons/md';
import { TbTargetArrow } from 'react-icons/tb';
import { AiOutlineAim } from 'react-icons/ai';
import { BsStars } from 'react-icons/bs';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { AiOutlineBulb } from 'react-icons/ai';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { TbZoomMoney } from 'react-icons/tb';
import { MdOutlineManageSearch, MdOutlinePrivacyTip } from 'react-icons/md';
import { MdOutlineGroupWork } from 'react-icons/md';
import { FaRegEnvelope } from 'react-icons/fa';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import { BsChatLeftDotsFill } from 'react-icons/bs';
import { RiUserSearchLine } from 'react-icons/ri';
import { ImMagicWand } from 'react-icons/im';
import { BiCommentAdd } from 'react-icons/bi';
import { MdOutlineInfo } from 'react-icons/md';
import { BsSendPlus } from 'react-icons/bs';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import { FiCheckSquare } from 'react-icons/fi';
import { FiEdit } from 'react-icons/fi';
import { BsCurrencyDollar } from 'react-icons/bs';
import { BsSend } from 'react-icons/bs';
import { MdPercent } from 'react-icons/md';
import { GoOrganization } from 'react-icons/go';

export const Icons = {
    Peers: {
        Groups: MdOutlineGroupWork,
    },
    Accounts: {
        User: AiOutlineUser,
        Asset: GoOrganization,
        Integration: MdLink,
    },
    Studies: {
        Submission: AiOutlineSend,
        Study: MdOutlineQuestionAnswer,
        Invite: FaRegEnvelope,
        Group: MdOutlineGroupWork,
        Cohort: MdOutlineManageSearch,
        Answer: BsChatLeftDotsFill,
        Privacy: MdOutlinePrivacyTip,
        Manual: RiUserSearchLine,
        Automatic: ImMagicWand,
    },
    Attributes: {
        Currency: BsCurrencyDollar,
        Percentage: MdPercent,
    },
    Networks: {
        Operator: HiOutlineLightBulb,
        Agency: HiOutlineUserGroup,
        Target: TbTargetArrow,
        Filter: MdOutlinePersonSearch,
        Budget: TbZoomMoney,
        AdditionalDetail: FiEdit,
        Submit: BsSend,
        Currency: BsCurrencyDollar,
    },
    Feature: {
        Highlight: BsStars,
    },
};
