import { DashboardGroupAggregate, GroupItemAggregate } from '../../../view';
import { DashboardGroupConfig } from './dashboardGroupConfig';
import { DashboardGroupLoader } from './dashboardGroupInterface';

export function createDashboardGroupLoader(
    config: Pick<DashboardGroupConfig, 'repository'>
): DashboardGroupLoader {
    const {
        repository: { peerset: peersetRepository },
    } = config;
    return {
        useLoad(context): DashboardGroupAggregate {
            const peersetQuery = peersetRepository.useFind(
                context,
                {},
                { suspense: true, staleTime: Infinity }
            );
            return {
                group: {
                    items:
                        peersetQuery.data?.items.map(
                            (peerset): GroupItemAggregate => ({
                                peerset,
                                user: null,
                                count: {
                                    invitation: null,
                                },
                            })
                        ) ?? [],
                },
            };
        },
    };
}
