import { useState, useEffect, useMemo } from 'react';

import { PeerMemberEditStateProvider } from './peerMemberEditInterface';
import { PeerMemberEditStateValues } from './peerMemberEditModel';

export function createPeerMemberEditStateProvider(): PeerMemberEditStateProvider {
    return {
        useState(context) {
            const [value, setValue] = useState<PeerMemberEditStateValues>({
                search: '',
            });
            return {
                value,
                onChange: setValue,
            };
        },
    };
}
