import { useQuery } from '@tanstack/react-query';
import { RecommendationAdapter } from './recommendationAdapter';
import { RecommendationRepository } from './recommendationInterface';

export function createRecommendationRepository(
    adapter: RecommendationAdapter
): RecommendationRepository {
    const PREFIX = ['personalization', 'recommendation'];
    return {
        useFind(context, props, options) {
            const query = useQuery({
                queryKey: [...PREFIX, 'find'],
                async queryFn() {
                    const response = await adapter.find(context, props);
                    return response;
                },
                staleTime: Infinity,
                ...options,
            });
            return query;
        },
    };
}
