import React from 'react';
import { SettingAccountItemProvider } from '../../../../route/settings/account';
import { SettingSubscriptionConfig } from './subscriptionConfig';
import {
    createSettingSubscriptionEditContainer,
    createSettingSubscriptionEditController,
    createSettingSubscriptionEditLoader,
    createSettingSubscriptionEditView,
} from './edit';

export function createSettingBillingSubscriptionStrategy(
    init: SettingSubscriptionConfig
): SettingAccountItemProvider {
    return {
        create(config) {
            const Edit = {
                Container: createSettingSubscriptionEditContainer(
                    init,
                    config,
                    createSettingSubscriptionEditLoader(init),
                    createSettingSubscriptionEditController(init, config)
                ),
                View: createSettingSubscriptionEditView(config),
            };
            return [
                {
                    scope: 'billing',
                    path: 'subscription',
                    key: 'subscription',
                    title: 'Subscription',
                    Component() {
                        return <Edit.Container as={Edit.View} />;
                    },
                },
            ];
        },
    };
}
