import { useMemo } from 'react';
import { assert } from '../../../../../util/assert';
import { SubscriptionEditAggregate } from '../../../../../view/billing';
import { SettingSubscriptionConfig } from '../subscriptionConfig';
import { SettingSubscriptionEditLoader } from './editInterface';

export function createSettingSubscriptionEditLoader(
    config: Pick<SettingSubscriptionConfig, 'repository'>
): SettingSubscriptionEditLoader {
    const { repository } = config;
    return {
        useLoad(context) {
            const subscription = repository.subscription.useLookup(context, {
                suspense: true,
            });
            const plans = repository.plan.useList(
                context,
                { version: 2 },
                {
                    suspense: true,
                }
            );
            const methods = repository.method.useList(context, {
                suspense: true,
            });
            assert(subscription.status === 'success', 'expected suspense query');
            assert(plans.status === 'success', 'expected suspense query');
            assert(methods.status === 'success', 'expected suspense query');
            return useMemo<SubscriptionEditAggregate>(
                () => ({
                    subscription: subscription.data,
                    plans: plans.data.items,
                    methods: methods.data,
                    features: [],
                }),
                [subscription.data, plans.data.items, methods.data]
            );
        },
    };
}
