import {
    AgencyCompensationReport,
    CreativeStrategyReport,
    MarketingTeamCompensationReport,
    ToolingReport,
} from './fixture';

export const ReportDefinitions = {
    TOOLING: ToolingReport,
    AGENCY: AgencyCompensationReport,
    CREATIVE: CreativeStrategyReport,
    MARKETING_TEAM: MarketingTeamCompensationReport,
};
