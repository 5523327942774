import {
    buildPeerExample,
    ExampleProps,
    PeerGroupExampleProperty,
} from '../../../domain/peers';
import {
    AnyCondition,
    getTypeConstraints,
    isBetweenCondition,
    isCurrencyProperty,
    isGreaterThanCondition,
    isGreaterThanOrEqualCondition,
    isInCondition,
    isInConstraint,
    isLessThanCondition,
    isLessThanOrEqualCondition,
    isNumericBetweenCondition,
    isNumericBetweenConstraint,
    isTreeProperty,
} from '../../../domain/attributes';
import { PeerExampleControllerProps } from '../../../view/peer';
import { PeerStatusQuery } from './statusQuery';

export function castStatusQueryToExample(query: PeerStatusQuery) {
    const conditionBySource = query.conditions.reduce(
        (acc, condition) => ({
            ...acc,
            [condition.key]: condition,
        }),
        {} as Record<string, AnyCondition | undefined>
    );

    const props: ExampleProps = {
        properties: query.plugin.traits.flatMap((trait): PeerGroupExampleProperty[] => {
            const [constraint, ...rest] = getTypeConstraints(trait.type);

            if (rest.length > 0) {
                console.warn(
                    `trait ${trait.key} has multiple constraints which is not supported`
                );
            }
            const condition = conditionBySource[trait.key];

            if (
                !condition?.value &&
                constraint &&
                (constraint.operator === 'in' || constraint.operator === 'between')
            ) {
                return [
                    {
                        property: trait,
                        constraint: constraint,
                    },
                ];
            }

            if (!condition) {
                return [];
            }

            if (isInCondition(condition)) {
                return [
                    {
                        property: trait,
                        constraint: {
                            operator: 'in',
                            value: condition.value as string[],
                        },
                    },
                ];
            }

            if (isNumericBetweenCondition(condition)) {
                return [
                    {
                        property: trait,
                        constraint: condition,
                    },
                ];
            }

            if (isLessThanCondition(condition) || isLessThanOrEqualCondition(condition)) {
                return [
                    {
                        property: trait,
                        constraint: {
                            operator: 'between',
                            value: {
                                from: 1,
                                to: condition.value,
                            },
                        },
                    },
                ];
            }
            if (
                (isGreaterThanCondition(condition) ||
                    isGreaterThanOrEqualCondition(condition)) &&
                constraint?.operator === 'between'
            ) {
                return [
                    {
                        property: trait,
                        constraint: {
                            operator: 'between',
                            value: {
                                from: condition.value,
                                to: constraint.value.to,
                            },
                        },
                    },
                ];
            }
            if (!constraint) {
                console.warn(`trait ${trait.key} has not countraint, skipping...`);
                return [];
            }
            return [];
        }),
        size: 5,
    };

    return buildPeerExample(props);
}
