import { assert } from '../../../util/assert';
import { fetchChildrenProps, getChildrenProps } from '../../../util';
import { ChakraBarChartComponents } from './chakraBarChartInterface';
import { BarChartItem } from './chakraBarChartModel';
import { BarChartContainerProps, BarChartViewProps } from './chakraBarChartProps';

export function buildBarChartViewProps(
    Components: ChakraBarChartComponents,
    props: BarChartContainerProps
): BarChartViewProps {
    // console.log('DEBUG props factory', props);
    const childProps = {
        ...fetchChildrenProps(props.children, {
            item: Components.Item,
        }),
        ...getChildrenProps(props.children, {
            scale: Components.Scale,
        }),
    };
    const [series, ...rest] = props.series;
    assert(rest.length === 0, 'muliti series bar chart not yet supported');

    return {
        layout: {
            container: props,
            item: childProps.item,
            scale: childProps.scale ?? { startAtZero: false },
        },
        items: props.labels.map((label, index): BarChartItem => {
            const entry = series.data[index];
            return {
                label,
                value: entry.value,
            };
        }),
        getItemProps(item) {
            return {
                label: props.formatCategory(item.label),
                value: item.value === null ? 'N/A' : props.formatValue(item.value),
            };
        },
    };
}
