import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { ApiErrorResponseSchema } from '../../api';
import { ApiError } from '../../base';

export function createAxios(config: AxiosRequestConfig<any>) {
    const instance = axios.create({
        ...config,
    });

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error: AxiosError) => {
            const parsed = ApiErrorResponseSchema.safeParse(error.response?.data ?? {});
            if (parsed.success) {
                const error = new ApiError(parsed.data.detail);
                throw error;
            }
            throw new ApiError(error.message);
        }
    );

    instance.interceptors.request.use((config: AxiosRequestConfig) => {
        const serializedParams: any = {};

        for (const key in config.params) {
            if (config.params.hasOwnProperty(key)) {
                const value = config.params[key];
                if (value instanceof Date) {
                    serializedParams[key] = moment(value).format('YYYY-MM-DD');
                } else {
                    serializedParams[key] = value;
                }
            }
        }

        config.params = serializedParams;
        return config;
    });

    return instance;
}
