import { useState } from 'react';
import { FormControllerConfig } from './formViewConfig';
import { FormViewController } from './formViewInterface';

export function createFormController(
    config: FormControllerConfig = {}
): FormViewController {
    const { submitOnEnter = false } = config;
    const shouldPreventEnterKey = !submitOnEnter;
    return {
        useProps(props) {
            const [error, setError] = useState<null | Error>(null);
            const { isSubmitting } = props.form.formState;
            return {
                form: props.form,
                error: error
                    ? {
                          status: 'error',
                          label: error.message,
                      }
                    : null,

                reset() {
                    props.form.reset();
                    setError(null);
                },
                getFormElementProps() {
                    return {
                        onKeyPress: shouldPreventEnterKey
                            ? (event) => {
                                  if (event.key === 'Enter') {
                                      event.preventDefault();
                                  }
                              }
                            : undefined,
                        onSubmit: props.form.handleSubmit(async (values) => {
                            try {
                                const response = await props.onSubmit(values);
                            } catch (error) {
                                if (error instanceof Error) {
                                    console.error(error);
                                    setError(error);
                                    return;
                                }
                                throw error;
                            }
                        }, props.onError),
                    };
                },
                getSubmitButtonProps() {
                    return {
                        type: 'submit',
                        isLoading: isSubmitting,
                        isDisabled: isSubmitting,
                        children: props.action?.label ?? 'Save',
                    };
                },
            };
        },
    };
}
