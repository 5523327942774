import { Box, Heading } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { IntegrationConf } from '../../models/integration';
import { pluralize } from '../../utils/strings';
import { ErrorInfo } from '../../models/Errors';
import {
    getOrganizationIntegrationStatus,
    AnyIntegrationStatus,
    UserIntegrationDefinition,
} from '../../domain';
import { Divider, VStack } from '@chakra-ui/layout';
import { IntegrationButtonProps } from '../Integrations/IntegrationButton';
import { Integration } from '../../models/integration';
import {
    IntegrationItemOrganizationView,
    IntegrationCardListView,
    IntegrationCardAlertProps,
} from '../../views';
import { useSearchParams } from 'react-router-dom';
import { INTEGRATED_QUERY_PARAM_KEY } from '../../hooks';

export interface IntegrationSelectorProps {
    isLoading: boolean;
    err: ErrorInfo | null;
    integrationDefinitions: Array<IntegrationConf> | null;
    currentIntegrations: Array<Integration> | null;
    fetchIntegrations: (definitionId: number) => void;
    clearIntegrations: () => void;
    onIntegrationRequest: (integ: IntegrationConf) => void;
    onReconnectRequest: (def: IntegrationConf, integration: Integration) => void;
    onEdit: (id: number) => void;
    hideIntegrated?: boolean;
    mandatoryOnly: 'all' | 'none' | null;
    isLoadingConnection?: boolean;
    isLoadingReconnection?: boolean;
    isLoadingId?: number;
}

export interface PickedModalIntegration {
    baseProps: IntegrationButtonProps;
}

export const IntegrationSelector: React.FunctionComponent<IntegrationSelectorProps> = ({
    integrationDefinitions,
    onIntegrationRequest,
    onReconnectRequest,
    hideIntegrated,
    isLoadingConnection,
    isLoadingId,
}) => {
    const [searchParams] = useSearchParams();
    const definitions =
        integrationDefinitions?.filter(
            (integ) => !hideIntegrated || !integ.counts?.count_connected
        ) ?? [];

    const getAlertProps = useCallback(() => {
        const didIntegrate = searchParams.get(INTEGRATED_QUERY_PARAM_KEY);
        if (!didIntegrate) {
            return null;
        }
        const alertProps: IntegrationCardAlertProps = {
            status: 'success',
            title: 'Integration connected',
            description: `Link integrations to companies to start data sync`,
            action: {
                label: 'Link integrations',
                to: '/u/settings/companies',
            },
        };
        return alertProps;
    }, [searchParams]);

    return (
        <VStack w="100%" spacing={5}>
            <Heading fontWeight="medium" alignSelf="start">
                My Integrations
            </Heading>
            <Divider />
            <Box width={{ base: '100vw', xl: '80vw' }}>
                <IntegrationCardListView
                    getAlertProps={getAlertProps}
                    suspense={true}
                    containerProps={{ spacing: 5 }}
                    listProps={{ spacing: 5 }}
                    definitions={definitions}
                    renderItem={({ definition }) => (
                        <IntegrationItemOrganizationView
                            key={definition.id}
                            containerProps={{ minHeight: '210px' }}
                            definition={definition}
                            isLoadingId={isLoadingId}
                            isLoadingConnection={isLoadingConnection}
                            onConnect={onIntegrationRequest}
                            onReconnect={onReconnectRequest}
                            status={getOrganizationIntegrationStatus(definition)}
                        />
                    )}
                />
            </Box>
        </VStack>
    );
};
