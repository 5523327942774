import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { chain, isEqual } from 'lodash';
import { DEFAULT_COMPARISON } from '../../../../components/DashboardView/DashboardView';
import { isRelativePeriod, periodDifference, Schema } from '../../../../domain';
import {
    AnyFilter,
    CohortMode,
    CohortState,
    Dashboard,
    DashboardConfiguration,
    DashboardDateConfiguration,
    DynamicCohortStateItem,
    isPropertyFilter,
} from '../../../domain';
import {
    AnyCondition,
    DateRangeValue,
    isDateRangeValue,
    isInCondition,
    isTreeType,
    isDurationValue,
    isLessThanCondition,
    isLessThanOrEqualCondition,
    isGreaterThanCondition,
    isGreaterThanOrEqualCondition,
    getTypeConstraints,
    isNumericBetweenConstraint,
    BetweenConstraintExpression,
} from '../../../domain/attributes';
import { useWorkspaceContextV2 } from '../../../context';
import { useDashboardApi } from '../dashboard';
import { useStorageContext } from '../storage';
import { useDashboardContext } from '../dashboard';
import { useWorkspaceContext } from '../workspace';
import { cancelChartQueries } from '../chart';
import { ControlApi } from './controlInterface';
import { DashboardControlValue } from './controlModel';
import { selectControlState } from './controlSelector';
import { applyTreeValue } from './tree';
import { useFeatureApi, useOrganizationScope } from '../..';
import { ControlDateValue } from './controlModel';
import { DashboardSlugs } from '../../../../config/dashboard';
import { toJS } from 'mobx';

export const VISIBLE_BY_DASHBOARD: Record<
    string,
    { low: boolean; mid: boolean; high: boolean } | undefined
> = {
    [DashboardSlugs.FACEBOOK_BREAKDOWN]: {
        low: false,
        mid: false,
        high: true,
    },
    [DashboardSlugs.IT_BENCHMARKS_SPEND_DIST]: {
        low: false,
        mid: false,
        high: true,
    },
};

function createDefaultCohortState(
    dashboardId: string,
    mode: CohortState['mode'],
    comparison: string | null
): CohortState {
    // @ts-expect-error
    return {
        mode,
        config: {
            dynamic: {
                kind: 'dynamic',
                cohorts: [
                    {
                        name: 'Low performers',
                        visible: VISIBLE_BY_DASHBOARD[dashboardId]?.low ?? true,
                        value: 25,
                    },
                    {
                        name: 'Median',
                        visible: VISIBLE_BY_DASHBOARD[dashboardId]?.mid ?? true,
                        value: 50,
                    },
                    {
                        name: 'High performers',
                        visible: VISIBLE_BY_DASHBOARD[dashboardId]?.high ?? true,
                        value: 75,
                    },
                ],
            },
            fixed: {
                kind: 'fixed',
                // should not be nullable
                comparison: comparison,
                cohorts: [
                    {
                        name: 'Low performers',
                        visible: VISIBLE_BY_DASHBOARD[dashboardId]?.low ?? true,
                        lower: 0,
                        upper: 33,
                    },
                    {
                        name: 'Median',
                        visible: VISIBLE_BY_DASHBOARD[dashboardId]?.mid ?? true,
                        lower: 33,
                        upper: 66,
                    },
                    {
                        name: 'High performers',
                        visible: VISIBLE_BY_DASHBOARD[dashboardId]?.high ?? true,
                        lower: 66,
                        upper: 100,
                    },
                ],
            },
        },
    };
}

export function getDefaultQuerySegments(
    organizationId: number,
    dashboard: Pick<Dashboard, 'id'>,
    configuration: DashboardConfiguration,
    preference?: CohortState
): CohortState {
    const DEFAULT_MODE: Record<string, CohortMode | undefined> = {
        [DashboardSlugs.FACEBOOK_BREAKDOWN]: 'fixed',
        [DashboardSlugs.GA4_REVENUE_BREAKDOWN]: 'dynamic',
        [DashboardSlugs.MEDIA_MIX]: 'dynamic',
    };

    const comparison = configuration.cohort?.options[0]?.value || null;
    // console.log('DEBUG comparison', comparison);

    let mode: CohortMode = DEFAULT_MODE[dashboard.id] ?? 'fixed';

    if (!comparison) {
        // if there is not valid fixed cohort metric candidates fallback to dynamic mode
        mode = 'dynamic';
    }
    if (!configuration.cohort || !configuration.cohort?.modes.includes(mode)) {
        mode = configuration.cohort?.modes[0] ?? 'dynamic';
    }

    const defaultValue = createDefaultCohortState(dashboard.id, mode, comparison);

    const cohortOptionKeys = new Set(
        configuration.cohort ? configuration.cohort.options.map((item) => item.value) : []
    );
    const applied = preference ?? defaultValue;

    // console.log('DEBUG configuration.cohort', configuration.cohort);
    // console.log('DEBYG preference', preference);

    if (!configuration.cohort || !configuration.cohort.modes.includes(applied.mode)) {
        console.warn(
            `cohort mode '${applied.mode}' not supported for dashboard ${dashboard.id}. falling back to default...`
        );
        return defaultValue;
    }

    if (
        applied.config.fixed.comparison &&
        !cohortOptionKeys.has(applied.config.fixed.comparison)
    ) {
        console.warn(
            `cohort comparison metric '${applied.config.fixed.comparison}' not supported for dashboard ${dashboard.id}. falling back to default...`
        );
        return defaultValue;
    }

    // if (!configuration.cohort.com applied.config.fixed.comparison)

    // if (!COHORT_PREVIEW_ORG_IDS.includes(organizationId)) {
    //     // console.info('not a preview org so falling back to dynamic ranking');
    //     // legacy hack to force old behaviour for non-preview orgs
    //     return { ...applied, mode: 'dynamic' };
    // }
    // console.log('DEBUG FINAL', cohortOptionKeys, applied.config.fixed.comparison);
    return applied;
}

export const useControlApi = (): ControlApi => {
    const queryClient = useQueryClient();

    const context = {
        storage: useStorageContext(),
        organization: useOrganizationScope(),
        workspace: useWorkspaceContext(),
        dashboard: useDashboardContext(),
        workspaceV2: useWorkspaceContextV2(),
    };

    const api = { dashboard: useDashboardApi() };

    const entities = {
        workspace: context.workspace.workspace,
        dashboard: api.dashboard.getDashboard(),
        configuration: api.dashboard.getConfiguration(),
    };

    const peergroup = useMemo(
        () =>
            context.workspaceV2.peergroups.data?.find(
                (item) => item.plugin.id === entities.dashboard.pluginId
            ) ?? null,
        [context.workspaceV2.peergroups.data, entities.dashboard.pluginId]
    );

    const state = useMemo(() => {
        return selectControlState(entities.configuration);
    }, [entities.configuration]);

    // const plugin = useMemo(
    //     () =>
    //         context.workspaceV2.plugins.data?.find(
    //             (item) => item.id === context.dashboard.dashboard.pluginId
    //         ),
    //     [context.workspaceV2.plugins, context.dashboard.dashboard.pluginId]
    // );

    const { data: preferences } = useQuery({
        queryKey: ['preferences', entities.workspace.id, entities.dashboard.id],
        queryFn() {
            return context.dashboard.adapter.preferences.getPreferences(
                entities.workspace,
                entities.dashboard,
                entities.configuration
            );
        },
        suspense: true,
        staleTime: Infinity,
    });

    const updatePreferences = useMutation({
        async mutationFn() {
            const filters = entities.configuration.properties.data.flatMap((filter) => {
                const value = instance.getDataFilterValue(filter);
                return value ? [value] : [];
            });
            const segment = entities.configuration.properties.traits.flatMap((filter) => {
                const value = instance.getSegmentFilterValue(filter);
                return value ? [value] : [];
            });
            const cohort = instance.getCohortValue();
            const response = await context.dashboard.adapter.preferences.savePreferences(
                entities.workspace,
                entities.dashboard,
                { filters, segment, cohort }
            );
            await context.workspaceV2.peergroups.refetch();
            return response;
        },
        async onSuccess() {
            await queryClient.invalidateQueries([
                'preferences',
                entities.workspace.id,
                entities.dashboard.id,
            ]);
        },
    });

    function cancelQueries() {
        cancelChartQueries(queryClient, entities.workspace, entities.dashboard);
    }

    function getDateFilter(
        configuration: DashboardDateConfiguration
    ): ControlDateValue | null {
        const current = context.storage.workspace.getDashboardDateState(
            entities.workspace
        );
        if (current) {
            // TODO: move this into the feature middleware. to do that we
            // need to include service hooks in the route/entrypoint configuration
            // to enable middleware overriding at the service level
            const daysAgo = periodDifference(current.period, 'day');

            // if the date range was set on a dashboard that does not enforce lookback limit, we need
            // to ensure that when switching to another dashboard that does enforce it
            // that we apply the limit
            const restricted =
                featureset.date_ranges?.config?.lookback_period_days &&
                !featureset.date_ranges.config.exclude?.includes(entities.dashboard.id)
                    ? daysAgo > featureset.date_ranges.config.lookback_period_days
                    : false;

            if (!restricted) {
                return current;
            }

            return {
                ...current,
                period: { amount: 4, interval: 'week' },
                granularity: 'week',
            };
        }
        if (!configuration) {
            return null;
        }

        if (
            configuration.filter?.default?.operator === 'previous' &&
            configuration.filter.default.value &&
            isDurationValue(configuration.filter.default.value)
        ) {
            return {
                granularity: configuration.granularity,
                period: configuration.filter.default.value,
                comparison: configuration.comparison,
            };
        }

        if (
            configuration.filter?.default?.operator === 'between' &&
            configuration.filter.default.value &&
            isDateRangeValue(configuration.filter.default.value)
        ) {
            return {
                granularity: configuration.granularity,
                period: {
                    start: configuration.filter.default.value.from,
                    end: configuration.filter.default.value.to,
                },
                comparison: configuration.comparison,
            };
        }

        console.warn(`unexpected date config`);
        return null;
    }

    function getCohortValue(): CohortState | null {
        if (!entities.configuration.cohort) {
            return null;
        }
        const valueLocal = context.storage.control.getCohort(entities.dashboard);
        // console.log('valueLocal', valueLocal);
        const valueDefault = getDefaultQuerySegments(
            context.organization.organization.id,
            entities.dashboard,
            entities.configuration,
            preferences?.cohort ?? undefined
        );

        const applied = valueLocal ?? valueDefault;
        // console.log('DEBUG local', toJS(valueLocal));
        // console.log('DEBUG applied', toJS(applied));
        return applied;

        // const cohortOptionKeys = new Set(
        //     entities.configuration.cohort.options.map((item) => item.id)
        // );

        // // console.log('applied', applied);
        // // console.log('applied', JSON.parse(JSON.stringify(applied)), cohortOptionKeys);

        // // preferences are set at the asset level and so it might
        // // be that a dashboard gets a cohort configuration saved from another dashboard
        // // which would be invalid for this dashboard.
        // // so unless the mode os supported and the comparison metric is part of the view schema
        // // we ignore the preference
        // if (
        //     entities.configuration.cohort.modes.includes(applied.mode) &&
        //     cohortOptionKeys.has(applied.config.fixed.comparison)
        // ) {
        //     return applied;
        // }

        // return valueLocal ?? valueDefault;
    }

    function getDataFilterValue(filter: AnyFilter): AnyCondition | null {
        const valueLocal = context.storage.control.getDataFilter(
            entities.dashboard,
            filter
        );
        if (valueLocal === null) {
            // null value means that filter was explicitly cleared
            return null;
        }
        const valueDefault =
            preferences?.filters.find(
                (candidate) => candidate.key === filter.property.key
            ) ??
            (filter.default?.value && filter.default?.operator
                ? {
                      key: filter.property.key,
                      operator: filter.default.operator,
                      value: filter.default.value,
                  }
                : null);

        // @ts-expect-error
        return (valueLocal ?? valueDefault) || null;
    }

    // TODO: this is a hack. feature check should be implemented in middleware
    // but we need to make sure that the middleware has access to the api/service
    // to make it work
    const featureApi = useFeatureApi();
    const featureset = featureApi.getFeatureset();

    function getSegmentFilterValue(filter: AnyFilter): AnyCondition | null {
        const valueLocal = context.storage.control.getSegmentFilter(
            entities.dashboard,
            filter
        );
        if (valueLocal === null) {
            // null value means that filter was explicitly cleared
            return null;
        }
        // const valuePreference =
        //     preferences?.segment.find(
        //         (candidate) => candidate.key === filter.property.key
        //     ) ?? null;

        let valuePreference = peergroup?.conditions.find(
            (condition) => condition.key === filter.property.key
        );

        // const trait = plugin?.traits.find((trait) => trait.key === filter.property.key);
        // console.log('DEBUG trait', trait);
        // console.log('DEBUG constraints', getTypeConstraints(filter.property.type));
        // const [constraint] = [
        //     // HACK this is legacy code but we need to use the new trait model from the plugins
        //     ...(trait ? getTypeConstraints(trait.type) : []),
        // ].filter(
        //     (item): item is BetweenConstraintExpression | undefined =>
        //         item?.operator === 'between'
        // );

        // console.log('DEBUG filter.property', filter.property.key, filter.property);

        // console.log('DEBUG valuePreference', valuePreference);
        // console.log('DEBUG valueLocal', valueLocal);
        // console.log('DEBUG constraint', constraint);

        // HACK downstream input components currently expect between conditions only
        if (
            valuePreference &&
            (isLessThanCondition(valuePreference) ||
                isLessThanOrEqualCondition(valuePreference))
        ) {
            valuePreference = {
                ...valuePreference,
                operator: 'between',
                value: {
                    from: 1,
                    to: valuePreference.value,
                },
            };
        }

        // HACK downstream input components currently expect between conditions only
        if (
            valuePreference &&
            (isGreaterThanCondition(valuePreference) ||
                isGreaterThanOrEqualCondition(valuePreference))
        ) {
            valuePreference = {
                ...valuePreference,
                operator: 'between',
                value: {
                    from: valuePreference.value,
                    to: null,
                },
            };
        }

        let valueDefault: AnyCondition | null = null;

        const valueApplied = valueLocal ?? valuePreference ?? valueDefault;

        // console.log('DEBUG valueApplied', valueApplied);

        // console.log('DEBUG valueApplied', valueApplied);

        if (
            isPropertyFilter(filter) &&
            isTreeType(filter.property.type) &&
            isInCondition(valueApplied)
        ) {
            if (featureset.subcategories && !featureset.subcategories.enabled) {
                // TODO: this is a hack. feature check should be implemented in middleware
                // but we need to make sure that the middleware has access to the api/service
                // to make it work
                const applied = applyTreeValue(state, filter.property, valueApplied);

                const parentByChildren =
                    state.treeValueParentByChildValue[filter.property.key];

                const restricted = [
                    ...new Set(
                        applied?.value.flatMap((candidate) => {
                            const parent = parentByChildren?.[candidate as string];
                            if (!parent) {
                                return [candidate];
                            }
                            return [parent];
                        })
                    ),
                ];

                if (!applied || !restricted || restricted?.length === 0) {
                    return null;
                }
                return {
                    ...applied,
                    value: restricted,
                };
            }
            // for enum and tree based types we should remove any selected value
            // that does not match any of the provided options
            return applyTreeValue(state, filter.property, valueApplied);
        }

        return valueApplied;
    }

    function getValue(configuration: DashboardDateConfiguration): DashboardControlValue {
        const date = getDateFilter(configuration);
        return {
            filters: entities.configuration.properties.data.flatMap((filter) => {
                const value = getDataFilterValue(filter);
                return value ? [value] : [];
            }),
            segments: entities.configuration.properties.traits.flatMap((filter) => {
                const value = getSegmentFilterValue(filter);
                return value ? [value] : [];
            }),
            date,
            cohort: getCohortValue(),
        };
    }

    const initialValue = useMemo<DashboardControlValue>((): DashboardControlValue => {
        // console.log('initial vlaue');
        return getValue({
            comparison: DEFAULT_COMPARISON,
            granularity: entities.configuration.defaults.granularity ?? 'week',
            filter: entities.configuration.properties.timestamp,
        });
    }, [entities.dashboard.id, entities.configuration, preferences]);

    const instance: ControlApi = {
        getValue,
        isDirty(configuration: DashboardDateConfiguration) {
            // console.log('checking...');
            const { date: _it, ...initialTest } = initialValue;
            const { date: _vt, ...valueTest } = this.getValue(configuration);
            // console.log('before', initialTest);
            // console.log('after', valueTest);
            return !isEqual(initialTest, valueTest);
        },
        reset() {
            context.storage.control.reset(entities.dashboard);
        },
        saveControls() {
            return updatePreferences.mutateAsync();
        },
        // Dates
        setDateFilter(configuration) {
            context.storage.workspace.setDashboardDateState(entities.workspace, {
                ...configuration,
                comparison: configuration.comparison ?? { interval: 'period', amount: 1 },
                // period: configuration.period ?? { interval: 'week', amount: 4 },
                granularity: configuration.granularity ?? 'week',
            });
            cancelQueries();
        },
        getDateFilter,

        // Data
        setDataFilterValue(filter, value) {
            context.storage.control.setDataFilter(entities.dashboard, filter, value);
            cancelQueries();
        },
        clearDataFilterValue(filter) {
            context.storage.control.setDataFilter(entities.dashboard, filter, null);
            cancelQueries();
        },
        getDataFilterValue,
        // Segment
        setSegmentFilterValue(filter, condition) {
            context.storage.control.setSegmentFilter(
                entities.dashboard,
                filter,
                condition
            );
            cancelQueries();
        },
        clearSegmentFilterValue(filter) {
            context.storage.control.setSegmentFilter(entities.dashboard, filter, null);
            cancelQueries();
        },
        getSegmentFilterValue,

        // Group

        setSegmentGroupValue(value) {
            context.storage.control.setGroups(value);
            cancelQueries();
        },
        clearSegmentGroupValue() {
            context.storage.control.setGroups([]);
            cancelQueries();
        },
        getSegmentGroupValue() {
            return context.storage.control.getGroups();
        },

        // Filter mode

        setFilterMode(value) {
            context.storage.control.setFilterMode(value);
            cancelQueries();
        },
        getFilterMode() {
            return context.storage.control.getFilterMode();
        },

        setCohortValue(value) {
            context.storage.control.setCohort(entities.dashboard, value);
        },
        getCohortValue,
    };
    return instance;
};
