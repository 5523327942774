import { z } from 'zod';

export const OrganizationSchema = z.object({
    id: z.number(),
    name: z.string(),
    domain: z.nullable(z.string()),
    company_type: z.nullable(z.string()),
    finished_on_boarding: z.boolean(),
    default_asset: z.nullable(z.number()),
    created_at: z.date({ coerce: true }),
});

export const OrganizationListSchema = z.array(OrganizationSchema);
