import { StudiesBaseRouteConfig } from '../../base';
import { StudiesChartRouteController } from './studiesChartItemInterface';

export function createStudiesChartRouteController(
    config: Pick<StudiesBaseRouteConfig, 'controller'>
): StudiesChartRouteController {
    const {
        controller: { result: resultController },
    } = config;
    return {
        useProps(context, item, props) {
            const resultProps = resultController.useProps({
                visualization: item.visualization,
                item: item.result,
            });

            return {
                result: resultProps,
                scale: {
                    startAtZero: true,
                    mode: props.mode,
                },
            };
        },
    };
}
