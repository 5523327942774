import { AnyReportDependency, ReportDependencyStatus } from '../../../../../app';
import { ReportItemAggregate } from '../../item';
import { StudytItemStatusProps } from '../../status';
import { StudyDependencyItemProps } from './studyDependencyItemProps';

export function buildDependencyItemProps(
    item: ReportItemAggregate,
    dependency: AnyReportDependency
): StudyDependencyItemProps {
    if (dependency.kind === 'survey') {
        const isSubmitted = !!item.submission;
        const isFulfilled = isSubmitted;

        let status: StudytItemStatusProps<ReportDependencyStatus> = {
            color: 'green',
            label: 'Fulfilled',
            kind: 'fulfilled',
            description: null,
        };

        return {
            kind: 'survey',
            label: 'Complete survey',
            description: `You must fill out a survey to access this report`,
            status,
            isFulfilled: isFulfilled,
            link: {
                form: {
                    to: `/u/assets/${item.asset.id}/reporting/submissions/new?survey=${item.dataset.id}`,
                },
                detail: item.submission
                    ? {
                          to: `/u/assets/${item.asset.id}/reporting/submissions/${item.submission.id}`,
                      }
                    : null,
            },
        };
    }
    return {
        kind: 'integration',
        isFulfilled: false,
        label: 'Connect integration',
        description: null,
        status: {
            color: 'green',
            label: 'Fulfilled',
            kind: 'fulfilled',
            description: null,
        },
    };
}
