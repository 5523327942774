import pluralize from 'pluralize';
import { useMemo } from 'react';
import { PeerControllerConfig } from '../../base';
import { PeerCompanySelectController } from './peerCompanySelectInterface';
import { PeerCompanySelectViewProps } from './peerCompanySelectProps';

export function createPeerCompanySelectController(
    config: PeerControllerConfig
): PeerCompanySelectController {
    const {
        controller: {
            company: {
                list: { useProps: useCompanyListProps },
            },
        },
    } = config;
    return {
        useProps(context, item, props): PeerCompanySelectViewProps {
            const company = useCompanyListProps(context, item.list, {
                metadata: {},
            });

            return {
                action: {
                    clear:
                        item.list.items.length == 0
                            ? null
                            : {
                                  onClick: props.onClear,
                              },
                },
                collection: {
                    label:
                        item.list.items.length > 0
                            ? `${item.list.items.length} ${pluralize('company', item.list.items.length)} selected`
                            : `No companies selected`,
                },
                items: item.list.items,
                getItemProps(item) {
                    const itemProps = company.getItemProps(item);
                    return {
                        id: itemProps.id,
                        company: itemProps,
                        select: {
                            id: itemProps.id,
                            value: itemProps.id,
                            isSelected: true,
                            button: {
                                onClick() {
                                    props.onRemove(item);
                                },
                            },
                        },
                    };
                },
            };
        },
    };
}
