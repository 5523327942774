import { AxiosInstance, AxiosResponse } from 'axios';
import {
    AccountPreferenceDto,
    AccountPreferenceSaveDto,
    SectionType,
} from './accountPreferenceDto';
import qs from 'qs';

export async function listAssetDashboardPreferences(
    api: AxiosInstance,
    sections: Array<SectionType>,
    assetId: string | number,
    dashboards: Array<string | number>,
    plugins: Array<string>,
    options?: {
        token?: string;
        apiKey?: string;
        organizationId?: number;
    }
): Promise<AccountPreferenceDto> {
    const headers: Record<string, string> = {};
    if (options?.token) {
        headers['Authorization'] = `bearer ${options.token}`;
    }
    const params: Record<string, unknown> = {
        asset_id: assetId,
        // dashboard,
        sections,
    };
    if (options?.apiKey) {
        params['api-key'] = options.apiKey;
    }
    if (options?.organizationId) {
        params['organization'] = options.organizationId;
    }
    const response = await api.get<unknown, AxiosResponse<AccountPreferenceDto>>(
        `/api/v1/account/preference-sections`,
        {
            headers,
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            },
        }
    );
    console.log('response', response);
    return response.data;
}

export async function getPreferences(
    api: AxiosInstance,
    sections: Array<SectionType>,
    assetId?: string | number,
    dashboard?: string,
    pluginId?: string,
    options?: {
        token?: string;
        apiKey?: string;
        organizationId?: number;
    }
): Promise<AccountPreferenceDto> {
    const headers: Record<string, string> = {};
    if (options?.token) {
        headers['Authorization'] = `bearer ${options.token}`;
    }
    const params: Record<string, unknown> = {
        asset_id: assetId,
        dashboard: dashboard ? btoa(dashboard.toString()) : null,
        plugin: pluginId ? btoa(pluginId) : null,
        sections,
    };
    if (options?.apiKey) {
        params['api-key'] = options.apiKey;
    }
    if (options?.organizationId) {
        params['organization'] = options.organizationId;
    }
    const response = await api.get<unknown, AxiosResponse<AccountPreferenceDto>>(
        `/api/v1/account/preferences`,
        {
            headers,
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'brackets' });
            },
        }
    );
    return response.data;
}

export async function savePreferencesApi(
    api: AxiosInstance,
    preference: AccountPreferenceSaveDto
): Promise<AccountPreferenceDto> {
    const response = await api.put<unknown, AxiosResponse<AccountPreferenceDto>>(
        `/api/v1/account/preferences`,
        preference
    );
    return response.data;
}
