import React from 'react';
import {
    ChildMapping,
    ChildAllReturnProps,
    ChildAllReturnPropsOptional,
    ChildReturn,
    ChildReturnOptional,
    ChildReturnProps,
    ChildReturnPropsOptional,
    ChildReturnElements,
    ChildReturnElementsOptional,
    ChildAllReturnElementsOptional,
} from './childrenModel';

// all

export function fetchAllChildrenProps<TMap extends ChildMapping>(
    children: React.ReactElement | React.ReactElement[],
    mapping: TMap
): ChildAllReturnProps<TMap> {
    const normal = Array.isArray(children) ? children : [children];
    const mapped = getAllChildrenProps(normal, mapping);
    for (const [key, klass] of Object.entries(mapping)) {
        if (!mapped[key]) {
            throw new Error(`required child of type '${klass.name}' not found`);
        }
    }
    return mapped as ChildAllReturnProps<TMap>;
}

export function getAllChildrenProps<TMap extends ChildMapping>(
    children:
        | React.ReactElement
        | Array<React.ReactElement[] | React.ReactElement | null>
        | null
        | undefined,
    mapping: TMap
): ChildAllReturnPropsOptional<TMap> {
    const childrenArray = React.Children.toArray(children) as React.ReactElement[];
    const mapped: ChildAllReturnPropsOptional<TMap> = Object.entries(mapping).reduce(
        (acc, [key, klass]) => {
            const children = childrenArray
                .filter((candidate) => candidate.type === klass)
                .map((candidate) => candidate.props);
            return {
                ...acc,
                [key]: children,
            };
        },
        {} as any
    );
    return mapped;
}

// props

export function fetchChildrenProps<TMap extends ChildMapping>(
    children:
        | React.ReactElement
        | Array<React.ReactElement[] | React.ReactElement | null>
        | null,
    mapping: TMap
): ChildReturnProps<TMap> {
    const normal = Array.isArray(children) ? children : [children];
    const mapped = getChildrenProps(normal, mapping);
    for (const [key, klass] of Object.entries(mapping)) {
        if (!mapped[key]) {
            throw new Error(`required child of type '${klass.name}' not found`);
        }
    }
    return mapped as ChildReturnProps<TMap>;
}

export function getChildrenProps<TMap extends ChildMapping>(
    children:
        | null
        | React.ReactElement
        | Array<React.ReactElement[] | React.ReactElement | null>
        | undefined,
    mapping: TMap
): ChildReturnPropsOptional<TMap> {
    const childrenArray = React.Children.toArray(children).filter(
        Boolean
    ) as Array<React.ReactElement>;
    const mapped: ChildReturnProps<TMap> = Object.entries(mapping).reduce(
        (acc, [key, klass]) => {
            const child = childrenArray.find((candidate) => candidate.type === klass) as
                | React.ReactElement
                | undefined;
            if (!child) {
                return acc;
            }
            return {
                ...acc,
                [key]: child.props,
            };
        },
        {} as any
    );
    return mapped;
}

// components

export function fetchChildren<TMap extends ChildMapping>(
    children: React.ReactElement | React.ReactElement[],
    mapping: TMap
): ChildReturn<TMap> {
    const normal = Array.isArray(children) ? children : [children];
    const mapped = getChildren(normal, mapping);
    for (const [key, klass] of Object.entries(mapping)) {
        if (!mapped[key]) {
            throw new Error(`required child of type '${klass.name}' not found`);
        }
    }
    return mapped as ChildReturn<TMap>;
}

export function getChildren<TMap extends ChildMapping>(
    children: React.ReactElement | React.ReactElement[],
    mapping: TMap
): ChildReturnOptional<TMap> {
    const childrenArray = React.Children.toArray(children) as React.ReactElement[];
    const mapped: ChildReturn<TMap> = Object.entries(mapping).reduce(
        (acc, [key, klass]) => {
            const child = childrenArray.find((candidate) => candidate.type === klass) as
                | React.ReactElement
                | undefined;

            if (!child) {
                return acc;
            }

            const Comp: React.FC = (props) =>
                React.cloneElement(child, { ...child?.props, ...props });
            return {
                ...acc,
                [key]: Comp,
            };
        },
        {} as any
    );
    return mapped;
}

export function fetchElements<TMap extends ChildMapping>(
    children: React.ReactElement | Array<React.ReactElement | null>,
    mapping: TMap
): ChildReturnElements<TMap> {
    const normal = Array.isArray(children) ? children : [children];
    const mapped = getElements(normal, mapping);
    for (const [key, klass] of Object.entries(mapping)) {
        if (!mapped[key]) {
            throw new Error(`required child of type '${klass.name}' not found`);
        }
    }
    return mapped as ChildReturnElements<TMap>;
}

export function getElements<TMap extends ChildMapping>(
    children: React.ReactElement | Array<React.ReactElement | null>,
    mapping: TMap
): ChildReturnElementsOptional<TMap> {
    const childrenArray = React.Children.toArray(children) as React.ReactElement[];
    const mapped: ChildReturnElementsOptional<TMap> = Object.entries(mapping).reduce(
        (acc, [key, klass]) => {
            const child = childrenArray.find((candidate) => candidate.type === klass) as
                | React.ReactElement
                | undefined;

            if (!child) {
                return acc;
            }
            return {
                ...acc,
                [key]: child,
            };
        },
        {} as any
    );
    return mapped;
}

export function getAllElements<TMap extends ChildMapping>(
    children: React.ReactElement | React.ReactElement[],
    mapping: TMap
): ChildAllReturnElementsOptional<TMap> {
    const childrenArray = React.Children.toArray(children) as React.ReactElement[];
    const mapped: ChildAllReturnElementsOptional<TMap> = Object.entries(mapping).reduce(
        (acc, [key, klass]) => {
            const children = childrenArray
                .filter((candidate) => candidate.type === klass)
                .map((candidate) => candidate.props);
            return {
                ...acc,
                [key]: children,
            };
        },
        {} as any
    );
    return mapped;
}
