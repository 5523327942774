import { ModalController } from './modalInterface';

export function createModalController(): ModalController {
    return {
        useProps(deps, props) {
            return {
                id: props.id,
                containerProps: {
                    isOpen: deps.disclosure.isOpen,
                    onClose: deps.disclosure.onClose,
                },
                triggerProps: {
                    onClick() {
                        deps.disclosure.onOpen();
                    },
                },
                disclosure: deps.disclosure,
            };
        },
    };
}
