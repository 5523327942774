import {
    Box,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Select,
    StackDivider,
    Tag,
    Text,
    useDisclosure,
    VStack,
    Button,
    Center,
    Alert,
    AlertIcon,
    AlertTitle,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
    AiOutlineArrowRight,
    AiOutlineCheck,
    AiOutlineClose,
    AiOutlinePlus,
    AiOutlineSearch,
} from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { HiOutlineCube } from 'react-icons/hi';
import {
    OrganizationMappableListProps,
    OrganizationMappingOptionProps,
} from '../../../../view/assets';
import {
    EntityControlControllerProps,
    EntityControlDeps,
    EntityControlViewProps,
    EntityListViewProps,
    PluginItemViewProps,
} from '../../../../view';
import { SettingAccountItemProviderConfig } from '../../../../route';
import { SettingAssetPluginConfig } from './pluginConfig';
import { SettingAssetPluginViewProps } from './pluginProps';
import { FaArrowRight } from 'react-icons/fa';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import { SettingPluginItemContainerProps } from './item';

export function createSettingAssetPluginView(
    init: SettingAssetPluginConfig,
    config: SettingAccountItemProviderConfig,
    ItemContainer: React.FC<SettingPluginItemContainerProps>
): React.FC<SettingAssetPluginViewProps> {
    const { controller } = init;
    const {
        UI: { Link, Heading, Tooltip, Table, Tbody, Thead, Tr, Td, Th },
        Layout: {
            Container,
            Content,
            Panel,
            List,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    const AssetIcon = HiOutlineCube;

    const ItemView: React.FC<PluginItemViewProps> = (props) => {
        return (
            <HStack fontWeight="medium" spacing={4}>
                <Box p={2.5} bg="whiteAlpha.300" borderRadius="md" flexShrink={0}>
                    <Image h={5} w={5} src={props.iconUrl}></Image>
                </Box>
                <VStack align="start" w="full" spacing={0}>
                    <Text>{props.title}</Text>
                    <Tooltip
                        placement="top"
                        hasArrow={true}
                        p={4}
                        bg="#1D1C21"
                        minW="20rem"
                        label={
                            <VStack align="start" w="full" fontWeight="medium">
                                <HStack w="full" userSelect="none" spacing={3}>
                                    <Box
                                        p={2}
                                        bg="whiteAlpha.100"
                                        borderRadius="md"
                                        flexShrink={0}
                                    >
                                        <Image h={5} w={5} src={props.iconUrl}></Image>
                                    </Box>
                                    <HStack w="full">
                                        <Text>{props.title}</Text>
                                        <Box
                                            bg={`${props.status.dependencies.colorScheme}.400`}
                                            w={2}
                                            h={2}
                                            borderRadius="full"
                                        />
                                    </HStack>
                                </HStack>
                                <VStack
                                    align="start"
                                    w="full"
                                    spacing={3}
                                    divider={
                                        <StackDivider
                                            borderWidth={1}
                                            borderColor="whiteAlpha.300"
                                        />
                                    }
                                >
                                    <HStack w="full">
                                        <Text color="whiteAlpha.700">
                                            {props.status.dependencies.description}
                                        </Text>
                                    </HStack>
                                    <VStack align="start" w="full">
                                        <Text
                                            fontSize="xs"
                                            fontWeight="bold"
                                            textTransform="uppercase"
                                            color="whiteAlpha.500"
                                            letterSpacing="wide"
                                        >
                                            Requirements
                                        </Text>
                                        <VStack align="start" w="full" spacing={2}>
                                            {props.status.dependencies.items.map(
                                                (item) => (
                                                    <HStack
                                                        key={item.label}
                                                        userSelect="none"
                                                        spacing={2}
                                                        w="full"
                                                        justify="space-between"
                                                    >
                                                        <Text color="whiteAlpha.800">
                                                            {item.label}
                                                        </Text>
                                                        <HStack
                                                            color={`${item.colorScheme}.400`}
                                                            spacing={2}
                                                        >
                                                            <Icon
                                                                fontSize="sm"
                                                                as={
                                                                    item.colorScheme ===
                                                                    'green'
                                                                        ? AiOutlineCheck
                                                                        : AiOutlineClose
                                                                }
                                                            />
                                                            <Text>{item.status}</Text>
                                                        </HStack>
                                                    </HStack>
                                                )
                                            )}
                                        </VStack>
                                    </VStack>
                                </VStack>
                            </VStack>
                        }
                    >
                        <HStack fontSize="sm" cursor="default">
                            <Box
                                bg={
                                    props.status.dependencies.colorScheme
                                        ? `${props.status.dependencies.colorScheme}.400`
                                        : 'whiteAlpha.700'
                                }
                                w={1.5}
                                h={1.5}
                                borderRadius="full"
                            />
                            <Text
                                color={
                                    props.status.dependencies.colorScheme
                                        ? `${props.status.dependencies.colorScheme}.400`
                                        : 'whiteAlpha.700'
                                }
                            >
                                {props.status.dependencies.summary}
                            </Text>
                        </HStack>
                    </Tooltip>
                </VStack>
            </HStack>
        );
        return (
            <Tr>
                <Td>
                    <HStack py={3} fontWeight="medium" spacing={4}>
                        <Box p={2.5} bg="whiteAlpha.300" borderRadius="md" flexShrink={0}>
                            <Image h={5} w={5} src={props.iconUrl}></Image>
                        </Box>
                        <VStack align="start" w="full" spacing={1}>
                            <Text>{props.title}</Text>
                            <Tooltip
                                placement="top"
                                hasArrow={true}
                                p={4}
                                bg="#1D1C21"
                                minW="20rem"
                                label={
                                    <VStack align="start" w="full" fontWeight="medium">
                                        <HStack userSelect="none" spacing={3}>
                                            {/* <Box
                                                bg={`${props.status.dependencies.colorScheme}.400`}
                                                w={2}
                                                h={2}
                                                borderRadius="full"
                                            />
                                            <Text
                                                color={`${props.status.dependencies.colorScheme}.400`}
                                            >
                                                {props.status.dependencies.label}
                                            </Text> */}
                                            <Box
                                                p={2}
                                                bg="whiteAlpha.100"
                                                borderRadius="md"
                                                flexShrink={0}
                                            >
                                                <Image
                                                    h={5}
                                                    w={5}
                                                    src={props.iconUrl}
                                                ></Image>
                                            </Box>
                                            <HStack>
                                                <Text>{props.title}</Text>
                                                <Box
                                                    bg={`${props.status.dependencies.colorScheme}.400`}
                                                    w={2}
                                                    h={2}
                                                    borderRadius="full"
                                                />
                                            </HStack>
                                        </HStack>
                                        <VStack
                                            align="start"
                                            w="full"
                                            spacing={3}
                                            divider={
                                                <StackDivider
                                                    borderWidth={1}
                                                    borderColor="whiteAlpha.300"
                                                />
                                            }
                                        >
                                            <HStack w="full">
                                                <Text color="whiteAlpha.700">
                                                    {
                                                        props.status.dependencies
                                                            .description
                                                    }
                                                </Text>
                                            </HStack>
                                            <VStack align="start" w="full">
                                                <Text
                                                    fontSize="xs"
                                                    fontWeight="bold"
                                                    textTransform="uppercase"
                                                    color="whiteAlpha.500"
                                                    letterSpacing="wide"
                                                >
                                                    Requirements
                                                </Text>
                                                <VStack
                                                    align="start"
                                                    w="full"
                                                    spacing={2}
                                                >
                                                    {props.status.dependencies.items.map(
                                                        (item) => (
                                                            <HStack
                                                                key={item.label}
                                                                userSelect="none"
                                                                spacing={2}
                                                                w="full"
                                                                justify="space-between"
                                                            >
                                                                <Text color="whiteAlpha.800">
                                                                    {item.label}
                                                                </Text>
                                                                <HStack
                                                                    color={`${item.colorScheme}.400`}
                                                                    spacing={2}
                                                                >
                                                                    <Icon
                                                                        fontSize="sm"
                                                                        as={
                                                                            item.colorScheme ===
                                                                            'green'
                                                                                ? AiOutlineCheck
                                                                                : AiOutlineClose
                                                                        }
                                                                    />
                                                                    <Text>
                                                                        {item.status}
                                                                    </Text>
                                                                </HStack>
                                                            </HStack>
                                                        )
                                                    )}
                                                </VStack>
                                            </VStack>
                                        </VStack>
                                    </VStack>
                                }
                            >
                                <HStack fontSize="sm" cursor="default">
                                    <Box
                                        bg={`${props.status.dependencies.colorScheme}.400`}
                                        w={2}
                                        h={2}
                                        borderRadius="full"
                                    />
                                    <Text
                                        color={`${props.status.dependencies.colorScheme}.400`}
                                    >
                                        {props.status.dependencies.summary}
                                    </Text>
                                </HStack>
                            </Tooltip>
                        </VStack>
                    </HStack>
                </Td>
                <Td></Td>
                <Td>
                    {/* <HStack w="full" justify="end">
                        <Tooltip
                            placement="top-end"
                            hasArrow={true}
                            p={4}
                            bg="#1D1C21"
                            label={
                                <VStack align="start" w="full" fontWeight="medium">
                                    <HStack userSelect="none" spacing={2}>
                                        <Box
                                            bg={`${props.status.colorScheme}.400`}
                                            w={2}
                                            h={2}
                                            borderRadius="full"
                                        />
                                        <Text>{props.status.label}</Text>
                                    </HStack>
                                    <VStack
                                        align="start"
                                        w="full"
                                        spacing={3}
                                        divider={
                                            <StackDivider
                                                borderWidth={1}
                                                borderColor="whiteAlpha.300"
                                            />
                                        }
                                    >
                                        <Text color="whiteAlpha.700">
                                            {props.status.description}
                                        </Text>
                                    </VStack>
                                </VStack>
                            }
                        >
                            <Tag
                                colorScheme={props.status.colorScheme}
                                size="lg"
                                cursor="default"
                            >
                                {props.status.label}
                            </Tag>
                        </Tooltip>
                    </HStack> */}
                </Td>
            </Tr>
        );
    };

    return (props) => {
        const breadcrumbsProps = props.getBreadcrumbsProps();
        return (
            <Container>
                <Header>
                    <BreadcrumbsList>
                        {breadcrumbsProps.map((item, index) => (
                            <BreadcrumbsItem key={index}>{item.label}</BreadcrumbsItem>
                        ))}
                    </BreadcrumbsList>
                    <ActionList>
                        <Link {...props.getPageActionProps()}>
                            <Button
                                size="sm"
                                bg="whiteAlpha.300"
                                _hover={{
                                    bg: 'whiteAlpha.400',
                                }}
                                _focus={{
                                    outline: 'none',
                                }}
                            >
                                Manage data sources
                            </Button>
                        </Link>
                    </ActionList>
                </Header>
                <Content>
                    <Panel.List>
                        <Panel.Item title="Plugins">
                            <List.Container>
                                {props.items.map((item) => {
                                    const itemProps = props.getItemProps(item);
                                    return (
                                        <List.Item
                                            key={item.plugin.id}
                                            label={
                                                <ItemContainer
                                                    {...itemProps}
                                                    as={ItemView}
                                                />
                                            }
                                        >
                                            <></>
                                        </List.Item>
                                    );
                                })}
                            </List.Container>
                        </Panel.Item>
                    </Panel.List>
                    {/* <Table>
                        <Thead>
                            <Tr>
                                <Th>Plugins</Th>
                                <Th></Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {props.items.map((item) => {
                                const itemProps = props.getItemProps(item);
                                return (
                                    <ItemContainer
                                        key={item.plugin.id}
                                        {...itemProps}
                                        as={ItemView}
                                    />
                                );
                            })}
                        </Tbody>
                    </Table> */}
                </Content>
            </Container>
        );
    };
}
