import { chain } from 'lodash';
import { isItemColored, sortItems } from '../../../../../hooks/sidebar/sidebarHelper';
import { makeIntegrationReasonByIntegration } from '../../../../../views/dashboard/deps/hooks/messages';
import { DashboardListItem, listDashboards } from '../../../../../api';
import {
    DashboardDepsCheckResultDto,
    IntegrationDepState,
    IntegrationTestExecutionDetails,
    IntegrationReasonType,
} from '../../../../../api/v2/dashboard/deps';
import { getAccountStatus } from '../../../../../api/account-status';
import {
    Workspace,
    Dashboard,
    DashboardConnectionStatus,
    DashboardAccessStatus,
} from '../../../../domain';
import { DashboardAdapter } from '../../../../app';
import { PluginsByDashboard } from '../../../../../config';
import { DashboardApiImplConfig } from './dashboardApiConfig';
import { m } from 'framer-motion';

export const NON_ERROR_STATES: IntegrationReasonType[] = ['integrated', 'no_integration'];

export function createApiDashboardImpl(config: DashboardApiImplConfig): DashboardAdapter {
    return {
        async listDashboards(context, workspace) {
            const responseDashboards = await listDashboards(
                config.axios,
                [workspace.id as number],
                undefined,
                100,
                'any',
                ['exclusive_access', 'is_highlighted'],
                {
                    callerName: 'api impl',
                    auth: context.auth.scheme,
                }
            );

            const sorted = sortItems(responseDashboards.items);

            const mapped = chain(sorted)
                .map((item): Dashboard => {
                    // const dependencyCheck = dependencyByDashboard[item.slug];
                    // if (!dependencyCheck) {
                    //     throw new Error(
                    //         `no dependency check found for dashboard '${item.slug}'`
                    //     );
                    // }
                    return {
                        object: 'dashboard',
                        id: item.slug,
                        pluginId: item.plugin ?? PluginsByDashboard[item.slug] ?? null,
                        title: item.name,
                        dependencies: item.dependencies,
                        description: item.description ?? null,
                        status: item.status,
                        iconUrl: item.icon_url!,
                        access: getStatus(item),
                        connection: getDashboardConnection(
                            workspace,
                            item.dependencies ?? null
                        ),
                        highlighted: getHighlighted(item),
                    };
                })
                .orderBy(
                    (item) => {
                        return [
                            item.connection.kind !== 'pending' ? 1 : -1,
                            item.highlighted ? 1 : -1,
                        ];
                    },
                    ['desc', 'desc']
                )
                .value();
            return mapped;
            // return [
            //     ...mapped.filter((item) => item.highlighted),
            //     ...mapped.filter((item) => !item.highlighted),
            // ];
        },
        async getStatus(context, workspace, dashboard) {
            const response = await getAccountStatus(
                config.axios,
                workspace.id,
                dashboard.id
            );
            return response;
        },
    };
}

function getStatus(dashboard: DashboardListItem): DashboardAccessStatus {
    const hasAccess = dashboard.access_list?.some((item) => item.has_access);
    if (hasAccess) {
        return 'active';
    }
    return 'inactive';
}

function getHighlighted(dashboard: DashboardListItem) {
    return isItemColored({
        slug: dashboard.slug,
        has_access: dashboard.access_list?.some((item) => item.has_access) ?? false,
    });
}

export function getDashboardConnection(
    workspace: Pick<Workspace, 'id' | 'name'>,
    item: DashboardDepsCheckResultDto | null
): DashboardConnectionStatus {
    const hasAccess = item?.asset_results[workspace.id as number];
    if (hasAccess) {
        return { kind: 'connected' };
    }
    const [failed, ...rest] = item?.tests.filter((test) => !test.test_pass) ?? [];
    if (!failed) {
        return {
            kind: 'error',
            error: new Error('no connection state on dashboard'),
        };
    }

    if (rest.length === 0 && failed.test.test_name === 'Data Synced') {
        return { kind: 'syncing' };
    }

    const detailsIntegration = failed.execution_details.find(
        (item): item is IntegrationTestExecutionDetails =>
            !!(item.state as IntegrationDepState).by_integration
    );

    const flattened = Object.values(
        detailsIntegration?.state.by_integration ?? {}
    ).flat();

    if (flattened.some((assetState) => assetState.status === 'no_integration')) {
        return { kind: 'pending' };
    }

    const errorState = flattened.find(
        (asssetState) => !NON_ERROR_STATES.includes(asssetState.status)
    );

    if (!errorState) {
        return { kind: 'connected' };
    }
    // console.log('item.test', errorState);

    if (errorState.status === 'connected_integ_syncing') {
        return { kind: 'syncing' };
    }

    // if (errorState.status === 'connected_integ_unmapped') {
    //     return { kind: 'syncing' };
    // }

    const formatted = makeIntegrationReasonByIntegration(
        errorState.status,
        errorState,
        {
            title: 'asdsa',
        },
        workspace.name
    );

    return {
        kind: 'error',
        error: new Error(formatted.text),
    };
}
