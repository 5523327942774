import React, { useEffect } from 'react';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { PageController } from '../../../view/page';
import { AnyPageEvent } from './trackingPageEvent';

export function createPageTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyPageEvent>();
            return tracker;
        }

        function enhanceController(controller: PageController): PageController {
            return {
                ...controller,
                useProps(...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    useEffect(() => {
                        tracker.track('page_viewed', {
                            page_id: viewProps.id,
                        });
                    }, []);
                    return viewProps;
                },
            };
        }

        const instance = create({
            ...init,
            controller: {
                ...init.controller,
                layout: {
                    ...init.controller.layout,
                    createPage(...args) {
                        const controller = init.controller.layout.createPage(...args);
                        return enhanceController(controller);
                    },
                },
            },
        });
        return instance;
    };
}
