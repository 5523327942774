import React from 'react';
import { PeerRouteConfig } from '../../base';
import {
    PeerCompanySearchController,
    PeerCompanySearchLoader,
} from './peerCompanyMatchInterface';
import {
    PeerCompanySearchContainerProps,
    PeerCompanySearchViewProps,
} from './peerCompanyMatchProps';

export function createPeerCompanySearchContainer(
    config: PeerRouteConfig,
    loader: PeerCompanySearchLoader,
    controller: PeerCompanySearchController,
    View: React.FC<PeerCompanySearchViewProps>
): React.FC<PeerCompanySearchContainerProps> {
    const {
        context: { useContext },
    } = config;
    return (props) => {
        const context = useContext();
        const data = loader.useLoad(context, props);
        const viewProps = controller.useProps(context, data, props);
        return <View {...viewProps} />;
    };
}
