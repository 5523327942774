import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    GridProps,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    StackDivider,
    StackProps,
    Switch,
    Table,
    Tag,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import { RiQuestionAnswerLine } from 'react-icons/ri';
import React from 'react';
import { BsChatLeftDotsFill } from 'react-icons/bs';
import {
    AiFillQuestionCircle,
    AiOutlineArrowRight,
    AiOutlinePlus,
    AiOutlineUser,
} from 'react-icons/ai';
import { Icons } from '../../../../../config';
import {
    SubmissionItemAnswerProps,
    SubmissionItemViewProps,
} from '../../../../view/studies';
import { StudiesSubmissionDetailRouteConfig } from './studiesSubmissionDetailConfig';
import { StudiesSubmissionDetailViewProps } from './studiesSubmissionDetailProps';
import { AttributeItem, AttributeList } from '../../../common';
import { createSubmissionStatusView } from '../common';
import { ChevronRightIcon } from '@chakra-ui/icons';

export function createStudiesSubmissionDetailView(
    config: Pick<StudiesSubmissionDetailRouteConfig, 'UI'>
): React.FC<StudiesSubmissionDetailViewProps> {
    const {
        UI: { Link: ApplicationLink },
    } = config;

    const StatusTag = createSubmissionStatusView(config);

    const AnswerItem: React.FC<SubmissionItemAnswerProps> = (props) => {
        return (
            <VStack w="full" align="start" spacing={1}>
                <HStack w="full" spacing={3} color="whiteAlpha.600" fontSize="md">
                    <Icon position="relative" top={0.5} as={Icons.Studies.Answer} />
                    <Text>{props.title}</Text>
                </HStack>
                <Text fontSize="md" fontWeight="semibold" color="whiteAlpha.800">
                    {props.value.label}
                </Text>
            </VStack>
        );
    };

    return (props) => {
        return (
            <Grid templateColumns="1fr" rowGap={12} w="full">
                <GridItem>
                    <VStack
                        w="full"
                        align="start"
                        spacing={6}
                        divider={
                            <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                        }
                    >
                        <HStack
                            w="full"
                            justify="space-between"
                            spacing={12}
                            align="start"
                        >
                            <VStack
                                w="full"
                                align="start"
                                spacing={4}
                                divider={
                                    <StackDivider
                                        borderColor="whiteAlpha.300"
                                        borderWidth={1}
                                    />
                                }
                            >
                                <HStack w="full" align="start" justify="space-between">
                                    <VStack w="full" align="start" spacing={1}>
                                        <HStack
                                            fontWeight="semibold"
                                            spacing={2}
                                            divider={
                                                <Icon
                                                    boxSize="1.20rem"
                                                    color="whiteAlpha.500"
                                                    as={ChevronRightIcon}
                                                    border="none"
                                                />
                                            }
                                        >
                                            <ApplicationLink to="../..">
                                                <Text
                                                    color="blue.300"
                                                    _hover={{ color: 'blue.400' }}
                                                >
                                                    Reports
                                                </Text>
                                            </ApplicationLink>
                                            <ApplicationLink to="..">
                                                <Text
                                                    color="blue.300"
                                                    _hover={{ color: 'blue.400' }}
                                                >
                                                    Surveys
                                                </Text>
                                            </ApplicationLink>
                                        </HStack>
                                        <Heading fontSize="2xl">
                                            {props.item.study.label}
                                        </Heading>
                                    </VStack>
                                    {props.item.links.response && (
                                        <ButtonGroup>
                                            <ApplicationLink
                                                {...props.item.links.response}
                                            >
                                                <Button
                                                    colorScheme="blue"
                                                    variant="outline"
                                                    size="sm"
                                                >
                                                    View response
                                                </Button>
                                            </ApplicationLink>
                                        </ButtonGroup>
                                    )}
                                </HStack>
                            </VStack>
                        </HStack>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack
                        align="start"
                        w="full"
                        spacing={4}
                        divider={
                            <StackDivider borderColor="whiteAlpha.300" borderWidth={1} />
                        }
                    >
                        <HStack w="full">
                            <Heading fontSize="xl">Details</Heading>
                        </HStack>
                        <AttributeList>
                            <AttributeItem label="Submitted">
                                {props.item.date.relative.label}
                            </AttributeItem>
                            <AttributeItem label="User">
                                <HStack w="full" spacing={2}>
                                    <Icon as={Icons.Accounts.User} />
                                    <Text cursor="default">{props.item.user.label}</Text>
                                </HStack>
                            </AttributeItem>
                            {/* <AttributeItem label="Group">
                                    <HStack spacing={3}>
                                        {props.itemProps.group ? (
                                            <ApplicationLink
                                                {...props.itemProps.group.links.detail}
                                            >
                                                <HStack>
                                                    <Icon as={Icons.Studies.Group}></Icon>
                                                    <Text
                                                        _hover={{
                                                            textDecoration: 'underline',
                                                        }}
                                                    >
                                                        {props.itemProps.group?.label}
                                                    </Text>
                                                </HStack>
                                            </ApplicationLink>
                                        ) : (
                                            <Text>N/A</Text>
                                        )}
                                    </HStack>
                                </AttributeItem> */}
                            <AttributeItem label="Status">
                                <HStack spacing={3}>
                                    <StatusTag {...props.item.status} size="md" />
                                    {props.item.links.response && (
                                        <ApplicationLink {...props.item.links.response}>
                                            <HStack
                                                color="whiteAlpha.700"
                                                cursor="pointer"
                                            >
                                                <Text
                                                    fontWeight="semibold"
                                                    fontSize="sm"
                                                    _hover={{
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    View response
                                                </Text>
                                                <Icon
                                                    fontSize="sm"
                                                    as={AiOutlineArrowRight}
                                                />
                                            </HStack>
                                        </ApplicationLink>
                                    )}
                                </HStack>
                            </AttributeItem>
                        </AttributeList>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack
                        align="start"
                        w="full"
                        spacing={4}
                        divider={
                            <StackDivider borderColor="whiteAlpha.300" borderWidth={1} />
                        }
                    >
                        <HStack w="full">
                            <Heading fontSize="xl">My answers</Heading>
                        </HStack>
                        <VStack align="start" w="full" spacing={6}>
                            <VStack w="full" align="start" spacing={6}>
                                {props.item.answers.map((answer) => (
                                    <AnswerItem key={answer.id} {...answer} />
                                ))}
                            </VStack>
                        </VStack>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}
