import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { FormIds, PageIds } from '../../../../config';
import { PeerRouteConfig } from '../../base';
import {
    PeerGroupCreateController,
    PeerGroupCreateFormProvider,
    PeerGroupCreateLoader,
    PeerGroupCreateStateProvider,
} from './peerGroupCreateInterface';
import { PeerGroupCreateFormValues } from './peerGroupCreateModel';
import { PeerGroupCreateViewProps } from './peerGroupCreateProps';

export function createPeerGroupCreateRoute(
    config: PeerRouteConfig,
    loader: PeerGroupCreateLoader,
    state: PeerGroupCreateStateProvider,
    forms: PeerGroupCreateFormProvider,
    controller: PeerGroupCreateController,
    View: React.FC<PeerGroupCreateViewProps>
): React.FC {
    const {
        repository: { invitation: invitationRepository, peerset: peerSetRepository },
        infra: { toaster },
        context,
        service: { peerset: peersetService },
    } = config;
    return (props) => {
        const toast = toaster.useToast();
        const navigate = useNavigate();

        const viewContext = context.useContext();
        const viewState = state.useState(viewContext);

        const viewForm = forms.useForm(viewContext);
        const viewFormValues = viewForm.watch();

        const viewData = loader.useLoad(viewContext, {
            form: viewForm,
        });

        const peersetMutation = peerSetRepository.useCreate(viewContext);
        const invitationMutation = invitationRepository.useBulk(viewContext);

        const mutationCreate = useMutation({
            mutationFn(values: PeerGroupCreateFormValues) {
                return peersetService.create(viewContext, {
                    companies: values.companies,
                    createPeerset(props) {
                        return peersetMutation.mutateAsync(props);
                    },
                    createInvitations(props) {
                        return invitationMutation.mutateAsync(props);
                    },
                });
            },
        });

        const selected = useMemo(
            () => new Set(viewFormValues.companies),
            [viewFormValues.companies]
        );

        const viewProps = controller.useProps(viewContext, viewData, {
            company: {
                page: {
                    item: {
                        id: PageIds.PEER_GROUP_NEW,
                        title: 'New group',
                        description: `Groups enable filtering data by a known set of companies that you define`,
                        breadcrumbs: [],
                    },
                },
                match: {
                    company: {
                        metadata: {},
                    },
                    search: {
                        value: viewState.value.search,
                        onChange(value) {
                            viewState.onChange({ ...viewState.value, search: value });
                        },
                    },
                    filter: {
                        value: {},
                        onChange(value) {},
                    },
                    query: {
                        term: viewState.value.search,
                        limit: 25,
                    },
                    selected,
                    onAdd(item) {
                        viewForm.setValue('companies', [
                            // add company to start of list for proper UX
                            item.company.id,
                            ...viewFormValues.companies,
                        ]);
                    },
                },
                select: {
                    selected: viewFormValues.companies,
                    onRemove(item) {
                        viewForm.setValue(
                            'companies',
                            viewFormValues.companies.filter(
                                (candidate) => candidate !== item.company.id
                            )
                        );
                    },
                    onClear() {
                        viewForm.setValue('companies', []);
                    },
                },

                form: {
                    id: FormIds.PEER_GROUP_CREATE,
                    action: {
                        label: 'Create group',
                    },
                    form: viewForm,
                    async onSubmit(values) {
                        const response = await mutationCreate.mutateAsync(values);
                        toast({
                            kind: 'success',
                            description: `Successfully created group`,
                        });
                        navigate(`../groups/${response.id}`);
                    },
                    onError(error) {
                        console.log('error', error);
                        toast({
                            kind: 'error',
                            description: `An error occured`,
                        });
                    },
                },
            },
        });
        return <View {...viewProps} />;
    };
}
