import { BillingApi } from './billingInterface';
import { BillingApiConfig } from './billingConfig';
import { createBillingSubscriptionResource } from './subscription';
import { createBillingPaymentResource } from './payment';
import { createBillingPaymentIntentResource } from './payment-intent';
import { createBillingSessionResource } from './session';
import { createBillingPaymentMethodResource } from './payment-method';
import { createBillingPortalResource } from './portal';
import { createBillingPlanResource } from './plan';

export function createBillingApi(config: BillingApiConfig): BillingApi {
    const { client } = config;
    return {
        subscription: createBillingSubscriptionResource(client),
        payment: createBillingPaymentResource(client),
        paymentIntent: createBillingPaymentIntentResource(client),
        session: createBillingSessionResource(client),
        paymentMethod: createBillingPaymentMethodResource(client),
        portal: createBillingPortalResource(client),
        plan: createBillingPlanResource(client),
    };
}
