import { PaymentMethodAdapter } from '../../../../app/billing';
import { PaymentMethodImplConfig } from './paymentMethodApiConfig';

export function createPaymentMethodImpl(
    config: PaymentMethodImplConfig
): PaymentMethodAdapter {
    const {
        api: { billing: api },
    } = config;
    return {
        async find(context) {
            const response = await api.paymentMethod.list(context);
            return response.data;
        },
        async portal(context, props) {
            const response = await api.portal.create(context, {
                return_path: props.returnPath,
                payment_methods: true,
            });
            return response;
        },
    };
}
