import {
    AnyReportDependency,
    ReportIntegrationDependency,
    ReportStudyDependency,
} from './reportModel';

export function isReportIntegrationDependency(
    item: AnyReportDependency
): item is ReportIntegrationDependency {
    return item.kind === 'integration';
}

export function isReportStudyDependency(
    item: AnyReportDependency
): item is ReportStudyDependency {
    return item.kind === 'survey';
}
