import { useMemo } from 'react';
import { GroupItemAggregate } from '../../../../view';
import { groupBySafe, keyBySafe } from '../../../../util';
import { assert } from '../../../../util/assert';
import { PeerGroupListRouteLoader } from './peerGroupListInterface';
import { PeerLoaderConfig } from '../../base';
import { StudiesGroupListAggregate } from './peerGroupListModel';

export function createPeerGroupListLoader(
    config: PeerLoaderConfig
): PeerGroupListRouteLoader {
    const {
        repository: {
            count: countRepository,
            peerset: peerSetRepository,
            invitation: invitationRepository,
        },
    } = config;
    return {
        useLoad(context) {
            const peerSetQuery = peerSetRepository.useFind(
                context,
                {},
                { suspense: true }
            );
            assert(peerSetQuery.status === 'success', 'expected suspense');

            const invitationQuery = invitationRepository.useFind(
                context,
                {
                    source: peerSetQuery.data.items.map((item) => ({
                        kind: 'group',
                        id: item.id,
                    })),
                },
                { suspense: true }
            );

            assert(invitationQuery.status === 'success', 'expected suspense');

            const invitationByGroup = useMemo(
                () =>
                    groupBySafe(invitationQuery.data, (item) => {
                        assert(item.source, `expected invitation to have a group source`);
                        return item.source.id;
                    }),
                [invitationQuery.data]
            );

            const aggregate = useMemo((): StudiesGroupListAggregate => {
                return {
                    group: {
                        items: peerSetQuery.data.items.map(
                            (peerset): GroupItemAggregate => {
                                return {
                                    peerset,
                                    user: null,
                                    count: {
                                        invitation: {
                                            value:
                                                invitationByGroup[peerset.id]?.length ??
                                                0,
                                        },
                                    },
                                };
                            }
                        ),
                    },
                };
            }, [peerSetQuery.data.items, invitationByGroup]);
            return aggregate;
        },
    };
}
