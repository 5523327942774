import { AnyVisualization, BarCountVisualization } from '../../../domain/visualization';
import { ReportV2Dto } from '../../../api';
import { AnyResolvedType, Property } from '../../../domain/attributes';
import { ReportV2Adapter, ReportV2Entity } from '../../../app';
import { ReportV2ImplConfig } from './reportConfig';
import { castViewPropertyToDomain } from '../../api';

export function createReportV2Impl(config: ReportV2ImplConfig): ReportV2Adapter {
    const {
        api: { report: api },
    } = config;

    function toEntity(item: ReportV2Dto): ReportV2Entity {
        // const type = getType
        return {
            id: item.id,
            name: item.name,
            category: item.category,
            description: item.description,
            tags: item.tags,
            thumbnail: item.thumbnail,
            images: item.images,
            dataset: {
                id: item.dataset,
            },
            traits: item.traits.map(
                castViewPropertyToDomain
            ) as Property<AnyResolvedType>[],
            dependencies: item.dependencies,
            visualizations: item.visualizations.map((item): AnyVisualization => {
                if (item.kind === 'bar' && item.aggregation === 'count') {
                    const mapped: BarCountVisualization = {
                        key: item.key,
                        kind: 'bar',
                        name: item.name,
                        description: item.description,
                        granularity: 'week',
                        filters: [],
                        metrics: [],
                        period: null,
                        view: null,
                        ranking: {
                            kind: 'dynamic',
                            cohorts: [],
                        },
                        aggregation: item.aggregation,
                        breakdown: {
                            properties: item.breakdown.keys.map((key) => ({ key })),
                        },
                    };
                    return mapped;
                }
                throw new Error('report visualization not impl');
            }),
        };
    }

    return {
        async find(context, query) {
            const response = await api.report.list(context, {
                limit: query.limit,
            });
            return response.data.map(toEntity);
        },
        async findOne(context, lookup) {
            const response = await api.report.get(context, lookup.id);
            return toEntity(response);
        },
    };
}
