import { PageControllerConfig } from './pageConfig';
import { PageController } from './pageInterface';
import { PageStatusProps } from './status';

export function createPageController(config: PageControllerConfig = {}): PageController {
    return {
        useProps(context, props) {
            return {
                id: props.item.id,
                getHeaderProps() {
                    return {
                        title: props.item.title ?? props.item.id,
                        description: props.item.description ?? null,
                        breadcrumbs: props.item.breadcrumbs,
                    };
                },
                getStatusProps(): PageStatusProps | null {
                    if (props.status?.type === 'readonly') {
                        return {
                            isDisabled: true,
                            description: props.status.description,
                            error: 'You do not have permissions for this action',
                        };
                    }
                    return null;
                },
                getAlertProps() {
                    if (props.status?.type === 'readonly') {
                        return {
                            items: [
                                {
                                    status: props.status.status,
                                    label: props.status.description,
                                },
                            ],
                        };
                    }
                    return {
                        items: [],
                    };
                },
                getBreadcrumbProps() {
                    return {
                        items: props.item.breadcrumbs,
                    };
                },
            };
        },
    };
}
