import { ApplicationLinks } from '../../../config';
import { PeerControllerConfig } from '../base';
import { PeerCompanyEditController } from './peerCompanyEditInterface';
import { PeerCompanyEditViewProps } from './peerCompanyEditProps';

export function createpeerCompanyEditController(
    config: PeerControllerConfig
): PeerCompanyEditController {
    const {
        provider: { createFormController },
        controller: {
            common: {
                page: { useProps: usePageProps },
            },
        },
    } = config;

    const { useProps: useFormProps } = createFormController({
        submitOnEnter: false,
    });

    return {
        useProps(context, item, props): PeerCompanyEditViewProps {
            const page = usePageProps(context, props.page);
            const form = useFormProps(props.form);
            return {
                page,
                form,
                match: props.match,
                select: props.select,
                anchor: {
                    documentation: ApplicationLinks.Documentation.CompetitiveSet,
                },
            };
        },
    };
}
