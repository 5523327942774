import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import {
    SubmissionItemAggregate,
    SubmissionListAggregate,
} from '../../../../view/studies';
import { getCollectionItems } from '../../../../base';
import { keyBySafe } from '../../../../util';
import { StudiesSubmissionListRouteConfig } from './studiesSubmissionConfig';
import { StudiesSubmissionListLoader } from './studiesSubmissionInterface';

export function createStudiesSubmissionListLoader(
    config: Pick<StudiesSubmissionListRouteConfig, 'repository'>
): StudiesSubmissionListLoader {
    const {
        repository: {
            submission: submissionRepository,
            dataset: datasetRepository,
            count: countRepository,
            report: reportRepository,
            collaborator: collaboratorRepository,
        },
    } = config;
    return {
        useLoad(context) {
            const collaboratorQuery = collaboratorRepository.useFind(
                context,
                { workspaces: [context.workspace.id as number] },
                { suspense: true }
            );
            const submissionQuery = submissionRepository.useFind(
                context,
                {
                    asset: {
                        id: context.workspace.id as number,
                    },
                },
                { suspense: true }
            );
            // TODO refactor this into a multi-key lookup
            const datasetQuery = datasetRepository.useFind(
                context,
                { category: 'survey' },
                { suspense: true }
            );

            const reportsQuery = reportRepository.useCollection(
                context,
                {},
                { suspense: true }
            );

            assert(submissionQuery.status === 'success', 'expected suspense');
            assert(reportsQuery.status === 'success', 'expected suspense');
            assert(datasetQuery.status === 'success', 'expected suspense');
            assert(collaboratorQuery.status === 'success', 'expected suspense');

            const countQuery = countRepository.useLookup(
                context,
                submissionQuery.data.map((submission) => ({
                    kind: 'data_ingestion.dataset_member',
                    object: submission.dataset.id,
                })),
                { suspense: true }
            );

            assert(countQuery.status === 'success', 'expected suspense');

            const countByDataset = useMemo(
                () => keyBySafe(countQuery.data, (item) => item.object!),
                [countQuery.data]
            );

            const datasetById = useMemo(
                () => keyBySafe(datasetQuery.data, (item) => item.id),
                [datasetQuery.data]
            );

            const collaboratorById = useMemo(
                () => keyBySafe(collaboratorQuery.data, (item) => item.id.toString()),
                [collaboratorQuery.data]
            );

            const reportsByDataset = useMemo(
                () =>
                    keyBySafe(
                        getCollectionItems(reportsQuery.data),
                        (item) => item.dataset.id
                    ),
                [reportsQuery.data]
            );

            const submissions = useMemo(
                (): SubmissionListAggregate => ({
                    items: submissionQuery.data.flatMap(
                        (submission): SubmissionItemAggregate[] => {
                            const dataset = datasetById[submission.dataset.id] ?? null;
                            const report =
                                reportsByDataset[submission.dataset.id] ?? null;
                            if (!dataset) {
                                console.warn(
                                    `dataset ${submission.dataset.id} not found`,
                                    datasetById
                                );
                                return [];
                            }
                            if (!report) {
                                console.warn(
                                    `report ${submission.dataset.id} not found`,
                                    reportsByDataset
                                );
                                return [];
                            }
                            return [
                                {
                                    user: collaboratorById[submission.user.id] ?? null,
                                    report,
                                    submission,
                                    dataset,
                                    data: null,
                                    respondents: countByDataset[dataset.id] ?? null,
                                },
                            ];
                        }
                    ),
                }),
                [
                    submissionQuery.data,
                    datasetById,
                    reportsByDataset,
                    countByDataset,
                    collaboratorById,
                ]
            );

            return {
                status: 'loaded',
                data: {
                    list: submissions,
                },
            };
        },
    };
}
