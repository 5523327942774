import React from 'react';
import { Navigate } from 'react-router';
import { GRAY, VAROS_GREEN } from '../../config';
import { NoWorkspaceConfigured } from '../../../v2';
import { createWorkspaceNavigationView } from '../../view';
import { ApplicationEntrypointConfig } from '../../entrypoint';
import { useUserApi, createWorkspaceProvider, WorkspaceProviderConfig } from '../../app';
import {
    createApiDashboardImpl,
    createSubscriptionApiImpl,
    createFeaturesetImpl,
} from '../../impl';
import { createWorkspaceRoute } from '../../route';

// export const WORKSPACE_ROUTE: ApplicationEntrypointConfig['route']['workspace'] = {
//     create: createWorkspaceRoute,
//     config: {
//         layout: {
//             wrapper: {
//                 gridTemplateRows: '50px 1fr',
//             },
//             navigation: {
//                 color: 'whiteAlpha.800',
//                 bg: GRAY[900],
//                 borderBottomWidth: '1px',
//                 borderColor: GRAY[600],
//                 borderStyle: 'solid',
//             },
//         },
//         container: {
//             Navigation: (props) => (
//                 <WorkspaceNavigationView
//                     {...props}
//                     style={{
//                         wrapper: {
//                             spacing: 8,
//                             // spacing: 0,
//                             fontWeight: 'medium',
//                             px: 4,
//                             h: '100%',
//                             fontSize: 'sm',
//                         },
//                         list: {
//                             maxW: {
//                                 sm: '100%',
//                                 md: '75%',
//                                 lg: '66%',
//                             },
//                         },
//                         item: {
//                             // px: 2,
//                             spacing: 3,
//                             _selected: {
//                                 borderWidth: '2px',
//                                 borderColor: VAROS_GREEN[200],
//                             },
//                         },
//                         dropdown: {
//                             fontWeight: 'medium',
//                             bg: GRAY[600],
//                         },
//                     }}
//                 />
//             ),
//         },
//     },
// };

// const enhancer = compose();

// export const ConditionalWorkspaceDefaultDashboardRedirect: React.FC = () => {
export const WorkspaceDefaultDashboardRedirect: React.FC = () => {
    const api = useUserApi();
    const url = api.getDefaultWorkspaceUrl();
    if (url) {
        return <Navigate to={url} replace={true} />;
    }
    // shouldn't get here because cases where no workspace are onboarding cases
    return <NoWorkspaceConfigured />;
};

export function configureWorkspaceProvider(
    config: Pick<
        WorkspaceProviderConfig,
        'paramKey' | 'queryKey' | 'workspaceNotFoundPathName'
    >
) {
    return createWorkspaceProvider({
        ...config,
        getDeps: (config) => {
            return {
                adapter: {
                    dashboard: createApiDashboardImpl({ axios: config.api }),
                    subscription: createSubscriptionApiImpl({ axios: config.api }),
                    featureset: createFeaturesetImpl({ axios: config.api }),
                },
            };
        },
    });
}
