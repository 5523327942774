import React, { Suspense, useEffect, useRef } from 'react';
import {
    Button,
    VStack,
    Flex,
    Text,
    Grid,
    GridItem,
    Box,
    Center,
    List,
    ListItem,
    Heading,
    Alert,
    AlertTitle,
    AlertIcon,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    HStack,
    StackDivider,
    useToken,
    FormControl,
    FormErrorMessage,
    Icon,
    Tooltip,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CardSpinner } from '../../../domain';
import {
    fetchAllChildrenProps,
    fetchChildrenProps,
    fetchElements,
    getAllChildrenProps,
    getChildrenProps,
} from '../../util';
import { useLazySuspense, LazySuspense } from '../../vendor/lazy-suspense';
import { assert } from '../../util/assert';
import { SettingsLayout as SettingsLayoutInterface } from './settingsLayoutInterface';
import { SettingsLayoutConfig } from './settingsLayoutConfig';
import { AiOutlineInfoCircle, AiOutlineWarning } from 'react-icons/ai';

export function createSettingsLayout(
    config: SettingsLayoutConfig
): SettingsLayoutInterface {
    const _Wrapper: SettingsLayoutInterface['Wrapper'] = (props) => {
        return <>{props.children}</>;
    };
    const _Container: SettingsLayoutInterface['Container'] = (props) => {
        const { Body, NavigationList } = fetchElements(props.children, {
            // Header: _Header,
            Body: _Body,
            NavigationList: _NavigationList,
        });

        const { NavigationSection } = getAllChildrenProps(NavigationList.props.children, {
            NavigationSection: _NavigationSection,
        });

        // function getSectionItems() {
        //     return
        // }

        // const { NavigationItems } = getAllChildrenProps(NavigationList.props.children, {
        //     NavigationItems: _NavigationItem,
        // });

        const { elementRef, suspenseProps } = useLazySuspense();

        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');

        return (
            <Box
                h="full"
                w="full"
                px={4}
                maxW={{
                    sm: '100vw',
                    md: '90vw',
                    lg: '85vw',
                    xl: '85vw',
                    '2xl': '80vw',
                    '3xl': '72rem',
                }}
                mx="auto"
            >
                <Grid
                    h="full"
                    templateAreas={`
                        "sidebar main"
                        "sidebar main"
                    `}
                    gridTemplateRows="min-content 1fr"
                    gridTemplateColumns={{
                        base: 'minmax(17vw, min-content) 1fr',
                        '2xl': 'minmax(14vw, min-content) 1fr',
                    }}
                    gap={{
                        md: 8,
                        lg: 12,
                        xl: 16,
                    }}
                >
                    <GridItem
                        gridArea="sidebar"
                        borderRightWidth={3}
                        borderStyle="solid"
                        borderColor="whiteAlpha.300"
                        pr={{ md: 8, lg: 12, xl: 16 }}
                        my={8}
                    >
                        <VStack
                            alignItems="flex-start"
                            fontWeight="medium"
                            color="whiteAlpha.900"
                            spacing={8}
                            w="full"
                        >
                            {NavigationSection.map((section, index) => (
                                <VStack
                                    key={index}
                                    alignItems="flex-start"
                                    spacing={2}
                                    w="full"
                                >
                                    <Box
                                        textTransform="uppercase"
                                        color="whiteAlpha.700"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        letterSpacing="wider"
                                        px={4}
                                    >
                                        {section.label}
                                    </Box>
                                    <VStack alignItems="flex-start" spacing={1} w="full">
                                        {getAllChildrenProps(section.children, {
                                            NavigationItems: _NavigationItem,
                                        })
                                            .NavigationItems.filter(
                                                (Item) => Item.visible !== false
                                            )
                                            .map((Item, index) => (
                                                <NavLink
                                                    style={{ width: '100%' }}
                                                    to={Item.to}
                                                    replace={true}
                                                    key={index}
                                                >
                                                    {({ isActive }) => (
                                                        <Box
                                                            borderStyle="solid"
                                                            borderStartWidth={3}
                                                            borderColor={
                                                                isActive
                                                                    ? 'green.500'
                                                                    : 'transparent'
                                                            }
                                                            w="full"
                                                            px={4}
                                                            py={2}
                                                            cursor="pointer"
                                                            aria-selected={isActive}
                                                            _selected={{
                                                                bg: 'whiteAlpha.200',
                                                            }}
                                                            _hover={{
                                                                bg: 'whiteAlpha.200',
                                                            }}
                                                            whiteSpace="nowrap"
                                                        >
                                                            {Item.children}
                                                        </Box>
                                                    )}
                                                </NavLink>
                                            ))}
                                    </VStack>
                                </VStack>
                            ))}
                        </VStack>
                    </GridItem>
                    <GridItem gridArea="main" position="relative">
                        <Box
                            h="full"
                            w="full"
                            overflowY="auto"
                            pr={8}
                            py={8}
                            css={{
                                scrollbarGutter: 'stable both-edges',
                                '&::-webkit-scrollbar': {
                                    '-webkit-appearance': 'none',
                                    width: '0.5rem',
                                },
                                '&::-webkit-scrollbar-track': {},
                                '&::-webkit-scrollbar-corner': {
                                    '-webkit-appearance': 'none',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: '1rem',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: scrollbarBg,
                                    // background: scrollbarHoverBg,
                                },
                            }}
                        >
                            <Suspense
                                {...suspenseProps}
                                fallback={
                                    <Center
                                        position="relative"
                                        alignItems="center"
                                        h="full"
                                        py={4}
                                        top={-8}
                                    >
                                        {/* {Loader} */}
                                        <CardSpinner />
                                    </Center>
                                }
                            >
                                <Box h="full" w="full" ref={elementRef}>
                                    {Body.props.children}
                                </Box>
                            </Suspense>
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        );
    };
    const _Header: SettingsLayoutInterface['Header'] = (props) => {
        return <Box>{props.children}</Box>;
    };

    const _NavigationList: SettingsLayoutInterface['Navigation']['List'] = (props) => {
        return <Box>{props.children}</Box>;
    };

    const _NavigationItem: SettingsLayoutInterface['Navigation']['Item'] = (props) => {
        return <Box>{props.children}</Box>;
    };

    const _NavigationSection: SettingsLayoutInterface['Navigation']['Section'] = (
        props
    ) => {
        return <Box>{props.children}</Box>;
    };

    const _EmptyState: SettingsLayoutInterface['EmptyState'] = (props) => {
        const icon = React.cloneElement(props.icon, { w: 12, h: 12, color: 'red.500' });
        return (
            <Center w="full" h="full" p={6}>
                <VStack spacing={2} fontWeight="medium">
                    {icon}
                    <VStack color="whiteAlpha.700" spacing={0}>
                        <Text fontWeight="medium">{props.header}</Text>
                        <Text fontSize="sm">
                            If this issue continues, please
                            <Box as="span" color="blue.400">
                                {' '}
                                contact support
                            </Box>
                        </Text>
                    </VStack>
                </VStack>
            </Center>
        );
    };

    const _Body: SettingsLayoutInterface['Body'] = (props) => {
        return <Grid gap={8}>{props.children}</Grid>;
    };

    const _Section: SettingsLayoutInterface['Section'] = (props) => {
        return <GridItem>{props.children}</GridItem>;
    };

    const _Loader: SettingsLayoutInterface['Loader'] = (props) => {
        return <>{props.children}</>;
    };

    const _ItemContainer: SettingsLayoutInterface['Page']['Container'] = (props) => {
        const { content, header } = fetchChildrenProps(props.children, {
            content: _PageContent,
            header: _PageHeader,
        });
        const { breadcrumbList } = fetchChildrenProps(header.children, {
            breadcrumbList: _PageBreadcrumbsList,
        });

        const { actionList } = getChildrenProps(header.children, {
            actionList: _PageActionList,
        });

        const { breadcrumbItems } = fetchAllChildrenProps(breadcrumbList.children, {
            breadcrumbItems: _PageBreadcrumbsItem,
        });

        // const { breadcrumbItems } = fetchAllChildrenProps(actionList.children, {
        //     actionListItems: _ItemBreadcrumbsItem,
        // });

        // assert(
        //     breadcrumbItems.length <= 2,
        //     'settings layout only support one level of route nesting'
        // );

        return (
            <VStack align="start" w="full" spacing={6} pb={64} maxW="50rem">
                <HStack justify="space-between" w="full">
                    <Breadcrumb fontSize="2xl" fontWeight="bold" spacing={3}>
                        {breadcrumbItems.map((item, index, array) => {
                            const isLast = array.length - 1 === index;
                            const to = item.to ?? (isLast ? null : '..');
                            const content = <Text>{item.children}</Text>;
                            if (!to) {
                                return (
                                    <BreadcrumbItem cursor="default" key={index}>
                                        <HStack>
                                            <Text>{content}</Text>
                                            {item.description && (
                                                <Tooltip
                                                    label={item.description}
                                                    placement="top"
                                                    hasArrow={true}
                                                    p={4}
                                                    bg="#1D1C21"
                                                >
                                                    <Box as="span">
                                                        <Icon
                                                            // color="whiteAlpha.700"
                                                            as={AiOutlineInfoCircle}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            )}
                                        </HStack>
                                    </BreadcrumbItem>
                                );
                            }
                            return (
                                <BreadcrumbItem key={index} color="whiteAlpha.500">
                                    <BreadcrumbLink
                                        to={to}
                                        as={Link}
                                        _focus={{ outline: 'none' }}
                                    >
                                        <HStack>
                                            <Text>{content}</Text>
                                            {item.description && (
                                                <Tooltip
                                                    label={item.description}
                                                    placement="top"
                                                    hasArrow={true}
                                                    p={4}
                                                    bg="#1D1C21"
                                                >
                                                    <Box as="span">
                                                        <Icon
                                                            // color="whiteAlpha.700"
                                                            as={AiOutlineInfoCircle}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            )}
                                        </HStack>
                                    </BreadcrumbLink>
                                </BreadcrumbItem>
                            );
                        })}
                    </Breadcrumb>
                    {actionList ? (
                        <HStack spacing={3}>{actionList?.children}</HStack>
                    ) : null}
                </HStack>
                {header.description && (
                    <HStack color="whiteAlpha.700" fontWeight="medium">
                        {header.description}
                    </HStack>
                )}

                {content.children}
            </VStack>
        );
        // return (
        //     <Grid h="full" templateRows="min-content 1fr" gap={0}>
        //         <GridItem>
        //             <VStack alignItems="flex-start" spacing={2}>
        //                 <HStack justify="space-between" w="full">
        //                     <Breadcrumb fontSize="2xl" fontWeight="bold" spacing={3}>
        //                         {breadcrumbItems.map((item, index, array) => {
        //                             const isLast = array.length - 1 === index;
        //                             const to = isLast ? null : '..';
        //                             const content = <Text>{item.children}</Text>;
        //                             if (!to) {
        //                                 return (
        //                                     <BreadcrumbItem key={index}>
        //                                         {content}
        //                                     </BreadcrumbItem>
        //                                 );
        //                             }
        //                             return (
        //                                 <BreadcrumbItem
        //                                     key={index}
        //                                     color="whiteAlpha.500"
        //                                 >
        //                                     <BreadcrumbLink
        //                                         to={to}
        //                                         as={Link}
        //                                         _focus={{ outline: 'none' }}
        //                                     >
        //                                         {content}
        //                                     </BreadcrumbLink>
        //                                 </BreadcrumbItem>
        //                             );
        //                         })}
        //                     </Breadcrumb>
        //                     {actionList ? (
        //                         <HStack spacing={3}>{actionList?.children}</HStack>
        //                     ) : null}
        //                 </HStack>
        //             </VStack>
        //         </GridItem>
        //         <GridItem>
        //             <Box
        //                 // borderTopWidth={2}
        //                 // borderColor="whiteAlpha.300"
        //                 // borderStyle="solid"
        //                 h="full"
        //                 w="full"
        //                 // pt={6}
        //                 pb={24}
        //             >
        //                 {content.children}
        //             </Box>
        //         </GridItem>
        //     </Grid>
        // );
    };

    const _PageContent: SettingsLayoutInterface['Page']['Content'] = (props) => {
        return <>{props.children}</>;
    };

    const _PageHeader: SettingsLayoutInterface['Page']['Header']['Container'] = (
        props
    ) => {
        return <>{props.children}</>;
    };

    const _PageBreadcrumbsList: SettingsLayoutInterface['Page']['Header']['Breadcrumbs']['List'] =
        (props) => {
            return <>{props.children}</>;
        };

    const _PageBreadcrumbsItem: SettingsLayoutInterface['Page']['Header']['Breadcrumbs']['Item'] =
        (props) => {
            return <>{props.children}</>;
        };

    const _PageActionList: SettingsLayoutInterface['Page']['Header']['Action']['List'] = (
        props
    ) => {
        return <>{props.children}</>;
    };

    // Panel

    const _PagePanelList: SettingsLayoutInterface['Page']['Panel']['List'] = (props) => {
        return (
            <VStack align="start" spacing={6} w="full">
                {props.children}
            </VStack>
        );
    };

    const _PagePanelItem: SettingsLayoutInterface['Page']['Panel']['Item'] = (props) => {
        const { listContainer, actionList, form, content } = getChildrenProps(
            props.children,
            {
                listContainer: _PageListContainer,
                actionList: _PagePanelActionList,

                form: _PageFormContainer,
                content: _PagePanelContent,
            }
        );
        const { formControls, alerts } = getAllChildrenProps(props.children, {
            formControls: _PagePanelFormControl,
            alerts: _PagePanelAlert,
        });
        const { listItems } = getAllChildrenProps(listContainer?.children, {
            listItems: _PageListItem,
        });
        return (
            <VStack w="full" align="start" spacing={0} overflow="hidden">
                {alerts.length > 0 && (
                    <VStack w="full" pb={6}>
                        {alerts.map((alert) => (
                            <Alert key={alert.label} status={alert.status}>
                                <AlertIcon />
                                <AlertTitle fontWeight="semibold">
                                    {alert.label}
                                </AlertTitle>
                            </Alert>
                        ))}
                    </VStack>
                )}
                <HStack
                    bg="whiteAlpha.300"
                    w="full"
                    px={6}
                    py={3}
                    justify="space-between"
                >
                    <Heading
                        textTransform="uppercase"
                        fontSize="xs"
                        fontWeight="bold"
                        color="whiteAlpha.700"
                        letterSpacing="wider"
                    >
                        {props.title}
                    </Heading>
                    {props.secondary && <Box>{props.secondary}</Box>}
                </HStack>
                <VStack
                    align="start"
                    w="full"
                    spacing={0}
                    fontWeight="medium"
                    divider={
                        <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                    }
                >
                    {formControls?.map((item, index) => (
                        <HStack key={index} w="full" p={6}>
                            <VStack align="start" w="full" spacing={0}>
                                <Text color="whiteAlpha.900">{item.label}</Text>
                                {item.description && (
                                    <Text color="whiteAlpha.700" fontSize="sm">
                                        {item.description}
                                    </Text>
                                )}
                            </VStack>
                            <FormControl isInvalid={item.isInvalid}>
                                {item.children}
                                {item.error ? <FormErrorMessage {...item.error} /> : null}
                            </FormControl>
                        </HStack>
                    ))}
                </VStack>
                {listContainer && listItems && (
                    <VStack
                        w="full"
                        spacing={0}
                        divider={
                            <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                        }
                    >
                        {listItems.map((item) => (
                            <Grid p={6} w="full" templateColumns="1fr min-content">
                                <GridItem>{item.label}</GridItem>
                                <GridItem>{item.children}</GridItem>
                            </Grid>
                        ))}
                    </VStack>
                )}
                {actionList?.children && (
                    <HStack
                        w="full"
                        justify="end"
                        p={6}
                        borderTopWidth={2}
                        borderColor="whiteAlpha.300"
                        borderStyle="solid"
                        spacing={6}
                    >
                        {/* {alert?.status === 'error' ? (
                            <HStack spacing={2}>
                                <Text color="red.400" fontWeight="medium">
                                    {alert.label}
                                </Text>
                                <Icon color="red.400" as={AiOutlineWarning} />
                            </HStack>
                        ) : null} */}
                        {actionList?.children}
                    </HStack>
                )}
                {form?.children}
                {content?.children}
            </VStack>
        );
    };

    const _PagePanelFormControl: SettingsLayoutInterface['Page']['Panel']['FormControl'] =
        (props) => {
            return <></>;
        };

    const _PagePanelActionList: SettingsLayoutInterface['Page']['Panel']['Action']['List'] =
        (props) => {
            return <></>;
        };

    const _PagePanelAlert: SettingsLayoutInterface['Page']['Panel']['Alert'] = (
        props
    ) => {
        return <></>;
    };

    const _PagePanelContent: SettingsLayoutInterface['Page']['Panel']['Content'] = (
        props
    ) => {
        return <></>;
    };

    // List

    const _PageListContainer: SettingsLayoutInterface['Page']['List']['Container'] = (
        props
    ) => {
        return <></>;
    };

    const _PageListItem: SettingsLayoutInterface['Page']['List']['Item'] = (props) => {
        return <></>;
    };

    // Form

    const _PageFormContainer: SettingsLayoutInterface['Page']['Form']['Container'] = (
        props
    ) => {
        return <></>;
    };

    return {
        Wrapper: _Wrapper,
        Container: _Container,
        Navigation: {
            List: _NavigationList,
            Item: _NavigationItem,
            Section: _NavigationSection,
        },
        Header: _Header,
        Body: _Body,
        Section: _Section,
        Loader: _Loader,
        EmptyState: _EmptyState,
        Page: {
            Panel: {
                List: _PagePanelList,
                Item: _PagePanelItem,
                FormControl: _PagePanelFormControl,
                Action: {
                    List: _PagePanelActionList,
                },
                Alert: _PagePanelAlert,
                Content: _PagePanelContent,
            },
            List: {
                Container: _PageListContainer,
                Item: _PageListItem,
            },
            Form: {
                Container: _PageFormContainer,
            },
            Container: _ItemContainer,
            Content: _PageContent,
            Header: {
                Container: _PageHeader,
                Action: {
                    List: _PageActionList,
                },
                Breadcrumbs: {
                    List: _PageBreadcrumbsList,
                    Item: _PageBreadcrumbsItem,
                },
            },
        },
    };
}
