import { ChevronRightIcon, SmallCloseIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Spinner,
    StackDivider,
    Text,
    Tooltip,
    useToken,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineInfoCircle, AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { createInputContainer } from '../../../ui';
import {
    createFilterPopoverContainer,
    FilterPopoverViewProps,
} from '../../../view/common';
import { PeerViewConfig } from '../base';
import { PeerMemberEditViewProps } from '../member';
import { PeerCompanySearchContainerProps } from './match';
import { PeerCompanyEditViewProps } from './peerCompanyEditProps';
import { PeerCompanySelectContainerProps } from './select';

export function createCompanyEditView(
    config: PeerViewConfig,
    MatchList: React.FC<PeerCompanySearchContainerProps>,
    SelectList: React.FC<PeerCompanySelectContainerProps>
): React.FC<PeerCompanyEditViewProps> {
    const {
        UI: {
            Application: { Link, Anchor },
            Input: InputUI,
        },
    } = config;

    const PopoverContainer = createFilterPopoverContainer();
    const InputContainer = createInputContainer();

    const FilterItem: React.FC<FilterPopoverViewProps> = (props) => {
        return (
            <Popover
                {...props.popover}
                placement="bottom"
                isLazy={true}
                lazyBehavior="unmount"
            >
                <PopoverTrigger>
                    <HStack
                        userSelect="none"
                        cursor="pointer"
                        aria-selected={props.item.isActive}
                        borderWidth={2}
                        borderStyle="dashed"
                        borderColor="whiteAlpha.300"
                        h={10}
                        px={4}
                        spacing={2}
                        borderRadius="full"
                        fontWeight="medium"
                        color="whiteAlpha.800"
                        _hover={{
                            bg: 'whiteAlpha.50',
                        }}
                        _selected={{
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'blue.300',
                            color: 'blue.300',
                        }}
                    >
                        {props.item.isActive && (
                            <>
                                <Text>{props.item.value.label}</Text>
                                <Icon
                                    _hover={{ color: 'blue.400' }}
                                    as={SmallCloseIcon}
                                    {...props.button.clear}
                                />
                            </>
                        )}
                        {!props.item.isActive && (
                            <>
                                <Text>{props.button.trigger.children}</Text>
                                <Icon as={AiOutlinePlus} />
                            </>
                        )}
                    </HStack>
                </PopoverTrigger>
                <PopoverContent bg="#1D1C21" _focus={{ outline: 'none' }}>
                    <PopoverHeader px={3}>
                        <HStack w="full" justify="space-between">
                            <HStack w="full">
                                <Text fontWeight="semibold">{props.item.title}</Text>
                            </HStack>
                        </HStack>
                    </PopoverHeader>
                    <PopoverBody>
                        <InputContainer as={InputUI} {...props.item.input} />
                    </PopoverBody>
                    <PopoverFooter px={3}>
                        <HStack w="full" justify="space-between">
                            {props.selection && (
                                <Text
                                    whiteSpace="nowrap"
                                    color="blue.300"
                                    fontWeight="semibold"
                                    fontSize="sm"
                                >
                                    {props.selection.label}
                                </Text>
                            )}
                            <HStack w="full" justify="end">
                                {props.button.reset && (
                                    <Button
                                        size="sm"
                                        _focus={{ outline: 'none' }}
                                        {...props.button.reset}
                                    >
                                        Reset
                                    </Button>
                                )}
                                <Button
                                    colorScheme="blue"
                                    size="sm"
                                    _focus={{ outline: 'none' }}
                                    {...props.button.apply}
                                >
                                    Apply
                                </Button>
                            </HStack>
                        </HStack>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
        );
    };

    const FilterList: React.FC = (props) => {
        return (
            <Wrap shouldWrapChildren={true} spacingX={3} spacingY={1}>
                {props.children}
            </Wrap>
        );
    };

    return (props) => {
        const headerProps = props.page.getHeaderProps();
        return (
            <form
                style={{ width: '100%', height: '100%' }}
                {...props.form.getFormElementProps()}
            >
                <Grid
                    w="full"
                    h="90vh"
                    templateAreas={`
                    "header header header"
                    "left center right"
                    "footer footer footer"
                `}
                    templateRows="min-content minmax(0, 1fr) min-content"
                    templateColumns="minmax(0, 1fr) min-content minmax(0, 1fr)"
                >
                    <GridItem area="header">
                        <VStack w="full" align="start" spacing={4} py={6}>
                            <HStack w="full" align="start">
                                <HStack w="full" spacing={3}>
                                    <Heading fontSize="2xl">{headerProps.title}</Heading>
                                    {headerProps.description && (
                                        <Tooltip
                                            placement="top"
                                            hasArrow={true}
                                            p={4}
                                            bg="#1D1C21"
                                            label={headerProps.description}
                                        >
                                            <Box as="span">
                                                <Icon
                                                    fontSize="2xl"
                                                    as={AiOutlineInfoCircle}
                                                />
                                            </Box>
                                        </Tooltip>
                                    )}
                                </HStack>
                                <HStack spacing={6}>
                                    {/* @ts-expect-error this actually works */}
                                    <Link to={-1}>
                                        <Button
                                            size="sm"
                                            variant="link"
                                            _focus={{ outline: 'none' }}
                                        >
                                            Cancel
                                        </Button>
                                    </Link>
                                    <Button
                                        {...props.form.getSubmitButtonProps()}
                                        size="sm"
                                        colorScheme="green"
                                        _focus={{ outline: 'none' }}
                                    />
                                </HStack>
                            </HStack>
                        </VStack>
                    </GridItem>
                    <GridItem area="left">
                        <MatchList {...props.match} />
                    </GridItem>
                    <GridItem area="center">
                        <VStack h="full" w="full" align="center" spacing={6} px={12}>
                            <Box flex={1} w={0.5} bg="whiteAlpha.200"></Box>
                            <Icon
                                fontSize="3xl"
                                color="whiteAlpha.400"
                                as={HiOutlineSwitchHorizontal}
                            />
                            <Box flex={1} w={0.5} bg="whiteAlpha.200"></Box>
                        </VStack>
                    </GridItem>
                    <GridItem area="right">
                        <VStack w="full" h="full" align="start" spacing={3}>
                            {props.form.error && (
                                <Alert status="error" w="full">
                                    <AlertIcon />
                                    <AlertTitle>{props.form.error.label}</AlertTitle>
                                </Alert>
                            )}
                            <SelectList {...props.select} />
                        </VStack>
                    </GridItem>
                    <GridItem area="footer">
                        <HStack w="full" py={6} justify="end">
                            <Anchor
                                {...props.anchor.documentation}
                                cursor="pointer"
                                color="blue.300"
                                fontWeight="medium"
                                _hover={{ textDecor: 'underline' }}
                            >
                                Learn more about groups
                            </Anchor>
                        </HStack>
                    </GridItem>
                </Grid>
            </form>
        );
    };
}
