import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { FormViewController } from '../../../view/common';
import { AnyFormEvent } from './trackingFormEvent';

export function createFormTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyFormEvent>();
            return tracker;
        }

        function enhanceController(controller: FormViewController): FormViewController {
            return {
                ...controller,
                useProps(props, ...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(
                        {
                            ...props,
                            async onSubmit(values) {
                                const result = await props.onSubmit(values);
                                tracker.track('form_submitted', {
                                    form_id: props.id,
                                    form_data: values,
                                });
                                return result;
                            },
                        },
                        ...args
                    );
                    return viewProps;
                },
            };
        }

        const instance = create({
            ...init,
            controller: {
                ...init.controller,
                layout: {
                    ...init.controller.layout,
                    createForm(...args) {
                        const controller = init.controller.layout.createForm(...args);
                        return enhanceController(controller);
                    },
                },
            },
        });
        return instance;
    };
}
