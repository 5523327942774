import { ApplicationContext } from '../../../../hooks';
import { OrganizationAdapter } from '../../../app';

export function createLegacyOrganizationImpl(config: {
    organization: ApplicationContext['organization'];
}): OrganizationAdapter {
    return {
        async getOrganization() {
            if (!config.organization?.id) {
                throw new Error(`org id not found`);
            }
            return {
                id: config.organization.id,
                name: config.organization.name,
                defaultAsset: null,
                createdAt: config.organization.created_at,
            };
        },
    };
}
