import { ApiError } from '../../base';
import { PlatformApi } from './platformInterface';
import { PlatformApiConfig } from './platformConfig';
import { createWorkspaceResource } from './workspace';
import { createCollaboratorResource } from './collaborator';
import { createAssetResource } from './asset/assetResource';
import { createOrganizationResource } from './organization';
import { createUserResource } from './user';
import { createPreferenceResource } from './preference';
import { createRecommendationResource } from './recommendation';
import { createCompetitiveSetResource } from './competitive';
import { ApiErrorResponseSchema } from '../common';
import { createMeResource } from './me';
import { createClientPreferenceResource } from './client-preferences';
import { createInvitationResource } from './invitation';
import { createCountResource } from './count';
import { createPeerSetResource } from './peer-set';

export function createPlatformApi(config: PlatformApiConfig): PlatformApi {
    const { client } = config;
    const assets = createAssetResource(client);
    return {
        assets,
        me: createMeResource(client, assets),
        organizations: createOrganizationResource(client),
        workspaces: createWorkspaceResource(client),
        collaborators: createCollaboratorResource(client),
        users: createUserResource(client),
        preferences: createPreferenceResource(client),
        recommendations: createRecommendationResource(client),
        competitive: createCompetitiveSetResource(client),
        clientPreferences: createClientPreferenceResource(client),
        invitation: createInvitationResource(client),
        count: createCountResource(client),
        peerset: createPeerSetResource(client),
    };
}
