import { useMemo } from 'react';
import { FormDefinitionEntity } from '../../../../domain/forms';
import { DatasetEntity, resolveDatasetTypes } from '../../../../app/datasets';
import { getCollectionItems, LoadedLookup } from '../../../../base';
import { keyBySafe } from '../../../../util';
import { assert } from '../../../../util/assert';
import { isReferenceType } from '../../../../domain/attributes';
import { StudiesSubmissionNewLoader } from './studiesSubmissionNewInterface';
import { StudiesSubmissionNewAggregate } from './studiesSubmissionNewModel';
import { StudiesSubmissionNewRouteConfig } from './studiesSubmissionNewConfig';

export function createStudiesSubmissionNewLoader(
    config: Pick<StudiesSubmissionNewRouteConfig, 'repository'>
): StudiesSubmissionNewLoader {
    const {
        repository: {
            dataset: datasetRepository,
            member: memberRepository,
            view: viewRepository,
            report: reportRepository,
        },
    } = config;

    function datasetToForm(dataset: DatasetEntity): FormDefinitionEntity {
        return {
            id: dataset.id,
            name: dataset.name,
            description: dataset.description,
            dataset: {
                id: dataset.id,
            },
            fields: dataset.configuration.schema.map((property) => ({
                name: property.name,
                property,
                description: property.description,
            })),
        };
    }

    return {
        useLoad(context, props): LoadedLookup<StudiesSubmissionNewAggregate> {
            const datasetQuery = datasetRepository.useLookup(context, props.dataset, {
                suspense: true,
            });

            const reportsQuery = reportRepository.useCollection(
                context,
                {},
                {
                    suspense: true,
                }
            );

            const memberQuery = memberRepository.useFind(
                context,
                {
                    workspace: context.workspace,
                    types:
                        datasetQuery.data?.configuration.schema.flatMap((item) =>
                            isReferenceType(item.type) ? item.type : []
                        ) ?? [],
                },
                { suspense: true, staleTime: Infinity }
            );

            const viewQuery = viewRepository.useFind(context, {}, { suspense: true });

            assert(datasetQuery.status === 'success', 'expected suspense');
            assert(reportsQuery.status === 'success', 'expected suspense');
            assert(memberQuery.status === 'success', 'expected suspese');
            assert(viewQuery.status === 'success', 'expected suspese');

            const resolvedDataset = useMemo(() => {
                return resolveDatasetTypes(datasetQuery.data, memberQuery.data);
            }, [datasetQuery.data, memberQuery.data]);

            const viewsById = useMemo(
                () => keyBySafe(viewQuery.data, (item) => item.id),
                [viewQuery.data]
            );

            const reportsByDataset = useMemo(
                () =>
                    keyBySafe(
                        getCollectionItems(reportsQuery.data),
                        (item) => item.dataset.id
                    ),
                [reportsQuery.data]
            );

            const propertiesByKey = useMemo(
                () => keyBySafe(resolvedDataset.configuration.schema, (item) => item.key),
                [resolvedDataset.configuration.schema]
            );

            const form = useMemo(
                () => datasetToForm(datasetQuery.data),
                [datasetQuery.data]
            );

            const report = reportsByDataset[resolvedDataset.id];
            assert(report, `no report found for dataset ${resolvedDataset.id}`);

            return {
                status: 'loaded',
                data: {
                    study: {
                        asset: { id: context.workspace.id as number },
                        report,
                        dataset: resolvedDataset,
                        count: { respondents: null },
                        submission: null,
                        // HACK we currently assume the dataset and view have the same slug
                        view: viewsById[resolvedDataset.id] ?? null,
                    },
                    form: {
                        dataset: datasetQuery.data,
                        definition: form,
                        fields: form.fields.flatMap((field) => {
                            const property = propertiesByKey[field.property.key];
                            if (!property) {
                                return [];
                            }
                            return [
                                {
                                    field,
                                    property,
                                },
                            ];
                        }),
                    },
                },
            };
        },
    };
}
