import { useForm } from 'react-hook-form';
import { PeerGroupCreateFormProvider } from './peerGroupCreateInterface';
import { PeerGroupCreateFormValues } from './peerGroupCreateModel';

export function createPeerGroupCreateFormProvider(): PeerGroupCreateFormProvider {
    return {
        useForm(context) {
            const form = useForm<PeerGroupCreateFormValues>({
                defaultValues: {
                    companies: [],
                },
            });
            return form;
        },
    };
}
