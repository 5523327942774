import { useQuery, useQueryClient } from '@tanstack/react-query';
import { normalizeCollection } from '../../../base';
import { ReportV2Adapter } from './reportAdapter';
import { ReportV2Repository } from './reportInterface';
import { ReportV2Query } from './reportQuery';

export function createReportV2Repository(adapter: ReportV2Adapter): ReportV2Repository {
    const PREFIX = ['report-v2'];
    return {
        useCollection(context, query, options) {
            const client = useQueryClient();
            const applied: ReportV2Query = {
                limit: query.limit ?? 100,
            };
            const result = useQuery({
                ...options,
                queryKey: [...PREFIX, 'find', context.organization.id, applied],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return normalizeCollection(response);
                },
                onSuccess(data) {
                    for (const id of data.ids) {
                        const item = data.byId[id];
                        if (item) {
                            client.setQueryData(
                                [...PREFIX, 'lookup', context.organization.id, id],
                                item
                            );
                        }
                    }
                },
            });
            return result;
        },
        useLookup(context, lookup, options) {
            return useQuery({
                ...options,
                queryKey: [...PREFIX, 'lookup', context.organization.id, lookup.id],
                async queryFn() {
                    const response = await adapter.findOne(context, lookup);
                    return response;
                },
            });
        },
    };
}
