import {
    Box,
    Button,
    Checkbox,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    SimpleGrid,
    StackDivider,
    Tag,
    Text,
    VStack,
    Link as ChakraLink,
    Wrap,
    useToken,
    Image,
    TextProps,
} from '@chakra-ui/react';
import React from 'react';
import { StudyItemViewProps } from '../../../../view/studies';
import { StudiesExploreRouteConfig } from './studiesReportListConfig';
import { StudiesExploreViewProps } from './studiesReportListProps';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { MdOutlineOpenInNew } from 'react-icons/md';

export function createStudiesExploreView(
    config: Pick<StudiesExploreRouteConfig, 'Layout' | 'UI'>
): React.FC<StudiesExploreViewProps> {
    const {
        UI: {
            Application: { Link: Link, Anchor, Tooltip },
        },
    } = config;

    const VAROS_YELLOW = '#c6e74f';
    const VAROS_PURPLE: TextProps['color'] = 'purple.400';
    const ACCESS_COLOR = VAROS_PURPLE;

    const Illustration: React.FC<StudyItemViewProps> = (props) => {
        const transformImage = `perspective(500px) scale(1)`;
        const transformImageOrigin = `50%`;

        const shadowGradient =
            'linear-gradient(to top left, rgba(0, 0, 0, 0.9) 0%, transparent 80%)';

        const blurGradient =
            'linear-gradient(to left top, rgba(0, 0, 0, 0.7) 5%, transparent 100%)';

        return (
            <Box
                p={6}
                boxShadow="dark-lg"
                borderWidth={2}
                borderColor="whiteAlpha.300"
                borderRadius="lg"
                borderStyle="dashed"
            >
                <Box position="relative" w="full" height="auto" overflow="hidden">
                    <Box position="relative">
                        <Image
                            {...props.thumbnail.image}
                            display="block"
                            transform={transformImage}
                            transformOrigin={transformImageOrigin}
                            width="100%"
                            height="auto"
                            zIndex="2"
                            boxShadow="dark-lg"
                            // filter="grayscale(50%)"
                        />
                    </Box>
                    {/* <Box
                    position="absolute"
                    bottom="0"
                    right="0"
                    width="100%"
                    height="100%"
                    zIndex="3"
                    style={{
                        maskImage: blurGradient,
                        WebkitMaskImage: blurGradient,
                        backdropFilter: 'blur(2px)',
                    }}
                    pointerEvents="none"
                /> */}
                    <Box
                        position="absolute"
                        bottom="0"
                        right="0"
                        width="100%"
                        height="100%"
                        background={shadowGradient}
                        zIndex="4"
                        pointerEvents="none"
                    />
                </Box>
            </Box>
        );
    };

    const StudyItem: React.FC<StudyItemViewProps> = (props) => {
        const borderGrayColor = useToken('colors', 'whiteAlpha.300');
        const borderBlueColor = useToken('colors', 'blue.300');
        const borderOrangeColor = useToken('colors', 'orange.300');

        let borderAccentColor = ACCESS_COLOR.includes('#')
            ? ACCESS_COLOR
            : useToken('colors', ACCESS_COLOR);

        if (props.status.access.kind === 'available') {
            borderAccentColor = borderBlueColor;
        } else if (props.status.access.kind === 'not_enough_data') {
            borderAccentColor = borderOrangeColor;
        }

        const borderGradient = `linear-gradient(to right, transparent 0%, ${borderGrayColor} 30%, 85%, ${borderAccentColor})`;

        return (
            <VStack
                align="start"
                w="full"
                h="full"
                borderColor="whiteAlpha.300"
                borderStyle="solid"
                borderWidth={2}
                borderRadius="md"
                spacing={3}
                cursor="pointer"
                _hover={{ borderColor: 'whiteAlpha.400' }}
            >
                <Link
                    style={{ width: '100%', height: '100%' }}
                    {...(props.links.responses
                        ? props.links.responses
                        : props.links.detail)}
                >
                    <VStack
                        w="full"
                        h="full"
                        align="start"
                        p={8}
                        position="relative"
                        justify="space-between"
                        spacing={4}
                    >
                        <VStack w="full" h="full" align="start" spacing={4}>
                            <HStack w="full" justify="space-between" align="start">
                                <Illustration {...props} />
                                {/* <Box p={3} borderRadius="sm" bg="whiteAlpha.300">
                                    <Icon
                                        w={6}
                                        h={6}
                                        as={Icons.Studies.Study}
                                        position="relative"
                                        top={0.5}
                                        color="whiteAlpha.700"
                                    />
                                </Box> */}
                                {/* {props.highlight && (
                                    <Tag
                                        position="absolute"
                                        right={4}
                                        top={4}
                                        size="sm"
                                        fontWeight="bold"
                                        color="blackAlpha.900"
                                        bg="#c6e74f"
                                        letterSpacing="wide"
                                    >
                                        {props.highlight.label}
                                    </Tag>
                                )} */}
                            </HStack>
                            <VStack align="start" w="full" spacing={4}>
                                <HStack w="full" justify="space-between">
                                    <Text fontWeight="semibold" noOfLines={1}>
                                        {props.label}
                                    </Text>
                                    {props.status.access.kind !==
                                        'missing_dependencies' && (
                                        <Tooltip
                                            placement="top"
                                            hasArrow={true}
                                            p={4}
                                            bg="#1D1C21"
                                            isDisabled={!props.status.access.description}
                                            label={props.status.access.description}
                                        >
                                            <Box
                                                as="span"
                                                cursor="default"
                                                flexShrink={0}
                                            >
                                                <Tag
                                                    colorScheme={
                                                        props.status.access.color
                                                    }
                                                >
                                                    {props.status.access.label}
                                                </Tag>
                                            </Box>
                                        </Tooltip>
                                    )}
                                    {/* <Text
                                        fontSize="xs"
                                        fontWeight="bold"
                                        textTransform="uppercase"
                                        letterSpacing="wider"
                                        color="whiteAlpha.600"
                                    >
                                        {props.category.label}
                                    </Text> */}
                                </HStack>
                                <Box position="relative" height={0.5} width="100%">
                                    <Box
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        height="100%"
                                        width="100%"
                                        background={borderGradient}
                                    />
                                </Box>
                                <Text noOfLines={2} color="whiteAlpha.700">
                                    {props.description}
                                </Text>
                            </VStack>
                        </VStack>
                        <HStack w="full" justify="space-between" align="end">
                            <Wrap shouldWrapChildren={true}>
                                {props.tags.map((item, index) => (
                                    <Tag
                                        key={index}
                                        size="md"
                                        // colorScheme="blue"
                                        // color="#c6e74f"
                                        bg="whiteAlpha.300"
                                    >
                                        {item.label}
                                    </Tag>
                                ))}
                            </Wrap>
                            <Text
                                fontSize="xs"
                                fontWeight="bold"
                                textTransform="uppercase"
                                letterSpacing="wider"
                                color="whiteAlpha.600"
                            >
                                {props.category.label}
                            </Text>
                        </HStack>
                    </VStack>
                </Link>
            </VStack>
        );
    };

    return (props) => {
        return (
            <Grid
                templateAreas={`
                    "header header"
                    "content content"
                `}
                templateColumns={{
                    base: 'min-content 1fr',
                    lg: 'min-content 1fr',
                }}
                columnGap={12}
                rowGap={6}
                w="full"
            >
                <GridItem gridArea="header">
                    <VStack
                        w="full"
                        align="start"
                        spacing={6}
                        divider={
                            <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                        }
                    >
                        <HStack
                            w="full"
                            justify="space-between"
                            spacing={12}
                            align="start"
                        >
                            <VStack align="start" w="full" spacing={1}>
                                <HStack
                                    fontWeight="semibold"
                                    spacing={2}
                                    divider={
                                        <Icon
                                            boxSize="1.20rem"
                                            color="whiteAlpha.500"
                                            as={ChevronRightIcon}
                                            border="none"
                                        />
                                    }
                                >
                                    <Link to="..">
                                        <Text
                                            color="blue.300"
                                            _hover={{ color: 'blue.400' }}
                                        >
                                            Reports
                                        </Text>
                                    </Link>
                                </HStack>
                                <VStack align="start" w="full" spacing={3} maxW="46rem">
                                    <Heading fontSize="2xl">
                                        Dive-deeper into the strategies of high-performers
                                    </Heading>
                                    <Text
                                        fontSize="lg"
                                        color="whiteAlpha.700"
                                        maxW="42rem"
                                    >
                                        Uncover strategic and tactical insights based on the strategies of high-performers.                                    </Text>
                                    {/* <Anchor id="learn_more_reports" href="">
                                        <Button
                                            fontSize="lg"
                                            // colorScheme="blue"
                                            color="blue.300"
                                            // color="whiteAlpha.600"
                                            // color="#c6e74f"
                                            fontWeight="medium"
                                            variant="link"
                                            rightIcon={
                                                <Icon as={AiOutlineArrowRight}></Icon>
                                            }
                                            _focus={{ outline: 'none' }}
                                        >
                                            Learn more about reports
                                        </Button>
                                    </Anchor> */}
                                </VStack>
                            </VStack>
                            {/* <HStack justify="end">
                                <Button
                                    variant="link"
                                    color="whiteAlpha.600"
                                    // color="white"
                                    whiteSpace="nowrap"
                                    fontWeight="medium"
                                    _hover={{ textDecor: 'underline' }}
                                    _focus={{ outline: 'none' }}
                                    {...props.help.button}
                                >
                                    Request a report
                                </Button>
                            </HStack> */}
                            {/* <HStack>
                                <Button
                                    leftIcon={<Icon as={AiOutlinePlus} />}
                                    rightIcon={<Icon as={LockIcon} />}
                                    size="sm"
                                    colorScheme="blue"
                                    variant="solid"
                                    isDisabled={true}
                                >
                                    New study
                                </Button>
                            </HStack> */}
                        </HStack>
                    </VStack>
                </GridItem>
                {/* <GridItem gridArea="sidebar" fontWeight="medium">
                    <VStack w="full" align="start" spacing={6}>
                        {props.filter.items.map((item) => {
                            const itemProps = props.filter.getItemProps(item);
                            return (
                                <VStack
                                    key={itemProps.label}
                                    w="full"
                                    align="start"
                                    spacing={3}
                                >
                                    <Text
                                        color="whiteAlpha.500"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        textTransform="uppercase"
                                        letterSpacing="wide"
                                    >
                                        {itemProps.label}
                                    </Text>
                                    <VStack w="full" align="start" color="whiteAlpha.800">
                                        {itemProps.options.map((option) => (
                                            <HStack key={option.label}>
                                                <Checkbox />
                                                <Text>{option.label}</Text>
                                            </HStack>
                                        ))}
                                        {itemProps.options.length > 3 && (
                                            <Text
                                                fontSize="sm"
                                                color="whiteAlpha.600"
                                                fontWeight="semibold"
                                            >
                                                Show 13 hidden
                                            </Text>
                                        )}
                                    </VStack>
                                </VStack>
                            );
                        })}
                    </VStack>
                </GridItem> */}
                <GridItem gridArea="content">
                    {/* <VStack w="full" align="start" fontWeight="medium" spacing={4}>
                        <HStack>
                            <Text
                                fontWeight="semibold"
                                color="whiteAlpha.600"
                                cursor="default"
                            >
                                {props.list.items.length} studies found
                            </Text>
                        </HStack>
                        {props.list.items.map((item) => {
                            const itemProps = props.list.getItemProps(item);
                            return <StudyItem key={item.dataset.id} {...itemProps} />;
                        })}
                    </VStack> */}
                    <VStack align="start" w="full" spacing={12}>
                        {props.study.fulfilled.items.length > 0 && (
                            <VStack align="start" w="full" spacing={6}>
                                <HStack
                                    w="full"
                                    justify="space-between"
                                    fontSize="xl"
                                    color="whiteAlpha.800"
                                >
                                    <Text fontWeight="semibold">My reports</Text>
                                </HStack>
                                <SimpleGrid
                                    w="full"
                                    columns={{ base: 1, md: 2, xl: 2 }}
                                    spacing={6}
                                >
                                    {props.study.fulfilled.items.map((item) => {
                                        const itemProps =
                                            props.study.fulfilled.getItemProps(item);
                                        return (
                                            <StudyItem
                                                key={item.dataset.id}
                                                {...itemProps}
                                            />
                                        );
                                    })}
                                </SimpleGrid>
                            </VStack>
                        )}
                        <VStack align="start" w="full" spacing={6}>
                            <HStack w="full" justify="space-between">
                                <Text
                                    fontSize="xl"
                                    fontWeight="semibold"
                                    color="whiteAlpha.800"
                                >
                                    All reports
                                </Text>
                                <Button
                                    variant="link"
                                    color="whiteAlpha.600"
                                    // color="white"
                                    whiteSpace="nowrap"
                                    fontWeight="medium"
                                    rightIcon={<Icon as={MdOutlineOpenInNew} />}
                                    _hover={{ textDecor: 'underline' }}
                                    _focus={{ outline: 'none' }}
                                    {...props.help.button}
                                >
                                    Request a report
                                </Button>
                            </HStack>
                            <SimpleGrid
                                w="full"
                                columns={{ base: 1, md: 2, xl: 2 }}
                                spacing={6}
                            >
                                {props.study.unfulfilled.items.map((item) => {
                                    const itemProps =
                                        props.study.unfulfilled.getItemProps(item);
                                    return (
                                        <StudyItem key={item.dataset.id} {...itemProps} />
                                    );
                                })}
                            </SimpleGrid>
                        </VStack>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}
