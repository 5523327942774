import { IntegrationDefinitionOverrides } from '../../../impl/assets';
import { IntegrationTypes } from '../../integration';

export function getIntegrationDefinitionOverrides(): IntegrationDefinitionOverrides {
    return {
        [IntegrationTypes.VAROSTEST]: {
            requirement: {
                text: `Ad accounts must have a minimum spend of $100 in the last 30 days to be eligible`,
            },
            refreshRate: {
                interval: 'minute',
                range: {
                    from: 5,
                    to: 15,
                },
            },
        },
        [IntegrationTypes.FACEBOOK]: {
            entityName: 'Ad account',
            expedited: true,
            requirement: {
                text: `Ad accounts must have a minimum spend of $100 in the last 30 days to be eligible`,
            },
            refreshRate: {
                interval: 'minute',
                range: {
                    from: 5,
                    to: 25,
                },
            },
        },
        [IntegrationTypes.GOOGLE]: {
            requirement: {
                text: `Ad accounts must have a minimum spend of $100 in the last 30 days to be eligible`,
            },
        },
        [IntegrationTypes.LINKEDIN]: {
            requirement: {
                text: `Ad accounts must have a minimum spend of $1000 in the last 6 months to be eligible`,
            },
        },
        [IntegrationTypes.TIKTOK]: {
            requirement: {
                text: `Ad accounts must have a minimum spend of $100 in the last 30 days to be eligible`,
            },
        },
        [IntegrationTypes.SHOPIFY]: {
            entityName: 'Shop',
            requirement: {
                text: `Shops must have live orders in the last 30 days to be eligible`,
            },
            expedited: true,
            refreshRate: {
                interval: 'minute',
                range: {
                    from: 30,
                    to: 240,
                },
            },
        },
        [IntegrationTypes.GOOGLE_ANALYTICS]: {
            entityName: 'Property',
            requirement: {
                text: `Properties must have a minimum of 100 sessions in the last 30 days to be eligible`,
            },
        },
    };
}

export function getSupportedOnboardingIntegrations(): string[] {
    return [IntegrationTypes.VAROSTEST, IntegrationTypes.FACEBOOK];
}
