import { useMutation, useQuery } from '@tanstack/react-query';
import { ChargeSession, ChargeSessionCreateProps } from '../../../domain/billing';
import { ChargeConfig } from './chargeConfig';
import { ChargeRepository } from './chargeInterface';

export function createChargeRepository(
    config: ChargeConfig
): ChargeRepository {
    const { adapter } = config;
    return {
        useCreateSession(context) {
            const mutationState = useMutation<
                ChargeSession,
                Error,
                ChargeSessionCreateProps
            >({
                mutationFn(payload) {
                    return adapter.createSession(context, payload);
                },
            });
            return mutationState;
        },
        useCharge(context, q, options) {
            const query = useQuery({
                ...options,
                queryKey: ['billing', 'session', q.sessionId],
                async queryFn() {
                    const response = await adapter.retrieveBySession(context, q);
                    return response;
                },
                keepPreviousData: true,
                staleTime: Infinity,
            });
            return query;
        }
    };
}
