import thumbnail from '../../../../images/report_thumbnail_tooling.png';
import screenshot_email from '../../../../images/report_detail_marketing_tooling_email.png';
import screenshot_email_percent from '../../../../images/report_detail_marketing_tooling_email_percent.png';
import screenshot_attribution from '../../../../images/report_detail_marketing_tooling_attribution.png';
import { ReportV2Dto } from '../../../api';
import { DatasetSlugs } from '../../dataset';

const dimensionsWithDescriptions = [
    {
        key: 'email_marketing_tools',
        title: 'Most popular email marketing tools used by companies',
        description: null,
    },
    {
        key: 'email_marketing_spend',
        title: 'Monthly spending on email marketing tools',
        description: null,
    },
    {
        key: 'email_marketing_value',
        title: 'Value perceived from email marketing tools',
        description: null,
    },
    {
        key: 'ecomm_platform',
        title: 'E-commerce platforms being used.',
        description: null,
    },
    {
        key: 'attribution_software',
        title: 'Attribution software being used.',
        description: null,
    },
    {
        key: 'attribution_software_spend',
        title: 'Monthly spending on attribution software',
        description: null,
    },
    {
        key: 'attribution_software_value',
        title: 'Value perceived from attribution software',
        description: null,
    },
    {
        key: 'reporting_software',
        title: 'Reporting software being used.',
        description: null,
    },
    {
        key: 'reporting_software_spend',
        title: 'Monthly spending on reporting software',
        description: null,
    },
    {
        key: 'reporting_software_value',
        title: 'Value perceived from reporting software',
        description: null,
    },
];

export const ToolingReport: ReportV2Dto = {
    id: 'tooling',
    name: 'Marketing Tooling',
    tagline: null,
    description: `See what marketing tools are most used by the top performers.`,
    category: 'marketing',
    dataset: DatasetSlugs.TOOLING,
    traits: [],
    tags: ['tooling'],
    dependencies: [
        {
            kind: 'survey',
            dataset: DatasetSlugs.TOOLING,
        },
    ],
    thumbnail: {
        description: null,
        url: thumbnail,
    },
    images: [
        {
            description: null,
            url: screenshot_email,
        },
        {
            description: null,
            url: screenshot_email_percent,
        },
        {
            description: null,
            url: screenshot_attribution,
        }
    ],
    visualizations: dimensionsWithDescriptions.map((dimension) => ({
        key: dimension.key,
        kind: 'bar',
        name: dimension.title,
        description: dimension.description,
        aggregation: 'count',
        breakdown: {
            keys: [dimension.key],
        },
    })),
};
