import {
    Box,
    Link as ChakraLink,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Spinner,
    StackDivider,
    Tag,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { HiOutlineCube } from 'react-icons/hi';
import { IntegrationItemViewProps } from '../../../../../view';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { SettingIntegrationListViewProps } from './listProps';
import { SettingIntegrationItemContainerProps } from '../item';

export function createSettingIntegrationListView(
    config: SettingAccountItemProviderConfig,
    ItemContainer: React.FC<SettingIntegrationItemContainerProps>
): React.FC<SettingIntegrationListViewProps> {
    const {
        UI: { Link, Heading, Button },
        Layout: {
            Container,
            Content,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    const AssetIcon = HiOutlineCube;

    const ItemView: React.FC<IntegrationItemViewProps> = (props) => {
        const statusTagProps = props.getStatusTagProps();
        const statusTooltipProps = props.getStatusTooltipProps();
        const tagProps = props.getTagProps();
        const dateTagProps = props.getDateTagProps();
        const mappingProps = props.getMappingTagProps();
        return (
            <Grid
                w="full"
                templateColumns="minmax(min-content, 4fr) minmax(150px, 1fr) minmax(150px, min-content) minmax(150px, 1fr)"
                templateRows="1fr"
                p={4}
                gap={6}
            >
                <GridItem>
                    <HStack spacing={4}>
                        <Box bg="whiteAlpha.300" p={2.5} borderRadius="md" flexShrink={0}>
                            <Image h={5} w={5} src={props.iconUrl} />
                        </Box>
                        <VStack align="start" fontWeight="medium" spacing={-0.5} w="full">
                            <Text>{props.title}</Text>
                            {tagProps.length > 0 && (
                                <HStack
                                    w="full"
                                    spacing={1}
                                    divider={
                                        <Box color="whiteAlpha.700" border="none">
                                            &#x2022;
                                        </Box>
                                    }
                                >
                                    {tagProps.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {item.to ? (
                                                <ChakraLink
                                                    as={Link}
                                                    to={item.to}
                                                    _focus={{ outline: 'none' }}
                                                >
                                                    <Text
                                                        key={index}
                                                        fontSize="sm"
                                                        color="whiteAlpha.700"
                                                        whiteSpace="nowrap"
                                                    >
                                                        {item.label}
                                                    </Text>
                                                </ChakraLink>
                                            ) : (
                                                <Text
                                                    key={index}
                                                    fontSize="sm"
                                                    color="whiteAlpha.700"
                                                    whiteSpace="nowrap"
                                                >
                                                    {item.label}
                                                </Text>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </HStack>
                            )}
                        </VStack>
                    </HStack>
                </GridItem>
                <GridItem>
                    {/* {mappingProps && (
                        <HStack h="full" w="full">
                            <Text
                                cursor="default"
                                color="whiteAlpha.700"
                                fontWeight="medium"
                                whiteSpace="nowrap"
                            >
                                {mappingProps.label}
                            </Text>
                        </HStack>
                    )} */}
                </GridItem>
                <GridItem>
                    <HStack h="full" w="full">
                        <Popover
                            trigger="hover"
                            placement="top"
                            openDelay={0}
                            closeDelay={0}
                        >
                            <PopoverTrigger>
                                <Tag
                                    colorScheme={statusTagProps.colorScheme}
                                    size="lg"
                                    userSelect="none"
                                >
                                    <HStack spacing={3}>
                                        <Text whiteSpace="nowrap">
                                            {statusTagProps.label}
                                        </Text>
                                        {statusTagProps.isLoading && (
                                            <Spinner size="sm" speed="3.5s" />
                                        )}
                                    </HStack>
                                </Tag>
                            </PopoverTrigger>
                            <PopoverContent
                                bg="#1D1C21"
                                border="none"
                                borderRadius="sm"
                                fontWeight="medium"
                            >
                                <PopoverArrow bg="#1D1C21" />
                                <PopoverBody p={4} fontSize="sm">
                                    <VStack align="start" w="full" fontWeight="medium">
                                        <HStack>
                                            <Box
                                                w={2}
                                                h={2}
                                                borderRadius="full"
                                                bg={`${statusTagProps.colorScheme}.400`}
                                            />
                                            <Text fontWeight="semibold">
                                                {statusTagProps.label}
                                            </Text>
                                        </HStack>
                                        <VStack
                                            align="start"
                                            w="full"
                                            divider={
                                                <StackDivider
                                                    borderWidth={1}
                                                    borderColor="whiteAlpha.300"
                                                />
                                            }
                                        >
                                            <VStack
                                                align="start"
                                                spacing={2}
                                                color="whiteAlpha.700"
                                                divider={
                                                    <StackDivider
                                                        borderWidth={1}
                                                        borderColor="whiteAlpha.200"
                                                    />
                                                }
                                            >
                                                <Text>{statusTooltipProps?.label}</Text>
                                                {statusTooltipProps?.helperText && (
                                                    <Text>
                                                        {statusTooltipProps?.helperText}
                                                    </Text>
                                                )}
                                            </VStack>
                                            {props.status.summary && (
                                                <Text color="whiteAlpha.500">
                                                    {props.status.summary}
                                                </Text>
                                            )}
                                        </VStack>
                                    </VStack>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </HStack>
                </GridItem>
                <GridItem>
                    <Tooltip
                        {...props.getDateTooltipProps()}
                        placement="top"
                        hasArrow={true}
                        p={4}
                        bg="#1D1C21"
                    >
                        <HStack h="full" w="full" justify="end">
                            <Text
                                cursor="default"
                                color="whiteAlpha.700"
                                fontWeight="medium"
                                whiteSpace="nowrap"
                            >
                                {dateTagProps.label}
                            </Text>
                        </HStack>
                    </Tooltip>
                </GridItem>
            </Grid>
        );
    };

    return (props) => {
        const listProps = props.getListProps();
        const collectionProps = listProps.getCollectionProps();
        console.log('collectionProps', collectionProps);
        return (
            <Container>
                <Header>
                    <BreadcrumbsList>
                        <BreadcrumbsItem>Integrations</BreadcrumbsItem>
                    </BreadcrumbsList>
                    <ActionList>
                        <Link id="settings_account_new_link" to="/u/integrations">
                            <Button
                                size="sm"
                                colorScheme="blue"
                                _focus={{ outline: 'none' }}
                                leftIcon={<Icon as={AiOutlinePlus} />}
                            >
                                New integration
                            </Button>
                        </Link>
                    </ActionList>
                </Header>
                <Content>
                    <Box w="full">
                        <InputGroup w="full">
                            <InputLeftElement>
                                <Icon
                                    fontSize="xl"
                                    color="whiteAlpha.400"
                                    as={AiOutlineSearch}
                                />
                            </InputLeftElement>
                            <Input
                                {...listProps.getSearchProps()}
                                placeholder="Search integrations"
                                py={1.5}
                                borderWidth={2}
                                _placeholder={{ fontWeight: 'medium' }}
                            />
                            {collectionProps.label && (
                                <InputRightElement minW="16rem" userSelect="none">
                                    <HStack w="full" justify="end" px={4}>
                                        <Text
                                            color="whiteAlpha.500"
                                            whiteSpace="nowrap"
                                            fontWeight="semibold"
                                            fontSize="sm"
                                        >
                                            {collectionProps.label}
                                        </Text>
                                    </HStack>
                                </InputRightElement>
                            )}
                        </InputGroup>
                    </Box>
                    <VStack align="start" w="full" spacing={0}>
                        <Grid
                            bg="whiteAlpha.300"
                            w="full"
                            templateColumns="minmax(min-content, 4fr) minmax(150px, 1fr) minmax(150px, min-content) minmax(150px, 1fr)"
                            templateRows="1fr"
                            h={10}
                            px={6}
                            gap={6}
                        >
                            <GridItem>
                                <HStack h="full" w="full">
                                    <Heading
                                        textTransform="uppercase"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Integrations
                                    </Heading>
                                </HStack>
                            </GridItem>
                            <GridItem>
                                {/* <HStack h="full" w="full">
                                    <Text
                                        textTransform="uppercase"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Connections
                                    </Text>
                                </HStack> */}
                            </GridItem>
                            <GridItem>
                                <HStack h="full" w="full">
                                    <Text
                                        textTransform="uppercase"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Status
                                    </Text>
                                </HStack>
                            </GridItem>
                            <GridItem>
                                <HStack h="full" w="full" justify="end">
                                    <Text
                                        textTransform="uppercase"
                                        fontSize="xs"
                                        fontWeight="bold"
                                        color="whiteAlpha.700"
                                        letterSpacing="wider"
                                    >
                                        Connected
                                    </Text>
                                </HStack>
                            </GridItem>
                        </Grid>
                        {/* <HStack
                            bg="whiteAlpha.300"
                            w="full"
                            px={6}
                            // py={3}
                            h={10}
                            justify="space-between"
                        >
                            <Heading
                                textTransform="uppercase"
                                fontSize="xs"
                                fontWeight="bold"
                                color="whiteAlpha.700"
                                letterSpacing="wider"
                            >
                                Integrations
                            </Heading>
                            {collectionProps.status === 'loaded' && (
                                <Text
                                    textTransform="uppercase"
                                    fontSize="xs"
                                    fontWeight="bold"
                                    color="whiteAlpha.500"
                                    letterSpacing="wider"
                                >
                                    {collectionProps.label}
                                </Text>
                            )}
                        </HStack> */}
                        <VStack
                            w="full"
                            spacing={0}
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.300"
                                />
                            }
                        >
                            {collectionProps.status === 'empty' ? (
                                <HStack p={6} w="full" justify="center" spacing={3}>
                                    <Text fontWeight="medium" color="whiteAlpha.500">
                                        No integrations found
                                    </Text>
                                </HStack>
                            ) : null}
                            {props.items.map((item) => {
                                const itemProps = props.getItemProps(item);
                                return (
                                    <ItemContainer
                                        key={item.integration.id}
                                        {...itemProps}
                                        as={ItemView}
                                    />
                                );
                            })}
                        </VStack>
                    </VStack>
                </Content>
            </Container>
        );
    };
}
