import { useMemo } from 'react';
import { MdBolt } from 'react-icons/md';
import { chain, take } from 'lodash';
import { compose } from 'lodash/fp';
import { assert } from '../../../util/assert';
import { CompanyItemViewProps, CompanyListFactory } from '../../../view/companies';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import {
    PeerCompanySearchAggregate,
    PeerCompanySearchController,
    PeerRouterEnhancer,
} from '../../../route';
import { RecommendationCompanyMetadata } from './recommendationCompanyModel';
import { isRecommendationMetadata } from './recommendationCompanyGuard';

export function createCompanyRecommendationEnhancer(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const TOOLTIP_LABEL = `This company was automatically suggested based on your brand profile`;
        const MAX_RECOMMENDATIONS = 50;

        function createPeerRouterEnhancer(): PeerRouterEnhancer {
            return (create) => (config) => {
                function enhanceController(
                    controller: PeerCompanySearchController
                ): PeerCompanySearchController {
                    return {
                        ...controller,
                        useProps(context, item, props) {
                            const recommendationQuery =
                                instance.repository.personalization.recommendation.useFind(
                                    context,
                                    {
                                        kind: 'company',
                                        asset: {
                                            id: context.workspace.id as number,
                                        },
                                    },
                                    {
                                        suspense: true,
                                    }
                                );

                            assert(
                                recommendationQuery.status === 'success',
                                'expected suspense'
                            );

                            const recommended =
                                instance.repository.companies.company.useMultiLookup(
                                    context,
                                    recommendationQuery.data.map((item) => ({
                                        id: item.id,
                                    })),
                                    { suspense: true }
                                );
                            assert(recommended.status === 'success', 'expected suspense');

                            const recommendedItems = recommended.data;
                            const recommendedItemsTruncated = recommendedItems.slice(
                                0,
                                MAX_RECOMMENDATIONS
                            );

                            const metadata = useMemo(
                                (): RecommendationCompanyMetadata => ({
                                    recommended: new Set(
                                        recommendedItemsTruncated.map((item) => item.id)
                                    ),
                                }),
                                [recommendedItemsTruncated]
                            );

                            const merged = useMemo((): PeerCompanySearchAggregate => {
                                // TODO avoid flickering of recommended items when typing
                                if (props.search.value.trim().length > 0) {
                                    return item;
                                }
                                return {
                                    ...item,
                                    list: {
                                        ...item.list,
                                        items: chain([
                                            ...recommendedItemsTruncated.map(
                                                (company) => ({
                                                    company,
                                                    // TODO add asset
                                                    asset: null,
                                                })
                                            ),
                                            ...item.list.items,
                                        ])
                                            .uniqBy((item) => item.company.id)
                                            .value(),
                                    },
                                };
                            }, [item, recommendedItemsTruncated]);

                            return controller.useProps(context, merged, {
                                ...props,
                                company: {
                                    ...props.company,
                                    metadata: {
                                        ...props.company.metadata,
                                        ...metadata,
                                    },
                                },
                            });
                        },
                    };
                }
                return create({
                    ...config,
                    provider: {
                        ...config.provider,
                        match: {
                            ...config.provider.match,
                            createController(...args) {
                                const controller = config.provider.match.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    },
                });
            };
        }

        function enhanceFactory(factory: CompanyListFactory): CompanyListFactory {
            return {
                ...factory,
                build(props, item): CompanyItemViewProps {
                    const viewProps = factory.build(props, item);
                    if (viewProps.highlight) {
                        console.info(`company view already has highlight, skipping...`);
                        return viewProps;
                    }
                    // assert(
                    //     isRecommendationMetadata(props.metadata),
                    //     'expected recommendation metadata to have been passed to controller'
                    // );
                    if (!isRecommendationMetadata(props.metadata)) {
                        return viewProps;
                    }
                    return {
                        ...viewProps,
                        highlight: props.metadata.recommended.has(item.company.id)
                            ? {
                                  label: 'Recommended',
                                  Icon: MdBolt,
                                  description: TOOLTIP_LABEL,
                              }
                            : null,
                    };
                },
            };
        }

        const instance = create({
            ...config,
            router: {
                ...config.router,
                createPeer(init, enhancer) {
                    const enhancers = [
                        ...(enhancer ? [enhancer] : []),
                        createPeerRouterEnhancer(),
                    ];
                    return config.router.createPeer(init, compose(...enhancers));
                },
            },
            v2: {
                ...config.v2,
                companies: {
                    ...config.v2.companies,
                    company: {
                        ...config.v2.companies.company,
                        list: {
                            ...config.v2.companies.company.list,
                            createFactory(...args) {
                                const factory =
                                    config.v2.companies.company.list.createFactory(
                                        ...args
                                    );
                                return enhanceFactory(factory);
                            },
                        },
                    },
                },
            },
        });

        return instance;
    };
}
