import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormSubmissionAdapter } from './formSubmissionAdapter';
import { FormSubmissionRepository } from './formSubmissionInterface';
import { FormSubmissionEntity } from './formSubmissionModel';
import { FormSubmissionCreateProps } from './formSubmissionProps';
import { FormSubmissionLookupQuery } from './formSubmissionQuery';

export function createFormSubmissionRepository(
    adapter: FormSubmissionAdapter
): FormSubmissionRepository {
    const PREFIX = ['forms', 'submissions'];
    function getLookupKey(item: FormSubmissionEntity | FormSubmissionLookupQuery) {
        return [...PREFIX, 'lookup', item.id];
    }
    return {
        useFind(context, query, options) {
            const queryClient = useQueryClient();
            const result = useQuery({
                ...options,
                queryKey: [...PREFIX, 'find', query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                onSuccess(data) {
                    for (const entity of data) {
                        const queryKey = getLookupKey(entity);
                        queryClient.setQueryData(queryKey, entity);
                    }
                },
            });
            return result;
        },
        useLookup(context, query, options) {
            const result = useQuery({
                ...options,
                queryKey: getLookupKey(query),
                async queryFn() {
                    const response = await adapter.lookup(context, query);
                    return response;
                },
            });
            return result;
        },
        useCreate(context) {
            const queryClient = useQueryClient();
            const result = useMutation({
                async mutationFn(props: FormSubmissionCreateProps) {
                    const response = await adapter.create(context, props);
                    return response;
                },
                onSuccess(data, variables, context) {
                    const queryKey = getLookupKey(data);
                    queryClient.setQueryData(queryKey, data);
                },
            });
            return result;
        },
    };
}
