import { StackDivider, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { IntegrationStatus } from '../../../../domain/assets';
import { IntegrationItemStatusProps } from '../status';
import { IntegrationItemAggregate } from './integrationItemModel';

export function buildStatusProps(
    item: IntegrationItemAggregate
): IntegrationItemStatusProps {
    const startedTimeAgoText = moment
        .utc(item.integration.lastSyncedStartedAt ?? item.integration.updatedAt)
        .fromNow();
    const finishedAgoText = item.integration.lastSyncedFinishedAt
        ? moment.utc(item.integration.lastSyncedFinishedAt).fromNow()
        : null;

    const mapping: Record<IntegrationStatus, IntegrationItemStatusProps> = {
        pending: {
            type: 'warning',
            label: 'Pending mapping',
            summary: null,
            tooltip: {
                label: `Integration is connected but has not been mapped as a data source`,
                helperText: `Go to companies and click manage to map this integration to a company`,
                isDisabled: false,
            },
            isLoading: false,
        },
        analyzing: {
            type: 'warning',
            label: 'Analyzing',
            summary: `Started ${startedTimeAgoText}`,
            tooltip: {
                label: 'Integration is being analyzed',
                helperText: null,
                isDisabled: false,
            },
            isLoading: false,
        },
        importing: {
            type: 'warning',
            label: 'Importing',
            summary: `Started ${startedTimeAgoText}`,
            tooltip: {
                label: 'Integration data is currently being imported',
                helperText: null,
                isDisabled: false,
            },
            isLoading: false,
        },
        syncing: {
            type: 'success',
            label: 'Syncing',
            summary: `Started ${startedTimeAgoText}`,
            tooltip: {
                label: 'Integration data is currently being synced',
                helperText: null,
                isDisabled: false,
            },
            isLoading: false,
        },
        ready: {
            type: 'success',
            label: 'Connected',
            summary: finishedAgoText ? `Last synced ${finishedAgoText}` : null,
            tooltip: {
                label: 'Integration data is imported and up-to-date',
                helperText: null,
                isDisabled: false,
            },
            isLoading: false,
        },
        disconnected: {
            type: 'error',
            label: 'Disconnected',
            // summary: `Disconnected ${startedTimeAgoText}`,
            summary: null,
            tooltip: {
                label: 'Integration is no longer connected',
                helperText: null,
                isDisabled: false,
            },
            isLoading: false,
        },
    };

    if (item.integration.status === 'ready' && item.mappings.length === 0) {
        return mapping.pending;
    }

    return (
        mapping[item.integration.status] ?? {
            type: 'warning',
            label: 'Unknown state',
        }
    );
}
