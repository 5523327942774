import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    useToken,
    Link as ChakraLink,
    Box,
    HStack,
    Icon,
    Image,
    Text,
    VStack,
    StackDivider,
    FormControl,
    FormLabel,
    Popover,
    PopoverBody,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    InputGroup,
    InputRightElement,
    InputLeftElement,
    Input,
    Button,
    Tag,
    Grid,
    GridItem,
    Divider,
    Alert,
    AlertIcon,
    AlertTitle,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
    AiOutlineCloudDownload,
    AiOutlineInfoCircle,
    AiOutlinePlus,
    AiOutlineSearch,
} from 'react-icons/ai';
import { ViewEntity } from '../../../../../domain';
import { FileListViewProps, SelectListViewProps } from '../../../../../view/common';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { isNumericType } from '../../../../../domain/attributes';
import { DatasetAggregate, PropertyItemViewProps } from '../../../../../view/datasets';
import { DatasetEntity } from '../../../../../app/datasets';
import { SettingImportConfig } from '../importConfig';
import { SettingImportNewViewProps } from './importNewProps';

export function createSettingImportNewView(
    init: SettingImportConfig,
    config: SettingAccountItemProviderConfig
): React.FC<SettingImportNewViewProps> {
    const { controller } = init;
    const {
        UI: { Link, Heading, Tooltip, Table, Tbody, Thead, Th, Td, Tr },
        Layout: {
            Container,
            Content,
            Panel,
            List,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
                Action: { List: ActionList },
            },
        },
    } = config;

    const PopoverTriggerButton: React.FC<SelectListViewProps<DatasetAggregate>> = (
        props
    ) => {
        const clearButtonProps = props.getClearButtonProps();
        return (
            <HStack
                w="full"
                borderColor="whiteAlpha.300"
                borderStyle="solid"
                borderWidth={2}
                // bg="whiteAlpha.100"
                borderRadius="md"
                p={6}
                cursor="pointer"
                _hover={{
                    bg: 'whiteAlpha.50',
                    borderColor: 'whiteAlpha.400',
                }}
            >
                <VStack align="start" w="full" spacing={3}>
                    {props.current && (
                        <HStack w="full" spacing={3} userSelect="none">
                            {props.current.icon?.kind === 'image' && (
                                <Box p={2} bg="whiteAlpha.300" borderRadius="md">
                                    <Image
                                        w={5}
                                        h={5}
                                        src={
                                            props.current.icon.src ??
                                            'https://cdn.varos.io/assets/common/facebook_logo.png'
                                        }
                                    />
                                </Box>
                            )}
                            <Text fontWeight="semibold">{props.current.label}</Text>
                        </HStack>
                    )}
                    {!props.current && (
                        <HStack w="full" spacing={3} color="whiteAlpha.700">
                            <Box p={2} borderRadius="md">
                                <Icon w={5} h={5} as={AiOutlinePlus} />
                            </Box>
                            <Text fontWeight="semibold">Click to select dataset</Text>
                        </HStack>
                    )}
                </VStack>
                <HStack spacing={6}>
                    {clearButtonProps && (
                        <Button
                            {...clearButtonProps}
                            variant="link"
                            color="whiteAlpha.700"
                            fontWeight="medium"
                            _focus={{ outline: 'none' }}
                            _hover={{ color: 'whiteAlpha.800' }}
                        >
                            Clear
                        </Button>
                    )}
                    <Icon fontSize="lg" as={ChevronDownIcon} />
                </HStack>
            </HStack>
        );
    };

    const DropZone: React.FC<FileListViewProps> = (props) => {
        const buttonProps = props.getButtonProps();
        return (
            <>
                <HStack
                    {...props.getRootProps()}
                    aria-busy={buttonProps.isLoading}
                    fontWeight="medium"
                    w="full"
                    p={12}
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="whiteAlpha.300"
                    borderRadius="lg"
                    _hover={{
                        borderColor: 'whiteAlpha.400',
                        bg: 'whiteAlpha.50',
                    }}
                    _loading={{
                        borderColor: 'whiteAlpha.400',
                        bg: 'whiteAlpha.50',
                        cursor: 'default',
                    }}
                    justify="center"
                    cursor="pointer"
                >
                    <Box w="fit-content">
                        <VStack w="full" spacing={3}>
                            <Text>Drag or drop files here</Text>
                            <Text
                                fontWeight="bold"
                                fontSize="sm"
                                textTransform="uppercase"
                                color="whiteAlpha.500"
                            >
                                Or
                            </Text>
                            <Button
                                {...buttonProps}
                                colorScheme="blue"
                                _focus={{ outline: 'none' }}
                            >
                                Browse files
                            </Button>
                            <VStack color="whiteAlpha.700">
                                {props.items.map((item) => {
                                    const itemProps = props.getItemProps(item);
                                    return (
                                        <HStack key={item.filename}>
                                            <Text>{itemProps.label}</Text>
                                            {itemProps.secondary && (
                                                <Text color="whiteAlpha.600">
                                                    {itemProps.secondary}
                                                </Text>
                                            )}
                                        </HStack>
                                    );
                                })}
                            </VStack>
                        </VStack>
                    </Box>
                </HStack>
                <Input {...props.getInputProps()} />
            </>
        );
    };

    const PropertyItem: React.FC<PropertyItemViewProps> = (props) => {
        return (
            <>
                <GridItem>
                    <HStack color="whiteAlpha.700">
                        <Tooltip
                            placement="top"
                            hasArrow={true}
                            maxW="24rem"
                            p={4}
                            bg="#1D1C21"
                            label={
                                <VStack
                                    align="start"
                                    spacing={2}
                                    // divider={
                                    //     <StackDivider
                                    //         borderColor="whiteAlpha.200"
                                    //         borderWidth={1}
                                    //     />
                                    // }
                                >
                                    <VStack align="start" w="full" spacing={1}>
                                        <HStack w="full" justify="space-between">
                                            <HStack spacing={3}>
                                                <Text>{props.label}</Text>
                                            </HStack>
                                            <Text color="whiteAlpha.500">
                                                {props.type.label}
                                            </Text>
                                        </HStack>
                                        {props.description && (
                                            <HStack w="full">
                                                <Text color="whiteAlpha.700">
                                                    {props.description}
                                                </Text>
                                            </HStack>
                                        )}
                                    </VStack>
                                    <Box
                                        bg="blackAlpha.50"
                                        borderColor="whiteAlpha.200"
                                        borderStyle="solid"
                                        borderWidth={1}
                                        borderRadius="sm"
                                        px={2}
                                    >
                                        <Text color="orange.400">
                                            {props.source.label}
                                        </Text>
                                    </Box>
                                </VStack>
                            }
                        >
                            <HStack cursor="default">
                                <Box
                                    bg="whiteAlpha.100"
                                    borderColor="whiteAlpha.200"
                                    borderStyle="solid"
                                    borderWidth={1}
                                    borderRadius="sm"
                                    px={2}
                                >
                                    <Text color="orange.400">{props.source.label}</Text>
                                </Box>
                                <Box>
                                    <Icon as={AiOutlineInfoCircle} />
                                </Box>
                            </HStack>
                        </Tooltip>
                    </HStack>
                </GridItem>
                <GridItem>
                    <Text userSelect="none" color="whiteAlpha.800">
                        {props.required.label}
                    </Text>
                </GridItem>
                <GridItem textAlign="right">
                    <Tooltip
                        placement="top"
                        hasArrow={true}
                        maxW="24rem"
                        minW="16rem"
                        p={4}
                        bg="#1D1C21"
                        label={
                            <VStack
                                align="start"
                                w="full"
                                divider={
                                    <StackDivider
                                        borderWidth={1}
                                        borderColor="whiteAlpha.300"
                                    />
                                }
                                spacing={3}
                            >
                                <VStack align="start" spacing={1}>
                                    <Text>{props.type.label}</Text>
                                    {props.type.description && (
                                        <Text color="whiteAlpha.700">
                                            {props.type.description}
                                        </Text>
                                    )}
                                </VStack>
                                {/* {props.type.enum && (
                                    <VStack
                                        align="start"
                                        w="full"
                                        spacing={3}
                                        divider={
                                            <StackDivider
                                                borderWidth={1}
                                                borderColor="whiteAlpha.200"
                                            />
                                        }
                                    >
                                        {props.type.members.map((member) => (
                                            <HStack
                                                key={member.value.label}
                                                w="full"
                                                justify="space-between"
                                                align="start"
                                            >
                                                <VStack
                                                    align="start"
                                                    w="full"
                                                    spacing={0}
                                                >
                                                    <Text
                                                        color="whiteAlpha.800"
                                                        fontWeight="medium"
                                                    >
                                                        {member.label}
                                                    </Text>
                                                </VStack>
                                                <Box
                                                    bg="blackAlpha.50"
                                                    borderColor="whiteAlpha.200"
                                                    borderStyle="solid"
                                                    borderWidth={1}
                                                    borderRadius="sm"
                                                    px={2}
                                                >
                                                    <Text color="orange.400">
                                                        {member.value.label}
                                                    </Text>
                                                </Box>
                                            </HStack>
                                        ))}
                                    </VStack>
                                )} */}
                            </VStack>
                        }
                    >
                        <Box userSelect="none">
                            <Text color="whiteAlpha.800">{props.type.label}</Text>
                        </Box>
                    </Tooltip>
                </GridItem>
            </>
        );
    };

    return (props) => {
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');

        const pageProps = props.getPageProps();
        const layoutProps = props.getLayoutProps();
        const breadcrumbsProps = pageProps.getBreadcrumbProps();
        const viewProps = props.getViewProps();
        const formProps = viewProps.getFormProps();
        const propertyListProps = viewProps.getPropertyListProps();
        const datasetSelectListProps = viewProps.getDatasetSelectListProps();
        const entryListProps = viewProps.getEntryListProps();
        const entryTableProps = entryListProps.getTableProps();

        return (
            <form {...formProps.getFormElementProps()}>
                <Container>
                    <Header>
                        <BreadcrumbsList>
                            {breadcrumbsProps.items.map((item, index) => (
                                <BreadcrumbsItem
                                    key={index}
                                    to={item.to ?? undefined}
                                    description={item.description}
                                >
                                    {item.label}
                                </BreadcrumbsItem>
                            ))}
                        </BreadcrumbsList>
                        <ActionList>
                            <Link {...props.getCancelActionProps().link}>
                                <Button
                                    {...props.getCancelActionProps().button}
                                    bg="whiteAlpha.300"
                                    _hover={{
                                        bg: 'whiteAlpha.400',
                                    }}
                                    size="sm"
                                    _focus={{
                                        outline: 'none',
                                    }}
                                />
                            </Link>
                        </ActionList>
                    </Header>
                    <Content>
                        <VStack
                            w="full"
                            align="start"
                            spacing={12}
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.300"
                                />
                            }
                        >
                            <VStack w="full" align="start" spacing={6}>
                                <VStack align="start" w="full" spacing={2}>
                                    <Heading fontSize="xl" fontWeight="semibold">
                                        Select dataset
                                    </Heading>
                                    <Text color="whiteAlpha.700">
                                        Nulla ut pariatur et esse veniam. Commodo aute id
                                        culpa ex nisi esse nulla nisi et reprehenderit
                                    </Text>
                                </VStack>
                                <FormControl w="full">
                                    <FormLabel>Dataset</FormLabel>
                                    <Popover
                                        placement="bottom-start"
                                        isLazy={true}
                                        lazyBehavior="unmount"
                                        closeDelay={0}
                                        {...datasetSelectListProps.getPopoverProps()}
                                    >
                                        <PopoverArrow />
                                        <PopoverTrigger>
                                            <Box>
                                                <PopoverTriggerButton
                                                    {...datasetSelectListProps}
                                                />
                                            </Box>
                                        </PopoverTrigger>
                                        <PopoverContent
                                            borderColor="whiteAlpha.300"
                                            bg="#141414"
                                            minW="32rem"
                                            _focus={{ outline: 'none' }}
                                        >
                                            <PopoverBody
                                                p={0}
                                                maxH="50vh"
                                                overflow="auto"
                                            >
                                                <VStack align="start" w="full" py={3}>
                                                    <VStack align="start" w="full" px={3}>
                                                        <Text fontWeight="semibold">
                                                            Datasets
                                                        </Text>
                                                    </VStack>
                                                    <HStack w="full" p={3}>
                                                        <InputGroup>
                                                            <Input placeholder="Search datasets" />
                                                            <InputLeftElement>
                                                                <Icon
                                                                    color="whiteAlpha.500"
                                                                    as={AiOutlineSearch}
                                                                />
                                                            </InputLeftElement>
                                                        </InputGroup>
                                                    </HStack>
                                                    <VStack
                                                        align="start"
                                                        w="full"
                                                        divider={
                                                            <StackDivider borderColor="whiteAlpha.300" />
                                                        }
                                                        spacing={0}
                                                    >
                                                        {datasetSelectListProps.items.map(
                                                            (item) => {
                                                                const itemProps =
                                                                    datasetSelectListProps.getItemProps(
                                                                        item
                                                                    );
                                                                return (
                                                                    <HStack
                                                                        key={
                                                                            itemProps.value
                                                                        }
                                                                        w="full"
                                                                        p={3}
                                                                        spacing={3}
                                                                        onClick={
                                                                            itemProps.onClick
                                                                        }
                                                                        cursor="pointer"
                                                                        justify="space-between"
                                                                        _hover={{
                                                                            bg: 'whiteAlpha.50',
                                                                        }}
                                                                        fontWeight="medium"
                                                                    >
                                                                        <HStack
                                                                            spacing={3}
                                                                        >
                                                                            {itemProps
                                                                                .icon
                                                                                ?.kind ===
                                                                                'image' && (
                                                                                <Box
                                                                                    p={2}
                                                                                    bg="whiteAlpha.200"
                                                                                    borderRadius="md"
                                                                                >
                                                                                    <Image
                                                                                        w={
                                                                                            5
                                                                                        }
                                                                                        h={
                                                                                            5
                                                                                        }
                                                                                        src={
                                                                                            itemProps
                                                                                                .icon
                                                                                                .src
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            )}
                                                                            <Text>
                                                                                {
                                                                                    itemProps.label
                                                                                }
                                                                            </Text>
                                                                        </HStack>
                                                                        {itemProps.isSelected && (
                                                                            <Tag colorScheme="blue">
                                                                                Selected
                                                                            </Tag>
                                                                        )}
                                                                    </HStack>
                                                                );
                                                            }
                                                        )}
                                                    </VStack>
                                                </VStack>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </FormControl>
                                {layoutProps.showSchema && (
                                    <VStack
                                        align="start"
                                        w="full"
                                        maxW="32rem"
                                        spacing={3}
                                    >
                                        <FormControl w="full">
                                            <FormLabel>Columns</FormLabel>
                                            <VStack
                                                fontWeight="medium"
                                                w="full"
                                                p={6}
                                                borderRadius="lg"
                                                borderWidth={2}
                                                borderColor="whiteAlpha.300"
                                                fontSize="sm"
                                                spacing={6}
                                                // bg="whiteAlpha.100"
                                            >
                                                <Grid
                                                    w="full"
                                                    templateColumns="minmax(min-content, 1fr) min-content min-content"
                                                    columnGap={3}
                                                    rowGap={2}
                                                >
                                                    <GridItem pb={1}>
                                                        <Text
                                                            textTransform="uppercase"
                                                            fontWeight="bold"
                                                            fontSize="xs"
                                                            color="whiteAlpha.600"
                                                        >
                                                            Dimensions
                                                        </Text>
                                                    </GridItem>
                                                    <GridItem pb={1}>
                                                        <Text
                                                            textTransform="uppercase"
                                                            fontWeight="bold"
                                                            fontSize="xs"
                                                            color="whiteAlpha.600"
                                                        >
                                                            Required
                                                        </Text>
                                                    </GridItem>
                                                    <GridItem pb={1}>
                                                        <Text
                                                            textTransform="uppercase"
                                                            fontWeight="bold"
                                                            fontSize="xs"
                                                            color="whiteAlpha.600"
                                                            textAlign="right"
                                                        >
                                                            Type
                                                        </Text>
                                                    </GridItem>
                                                    {propertyListProps.items
                                                        .filter(
                                                            (item) =>
                                                                !isNumericType(
                                                                    item.property.type
                                                                )
                                                        )
                                                        .map((item) => (
                                                            <PropertyItem
                                                                key={item.property.key}
                                                                {...propertyListProps.getItemProps(
                                                                    item
                                                                )}
                                                            />
                                                        ))}
                                                    <Divider
                                                        my={3}
                                                        borderWidth={1}
                                                        borderColor="whiteAlpha.300"
                                                        gridColumn="span 3"
                                                    />
                                                    <GridItem pb={1}>
                                                        <Text
                                                            textTransform="uppercase"
                                                            fontWeight="bold"
                                                            fontSize="xs"
                                                            color="whiteAlpha.600"
                                                        >
                                                            Scalars
                                                        </Text>
                                                    </GridItem>
                                                    <GridItem pb={1}></GridItem>
                                                    <GridItem pb={1}></GridItem>
                                                    {propertyListProps.items
                                                        .filter((item) =>
                                                            isNumericType(
                                                                item.property.type
                                                            )
                                                        )
                                                        .map((item) => (
                                                            <PropertyItem
                                                                key={item.property.key}
                                                                {...propertyListProps.getItemProps(
                                                                    item
                                                                )}
                                                            />
                                                        ))}
                                                </Grid>
                                            </VStack>
                                        </FormControl>
                                    </VStack>
                                )}
                            </VStack>
                            {layoutProps.showUpload && (
                                <VStack w="full" align="start" spacing={6}>
                                    <VStack align="start" w="full" spacing={2}>
                                        <Heading fontSize="xl" fontWeight="semibold">
                                            Upload file
                                        </Heading>
                                        <Text color="whiteAlpha.700">
                                            Et ea consequat eu mollit veniam est aliqua in
                                            magna do ea dolore laborum ut. Esse eu Lorem
                                            cupidatat aliquip ad labore aliqua velit.
                                            Labore nostrud ullamco cillum id nulla qui
                                            laborum magna laboris proident in sint Lorem
                                            mollit
                                        </Text>
                                        <Button
                                            {...viewProps.getDownloadLinkProps()}
                                            variant="link"
                                            fontWeight="medium"
                                            color="blue.400"
                                            userSelect="none"
                                            _focus={{ outline: 'none' }}
                                        >
                                            <HStack align="center">
                                                <Icon
                                                    fontSize="lg"
                                                    as={AiOutlineCloudDownload}
                                                />
                                                <Text>Download template</Text>
                                            </HStack>
                                        </Button>
                                    </VStack>
                                    <DropZone {...viewProps.getFileProps()} />
                                </VStack>
                            )}
                            {layoutProps.showPreview && (
                                <VStack w="full" align="start" spacing={6}>
                                    <VStack align="start" w="full" spacing={2}>
                                        <Heading fontSize="xl" fontWeight="semibold">
                                            Preview data
                                        </Heading>
                                        <Text color="whiteAlpha.700">
                                            Lorem culpa nulla sint voluptate eu nostrud
                                            sit anim veniam dolore. Adipisicing qui
                                            cupidatat culpa nulla ad sunt commodo amet
                                            occaecat enim eiusmod. Pariatur amet dolor
                                            consequat aliquip est
                                        </Text>
                                    </VStack>
                                    <VStack
                                        align="start"
                                        w="full"
                                        p={6}
                                        borderRadius="lg"
                                        borderWidth={2}
                                        borderColor="whiteAlpha.300"
                                        spacing={3}
                                    >
                                        <Alert status="success" w="full">
                                            <AlertIcon />
                                            <AlertTitle w="full" fontWeight="semibold">
                                                <HStack justify="start">
                                                    <Text>
                                                        {entryTableProps.collection.label}
                                                    </Text>
                                                </HStack>
                                            </AlertTitle>
                                        </Alert>
                                        <Box
                                            w="full"
                                            overflowX="auto"
                                            pt={3}
                                            pb={6}
                                            css={{
                                                '&::-webkit-scrollbar': {
                                                    '-webkit-appearance': 'none',
                                                    height: '0.5rem',
                                                },
                                                '&::-webkit-scrollbar-track': {},
                                                '&::-webkit-scrollbar-corner': {
                                                    '-webkit-appearance': 'none',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    borderRadius: '1rem',
                                                    background: scrollbarBg,
                                                },
                                                '&::-webkit-scrollbar-thumb:hover': {
                                                    background: scrollbarHoverBg,
                                                },
                                            }}
                                        >
                                            <Table>
                                                <Thead>
                                                    <Tr>
                                                        {entryTableProps
                                                            .getHeaderProps()
                                                            .map((header) => (
                                                                <Th
                                                                    {...header}
                                                                    color="whiteAlpha.600"
                                                                    whiteSpace="nowrap"
                                                                />
                                                            ))}
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {entryTableProps.items.map((item) => (
                                                        <Tr
                                                            {...entryTableProps.getTrProps(
                                                                item
                                                            )}
                                                        >
                                                            {entryTableProps
                                                                .getCellProps(item)
                                                                .map((cell, index) => (
                                                                    <Td
                                                                        color={
                                                                            index === 0
                                                                                ? undefined
                                                                                : 'whiteAlpha.800'
                                                                        }
                                                                        fontWeight="medium"
                                                                        whiteSpace="nowrap"
                                                                        {...cell}
                                                                    />
                                                                ))}
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </Box>
                                        {entryTableProps.truncate && (
                                            <HStack w="full" justify="center">
                                                <Text
                                                    color="whiteAlpha.500"
                                                    fontWeight="medium"
                                                >
                                                    {entryTableProps.truncate.label}
                                                </Text>
                                            </HStack>
                                        )}
                                    </VStack>
                                </VStack>
                            )}
                            {layoutProps.showConfirm && (
                                <VStack w="full" align="start" spacing={6}>
                                    <VStack align="start" w="full" spacing={2}>
                                        <Heading fontSize="xl" fontWeight="semibold">
                                            Confirm
                                        </Heading>
                                        <Text color="whiteAlpha.700">
                                            Sunt anim laborum anim velit duis occaecat in
                                            est excepteur
                                        </Text>
                                    </VStack>
                                    <Button
                                        {...formProps.getSubmitButtonProps()}
                                        colorScheme="green"
                                        _focus={{ outline: 'none' }}
                                    >
                                        Create Import
                                    </Button>
                                </VStack>
                            )}
                        </VStack>
                    </Content>
                </Container>
            </form>
        );
    };
}
