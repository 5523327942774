import { SettingIntegrationConfig } from '../integrationConfig';
import { SettingIntegrationListController } from './listInterface';

export function createSettingIntegrationListController(
    config: Pick<SettingIntegrationConfig, 'controller'>
): SettingIntegrationListController {
    const {
        controller: {
            integration: { list: listController },
        },
    } = config;
    return {
        useProps(context, deps, props) {
            const listProps = listController.useProps(context, deps.list, props);
            return {
                items: listProps.items,
                getItemProps(item) {
                    const itemProps = listProps.getItemProps(item);
                    return itemProps;
                    // return { ...itemProps, path: `/u/assets/${itemProps.id}/settings` };
                },
                getListProps() {
                    return listProps;
                },
            };
        },
    };
}
