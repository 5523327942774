import { runInAction, makeObservable, observable } from 'mobx';
import StoreBase from '../../../../stores/StoreBase';
import { DashboardReference, AnyFilter } from '../../../domain/dashboard';
import { AnyCondition } from '../../../domain/attributes';
import { CohortState } from '../../../domain/dashboard';
import { ControlStore } from '../../../app';
import { ControlStoreConfig } from './controlStoreConfig';

export interface ControlStateValue {
    filters?: Record<string, AnyCondition | null | undefined>;
    segments?: Record<string, AnyCondition | null | undefined>;
    cohort?: CohortState | null;
}

export class ControlStoreImpl extends StoreBase implements ControlStore {
    _state: Record<string, ControlStateValue | undefined>;
    _group: string[];
    _filterMode: 'fixed' | 'dynamic';

    constructor(public config: ControlStoreConfig) {
        super();
        this._state = config.initialValues;
        this._group = [];
        this._filterMode = 'dynamic';
        makeObservable(this, {
            _state: observable,
            _group: observable,
            _filterMode: observable,
        });
    }

    reset(dashboard: DashboardReference) {
        runInAction(() => {
            this._state[dashboard.id] = this.config.initialValues[dashboard.id] ?? null;
        });
    }

    // Filters

    setDataFilter(
        dashboard: DashboardReference,
        filter: AnyFilter,
        value: AnyCondition | null
    ) {
        runInAction(() => {
            this._state = {
                ...this._state,
                [dashboard.id]: {
                    ...this._state[dashboard.id],
                    filters: {
                        ...this._state[dashboard.id]?.filters,
                        [filter.property.key]: value,
                    },
                },
            };
        });
    }

    clearDataFilter(dashboard: DashboardReference, filter: AnyFilter) {
        runInAction(() => {
            delete this._state[dashboard.id]?.filters?.[filter.property.key];
        });
    }

    getDataFilter(dashboard: DashboardReference, filter: AnyFilter) {
        return this._state[dashboard.id]?.filters?.[filter.property.key];
    }

    // Segment

    setSegmentFilter(
        dashboard: DashboardReference,
        filter: AnyFilter,
        value: AnyCondition | null
    ): void {
        runInAction(() => {
            this._state = {
                ...this._state,
                [dashboard.id]: {
                    ...this._state[dashboard.id],
                    segments: {
                        ...this._state[dashboard.id]?.segments,
                        [filter.property.key]: value,
                    },
                },
            };
        });
    }

    removeSegmentFilter(dashboard: DashboardReference, filter: AnyFilter): void {
        runInAction(() => {
            delete this._state[dashboard.id]?.segments?.[filter.property.key];
        });
    }

    getSegmentFilter(
        dashboard: DashboardReference,
        filter: AnyFilter
    ): AnyCondition | null | undefined {
        return this._state[dashboard.id]?.segments?.[filter.property.key];
    }

    // Group

    setGroups(value: string[]): void {
        runInAction(() => {
            this._group = value;
        });
    }

    removeGroups(): void {
        runInAction(() => {
            this._group = [];
        });
    }

    getGroups(): string[] {
        return this._group;
    }

    // Filter mode

    setFilterMode(value: 'dynamic' | 'fixed'): void {
        runInAction(() => {
            this._filterMode = value;
        });
    }

    getFilterMode(): 'dynamic' | 'fixed' {
        return this._filterMode;
    }

    // Cohort

    setCohort(dashboard: DashboardReference, state: CohortState) {
        runInAction(() => {
            this._state = {
                ...this._state,
                [dashboard.id]: {
                    ...this._state[dashboard.id],
                    cohort: state,
                },
            };
        });
    }

    clearCohort(dashboard: DashboardReference) {
        runInAction(() => {
            delete this._state[dashboard.id]?.cohort;
        });
    }

    getCohort(dashboard: DashboardReference) {
        return this._state[dashboard.id]?.cohort ?? null;
    }
}
