import { useMutation } from '@tanstack/react-query';
import { AccountInvitationRepository } from '../../../app/accounts';
import { InvitationAcceptPayload } from '../../../domain/accounts';
import { useStore } from '../../../../stores/setupContext';

export function createInvitationService(repository: AccountInvitationRepository) {
    return {
        useAccept() {
            const { auth: store } = useStore();
            const acceptCp = repository.useAccept();
            return useMutation<void, Error, InvitationAcceptPayload>({
                async mutationFn(payload: InvitationAcceptPayload) {
                    const resp = await acceptCp.mutateAsync(payload);
                    
                    await store.handloginResponse(
                        payload.email,
                        resp,
                    )
                },
            })
        }
    }
}