import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    BreadcrumbItem,
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    GridProps,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    Radio,
    Select,
    StackDivider,
    Switch,
    Tab,
    Table,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
    FormErrorMessage,
    Alert,
    AlertTitle,
    AlertIcon,
    Textarea,
    Stack,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { ImMagicWand } from 'react-icons/im';
import { MdOutlineManageSearch } from 'react-icons/md';
import { RiUserSearchLine } from 'react-icons/ri';
import { Icons } from '../../../../../config';
import { AccountInviteFormViewProps } from '../../../../view';
import { FormFieldItemViewProps } from '../../../../view/forms';
import { createFieldItemView, OptionItem, OptionList } from '../../../common';
import { StudiesSubmissionNewRouteConfig } from './studiesSubmissionNewConfig';
import { StudiesSubmissionViewProps } from './studiesSubmissionNewProps';

export function createStudiesSubmissionNewView(
    config: Pick<StudiesSubmissionNewRouteConfig, 'Layout' | 'UI'>
): React.FC<StudiesSubmissionViewProps> {
    const {
        Layout: {
            Domain: { Shell: Layout },
        },
        UI: {
            Application: { Link: ApplicationLink },
            Input: InputUI,
        },
    } = config;

    const FieldItem = createFieldItemView(InputUI);

    // const AutomaticItem: React.FC<AccountInviteFormViewProps> = (props) => {
    //     const {
    //         fields: { text: description },
    //     } = props;
    //     return (
    //         <FormControl isInvalid={!!description.error}>
    //             <VStack w="full" align="start">
    //                 <Textarea
    //                     value={description.input.value ?? undefined}
    //                     onChange={(event) =>
    //                         description.input.onChange(event.target.value)
    //                     }
    //                     w="full"
    //                     placeholder="Describe your peer group"
    //                     _hover={{ borderColor: 'whiteAlpha.400' }}
    //                     _focus={{ borderColor: 'whiteAlpha.500' }}
    //                 />
    //                 {description.error?.message && (
    //                     <FormErrorMessage>{description.error.message}</FormErrorMessage>
    //                 )}
    //                 <Text fontStyle="italic" fontSize="sm" color="whiteAlpha.500">
    //                     eg. Sit ullamco id consequat voluptate irure est nisi cillum eu
    //                     mollit deserunt exercitation ad aliquip magna amet eiusmod magna
    //                 </Text>
    //             </VStack>
    //         </FormControl>
    //     );
    // };

    return (props) => {
        return (
            <form {...props.form.getFormElementProps()} style={{ width: '100%' }}>
                <Grid
                    w="full"
                    templateAreas={`
                        "header header"
                        "content content"
                    `}
                    columnGap={24}
                    rowGap={6}
                >
                    <GridItem gridArea="header">
                        <VStack
                            w="full"
                            align="start"
                            spacing={6}
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.300"
                                />
                            }
                        >
                            <HStack
                                w="full"
                                justify="space-between"
                                spacing={12}
                                align="end"
                                pb={4}
                                borderBottom={2}
                                borderColor="whiteAlpha.300"
                                borderStyle="solid"
                            >
                                <VStack w="full" align="start" spacing={4}>
                                    <VStack w="full" align="start" spacing={1}>
                                        <HStack
                                            fontWeight="semibold"
                                            spacing={2}
                                            divider={
                                                <Icon
                                                    boxSize="1.20rem"
                                                    color="whiteAlpha.500"
                                                    as={ChevronRightIcon}
                                                    border="none"
                                                />
                                            }
                                        >
                                            <ApplicationLink to="../..">
                                                <Text
                                                    color="blue.300"
                                                    _hover={{ color: 'blue.400' }}
                                                >
                                                    Reports
                                                </Text>
                                            </ApplicationLink>
                                            <ApplicationLink to="..">
                                                <Text
                                                    color="blue.300"
                                                    _hover={{ color: 'blue.400' }}
                                                >
                                                    Surveys
                                                </Text>
                                            </ApplicationLink>
                                        </HStack>
                                        <Heading fontSize="2xl">New submission</Heading>
                                        <HStack w="full">
                                            <Text
                                                fontWeight="medium"
                                                color="whiteAlpha.700"
                                            >
                                                For survey
                                            </Text>
                                            <ApplicationLink {...props.links.report}>
                                                <HStack
                                                    spacing={2}
                                                    cursor="pointer"
                                                    _hover={{
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    <Icon as={Icons.Studies.Study} />
                                                    <Text
                                                        as="span"
                                                        color="whiteAlpha.800"
                                                        fontWeight="semibold"
                                                    >
                                                        {
                                                            props.page.getBreadcrumbProps()
                                                                .items[0].label
                                                        }
                                                    </Text>
                                                </HStack>
                                            </ApplicationLink>
                                        </HStack>
                                    </VStack>
                                </VStack>
                                <ButtonGroup>
                                    <ApplicationLink {...props.links.cancel}>
                                        <Button
                                            size="sm"
                                            bg="whiteAlpha.300"
                                            _focus={{ outline: 'none' }}
                                        >
                                            Cancel
                                        </Button>
                                    </ApplicationLink>
                                </ButtonGroup>
                            </HStack>
                        </VStack>
                    </GridItem>
                    <GridItem gridArea="content">
                        <VStack
                            w="full"
                            align="start"
                            fontWeight="medium"
                            spacing={6}
                            pb="12rem"
                            divider={
                                <StackDivider
                                    borderWidth={2}
                                    borderColor="whiteAlpha.200"
                                />
                            }
                        >
                            {/* <VStack align="start" w="full" maxW="42rem" spacing={6}>
                                    <VStack
                                        align="start"
                                        w="full"
                                        spacing={1}
                                        maxW="32rem"
                                    >
                                        <Heading fontSize="lg">
                                            Choose a study type
                                        </Heading>
                                        <Text color="whiteAlpha.700" maxW="24rem">
                                            The study type determines how companies will
                                            be included in your peer group and study
                                            results
                                        </Text>
                                    </VStack>
                                    <OptionList
                                        {...props.input.type}
                                        as={
                                            <Stack w="full" spacing={3} direction="row" />
                                        }
                                    />
                                </VStack>
                                <VStack w="full" align="start" spacing={6} maxW="42rem">
                                    <VStack
                                        align="start"
                                        w="full"
                                        spacing={1}
                                        // maxW="32rem"
                                    >
                                        <HStack w="full" justify="space-between">
                                            <HStack w="full">
                                                <Heading w="full" fontSize="lg">
                                                    Choose a study group
                                                </Heading>
                                            </HStack>
                                            <Text
                                                whiteSpace="nowrap"
                                                color="blue.300"
                                                cursor="pointer"
                                                _hover={{ textDecoration: 'underline' }}
                                            >
                                                Learn more about groups
                                            </Text>
                                        </HStack>
                                        <Text color="whiteAlpha.700" maxW="24rem">
                                            Either create a new study group or select an
                                            existing one
                                        </Text>
                                    </VStack>
                                    <Tabs variant="unstyled" isFitted={true} w="full">
                                        <TabList>
                                            <Tab
                                                id="new"
                                                fontWeight="semibold"
                                                borderStyle="solid"
                                                color="whiteAlpha.800"
                                                borderColor="transparent"
                                                borderBottomColor="whiteAlpha.300"
                                                borderTopRadius="md"
                                                borderWidth={2}
                                                _focus={{ outline: 'none' }}
                                                _selected={{
                                                    color: 'blue.300',
                                                    borderColor: 'whiteAlpha.300',
                                                    borderBottomColor: 'transparent',
                                                }}
                                            >
                                                <HStack>
                                                    <Icon as={AiOutlinePlus} />
                                                    <Text>New group</Text>
                                                </HStack>
                                            </Tab>
                                            <Tab
                                                id="saved"
                                                fontWeight="semibold"
                                                borderStyle="solid"
                                                color="whiteAlpha.800"
                                                borderColor="transparent"
                                                borderBottomColor="whiteAlpha.300"
                                                borderTopRadius="md"
                                                borderWidth={2}
                                                _focus={{ outline: 'none' }}
                                                _selected={{
                                                    color: 'blue.300',
                                                    borderColor: 'whiteAlpha.300',
                                                    borderBottomColor: 'transparent',
                                                }}
                                            >
                                                Saved groups
                                            </Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel id="new" px={0} pb={6}>
                                                <VStack
                                                    align="start"
                                                    w="full"
                                                    spacing={4}
                                                >
                                                    <VStack
                                                        align="start"
                                                        w="full"
                                                        spacing={0}
                                                    >
                                                        <Text
                                                            fontWeight="medium"
                                                            color="whiteAlpha.800"
                                                        >
                                                            Invite companies to your group
                                                            manually or automatically
                                                            using AI
                                                        </Text>
                                                    </VStack>
                                                    <OptionList
                                                        as={
                                                            <Stack
                                                                w="full"
                                                                spacing={3}
                                                                direction="column"
                                                            />
                                                        }
                                                        {...props.input.group}
                                                    />
                                                    <AutomaticItem
                                                        {...props.inviteProps}
                                                    />
                                                </VStack>
                                            </TabPanel>
                                            <TabPanel id="saved" px={0} pb={6}>
                                                <VStack
                                                    align="start"
                                                    w="full"
                                                    spacing={2}
                                                    p={6}
                                                    borderRadius="md"
                                                    borderColor="whiteAlpha.300"
                                                    borderStyle="solid"
                                                    borderWidth={2}
                                                >
                                                    <HStack
                                                        w="full"
                                                        justify="space-between"
                                                    >
                                                        <VStack align="start" w="full">
                                                            <Text fontWeight="semibold">
                                                                Select group
                                                            </Text>
                                                        </VStack>
                                                        <Select placeholder="Select group"></Select>
                                                    </HStack>
                                                </VStack>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </VStack> */}
                            <VStack w="full" align="start" spacing={6} maxW="42rem">
                                {/* <Text fontSize="lg" color="whiteAlpha.700">
                                    {props.study.description}
                                </Text> */}
                                <VStack align="start" w="full" spacing={1}>
                                    <Heading fontSize="lg">Questions</Heading>
                                    <Text color="whiteAlpha.700" maxW="24rem">
                                        Answer the following questions to get answer to
                                        the responses from study participants
                                    </Text>
                                </VStack>
                                <VStack
                                    borderStyle="solid"
                                    borderWidth={2}
                                    borderColor="whiteAlpha.300"
                                    borderRadius="md"
                                    p={6}
                                    spacing={6}
                                    w="full"
                                    align="start"
                                    divider={
                                        <StackDivider
                                            borderWidth={1}
                                            borderColor="whiteAlpha.200"
                                        />
                                    }
                                >
                                    {props.field.items.map((item) => {
                                        const itemProps = props.field.getItemProps(item);
                                        return (
                                            <FieldItem
                                                key={itemProps.id}
                                                icon={
                                                    <Icon
                                                        position="relative"
                                                        top={1.5}
                                                        as={Icons.Studies.Answer}
                                                    />
                                                }
                                                {...itemProps}
                                            />
                                        );
                                    })}
                                </VStack>
                            </VStack>
                            <VStack align="start" w="full" spacing={3}>
                                {props.form.error && (
                                    <Alert status={props.form.error.status} w="full">
                                        <AlertIcon />
                                        <AlertTitle>{props.form.error.label}</AlertTitle>
                                    </Alert>
                                )}
                                <ButtonGroup spacing={3}>
                                    <Button
                                        colorScheme="green"
                                        {...props.form.getSubmitButtonProps()}
                                    >
                                        Submit study
                                    </Button>
                                </ButtonGroup>
                            </VStack>
                        </VStack>
                    </GridItem>
                </Grid>
            </form>
        );
    };
}
