import { chain } from 'lodash';
import {
    isReportIntegrationDependency,
    isReportStudyDependency,
} from '../../../../../app';
import { ReportItemAggregate } from '../../item';
import { StudyDataSourceItemProps } from '../item';
import { StudyDataSourceListProps } from './studyDataSourceListProps';

export function buildDataSourceListProps(
    item: ReportItemAggregate
): StudyDataSourceListProps {
    const applied: Set<StudyDataSourceItemProps['kind']> = new Set();

    if (item.report.dependencies.some(isReportStudyDependency)) {
        applied.add('survey');
    }

    if (item.report.dependencies.some(isReportIntegrationDependency)) {
        applied.add('integration');
    }

    const dependencies: StudyDataSourceItemProps[] = [
        {
            kind: 'integration',
            label: 'Integration',
            description: `Report is based on data pulled from active integrations`,
            isEnabled: applied.has('integration'),
        },
        {
            kind: 'survey',
            label: 'Survey',
            description: `Report is based on data from surveys submitted by other users`,
            isEnabled: applied.has('survey'),
        },
    ];

    return {
        items: chain(dependencies)
            .orderBy((item) => (item.isEnabled ? 1 : 0), 'desc')
            .value(),
    };
}
