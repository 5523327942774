import { Box, BoxProps, HStack, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { calculateBarWidths, calculateRelativeValues } from './chakraBarChartHelper';
import { BarChartViewProps } from './chakraBarChartProps';

export function createBarChartV2View(): React.FC<BarChartViewProps> {
    const OptionItem: React.FC<{
        label: string;
        value: string;
        width: BoxProps['width'];
        layout: BarChartViewProps['layout']['item'];
    }> = (props) => {
        return (
            <Tr>
                <Td py={1} px={3} border="none" w="30%" textAlign="right">
                    <Text fontWeight="medium" color={props.layout.color}>
                        {props.label}
                    </Text>
                </Td>
                <Td py={1} px={0} border="none" w="70%">
                    <HStack w="full" spacing={3}>
                        <Box
                            width={props.width}
                            minW="fit-content"
                            h={props.layout.h ?? 8}
                            p={1}
                            bg={props.layout.borderColor ?? 'whiteAlpha.300'}
                            borderRadius={3}
                        >
                            <Box
                                h="full"
                                w="full"
                                bg={props.layout.bg}
                                borderWidth={2}
                                borderColor="whiteAlpha.100"
                                borderStyle="solid"
                                borderRadius={2}
                            />
                        </Box>
                        <HStack minW="10%">
                            <Text fontWeight="bold" color={props.layout.color}>
                                {props.value}
                            </Text>
                        </HStack>
                    </HStack>
                </Td>
            </Tr>
        );
    };

    return (props) => {
        const {
            scale: { startAtZero = false, mode = 'absolute' },
        } = props.layout;
        const widths = useMemo(
            () => calculateBarWidths(props.items, 100, startAtZero),
            [props.items, startAtZero]
        );
        const relativeLabels = useMemo(
            () => calculateRelativeValues(props.items),
            [props.items]
        );
        return (
            <Table w="full">
                <Tbody>
                    {props.items.map((item, index) => {
                        const itemProps = props.getItemProps(item);
                        const width = `${widths[index]}%`;
                        const relative = `${relativeLabels[index]}%`;
                        const value = mode === 'relative' ? relative : itemProps.value;
                        return (
                            <OptionItem
                                {...itemProps}
                                value={value}
                                key={item.label}
                                width={width}
                                layout={props.layout.item}
                            />
                        );
                    })}
                </Tbody>
            </Table>
        );
    };
}
