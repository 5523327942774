import React from 'react';
import { PeerGroupCreateViewProps } from './peerGroupCreateProps';
import { PeerViewConfig } from '../../base';
import { PeerCompanyEditViewProps } from '../../company';

export function createPeerGroupCreateView(
    config: PeerViewConfig & { company: React.FC<PeerCompanyEditViewProps> }
): React.FC<PeerGroupCreateViewProps> {
    const { company: CompanyView } = config;
    return (props) => {
        return <CompanyView {...props.company} />;
    };
}
