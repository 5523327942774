import { PeerControllerConfig } from '../../base';
import { PeerCompanyEditController } from '../../company';
import { PeerMemberEditController } from './peerMemberEditInterface';
import { PeerMemberEditViewProps } from './peerMemberEditProps';

export function createPeerMemberEditController(
    config: PeerControllerConfig & {
        company: PeerCompanyEditController;
    }
): PeerMemberEditController {
    const {
        company: { useProps: useCompanyProps },
    } = config;

    return {
        useProps(context, item, props): PeerMemberEditViewProps {
            const company = useCompanyProps(context, item.company, props.company);
            return {
                company,
            };
        },
    };
}
