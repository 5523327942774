import { OrganizationAdapterV2 } from '../../../app/platform';
import { OrganizationImplConfig } from './organizationConfig';

export function createOrganizationImpl(
    config: OrganizationImplConfig
): OrganizationAdapterV2 {
    const { api } = config;
    return {
        async lookup(context, query) {
            const response = await api.platform.organizations.get(context, query.id);
            return {
                id: response.id,
                name: response.name,
                defaultAsset: response.default_asset
                    ? { id: response.default_asset }
                    : null,
                createdAt: response.created_at,
            };
        },
    };
}
