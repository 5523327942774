import { z } from 'zod';
import { CursorPaginationSchema } from '../../common';

export const GroupPrivacyKind = z.union([z.literal('aggregate'), z.literal('anonymize')]);

export const GroupMembershipSchema = z.discriminatedUnion('kind', [
    z.object({
        kind: z.literal('internal'),
        organization_id: z.optional(z.number()),
    }),
    z.object({
        kind: z.literal('opt_in'),
    }),
    z.object({
        kind: z.literal('automatic'),
    }),
]);

export const GroupSchema = z.object({
    id: z.string(),
    name: z.string(),
    dataset_ids: z.nullable(z.array(z.string())),
    privacy_restrictions: z.array(GroupPrivacyKind),
    membership: GroupMembershipSchema,
    created_by: z.nullable(z.number()),
    created_at: z.nullable(z.date({ coerce: true })),
});

export const GroupCreatePropsSchema = z.object({
    name: z.string(),
    membership: GroupMembershipSchema,
    dataset_ids: z.number().optional(),
    tags: z.array(z.string()).optional(),
    manager_asset_id: z.number().optional(),
});

export const GroupListResponseSchema = z.object({
    data: z.array(GroupSchema),
    pagination: CursorPaginationSchema,
});
