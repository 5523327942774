import React from 'react';
import { SettingAccountItemProvider } from '../../../../route/settings/account';
import { SettingIntegrationConfig } from './integrationConfig';
import { createSettingIntegrationItemContainer } from './item';
import {
    createSettingIntegrationListLoader,
    createSettingAccountIntegrationListContainer,
    createSettingIntegrationListController,
    createSettingIntegrationListView,
} from './list';

export function createSettingIntegrationStrategy(
    init: SettingIntegrationConfig
): SettingAccountItemProvider {
    return {
        create(config) {
            const List = {
                Container: createSettingAccountIntegrationListContainer(
                    init,
                    config,
                    createSettingIntegrationListLoader(init),
                    createSettingIntegrationListController(init)
                ),
                View: createSettingIntegrationListView(
                    config,
                    createSettingIntegrationItemContainer(init, config)
                ),
            };

            return [
                {
                    scope: 'organization',
                    path: 'integrations',
                    key: 'integrations',
                    title: 'Integrations',
                    Component() {
                        return <List.Container as={List.View} />;
                    },
                },
            ];
        },
    };
}
