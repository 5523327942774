import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSessionStorage } from 'react-use';
import { useAuthenticatedContext } from '../../../../container';
import { useStore } from '../../../../stores/setupContext';
import { useOnboarding } from '../../../../hooks';
import { useIntegrationDefinitions } from '../../../../views';
import { Asset, AssetCreateProps } from '../../../domain/assets';
import { AnyOnboardingStep } from '../../../domain/onboarding';
import { isLoadedLookup } from '../../../base';
import { OnboardingIntegrationConfig } from './onboardingIntegrationConfig';
import { OnboardingIntegrationController } from './onboardingIntegrationInterface';
import { OnboardingIntegrationViewProps } from './onboardingIntegrationProps';
import { useSearchParams } from 'react-router-dom';

export function createOnboardingIntegrationController(
    config: OnboardingIntegrationConfig
): OnboardingIntegrationController {
    const {
        service,
        loader,
        router,
        hooks: { useLocalStorage },
    } = config;
    return {
        useProps(context, props): OnboardingIntegrationViewProps {
            const rootStore = useStore();
            const {
                integrations: { isLoadingId, isLoadingIntegrateLink },
            } = rootStore;

            const [, setOnboardingState] = useLocalStorage(
                context.organization.id.toString()
            );

            useEffect(() => {
                setOnboardingState({
                    step: 'integration',
                    organization: context.organization.id,
                    asset: null,
                });
            }, []);

            const authedcontext = useAuthenticatedContext();

            const definitions = useIntegrationDefinitions(authedcontext, {
                suspense: true,
            });

            const onboarding = useOnboarding({
                definitions: definitions.data ?? [],
            });

            const [, setOnboardingRedirect] = useSessionStorage<
                AnyOnboardingStep['id'] | null
            >('vs_onboarding_step_redirect', null);

            useEffect(() => {
                setOnboardingRedirect('integration');
            }, []);

            const assets = service.asset.useFind(context, {});

            const canContinue = useMemo(
                () =>
                    definitions.data?.some((item) =>
                        item.integrations.some((integ) => integ.is_connected)
                    ),
                [definitions.data]
            );

            // const mutations = {
            //     asset: {
            //         create: service.asset.useCreate(context),
            //     },
            // };

            const data = loader.profile.useData(context, {});

            const navigate = useNavigate();
            const [searchParams] = useSearchParams();

            const handleSubmitClick = async () => {
                if (!data.profile.lookup.data) {
                    console.warn('data not loaded yet');
                    return;
                }
                const [defaultAsset = null] = assets.data ?? [];
                let asset: Asset | null = defaultAsset;

                // HACK
                const successPath = searchParams.get('success_path');
                if (successPath) {
                    return navigate(successPath);
                }

                const path = router.getSuccessPath(
                    data.profile.lookup.status === 'success'
                        ? data.profile.lookup.data
                        : null,
                    asset
                );
                console.info(`redirecting to integration onboarding -> ${path}`);
                // setOnboardingStatus(null);
                navigate(path);
            };

            return {
                getFormProps() {
                    return {
                        onSubmit: handleSubmitClick,
                        isSubmittable: canContinue ?? false,
                    };
                },
                getViewListProps() {
                    return {
                        definitions: definitions.data ?? [],
                    };
                },
                getViewItemProps(item) {
                    return {
                        definition: item,
                        onConnect: onboarding.onConnect,
                        onReconnect: onboarding.onReconnect,
                        isLoadingId: isLoadingId,
                        isLoadingConnection: isLoadingIntegrateLink,
                    };
                },
            };
        },
    };
}
