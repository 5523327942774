import thumbnail from '../../../../images/report_thumbnail_marketing_comp.png';
import screenshot_paid_media_manager from '../../../../images/report_detail_marketing_compensation_paid_media_manager.png';
import screenshot_seo from '../../../../images/report_detail_marketing_compensation_seo.png';
import { ReportV2Dto } from '../../../api';
import { DatasetSlugs } from '../../dataset';

const dimensionsWithDescriptions = [
    {
        key: 'role',
        title: 'Role in the marketing team',
        description: null,
    },
    {
        key: 'base_compensation',
        title: 'Base compensation',
        description: null,
    },
    {
        key: 'total_variable_compensation',
        title: 'Total variable compensation',
        description: null,
    },
    {
        key: 'compensation_increase',
        title: 'Compensation increase vs. previous compensation cycle',
        description: null,
    },
];

export const MarketingTeamCompensationReport: ReportV2Dto = {
    id: 'marketing_team_compensation',
    name: 'Compensation',
    tagline: null,
    description:
        'Salary benchmarks for different marketing roles',
    category: 'marketing',
    dataset: DatasetSlugs.MARKETING_COMP,
    traits: [],
    tags: ['compensation'],
    dependencies: [
        {
            kind: 'survey',
            dataset: DatasetSlugs.MARKETING_COMP,
        },
    ],
    thumbnail: {
        description: null,
        url: thumbnail,
    },
    images: [
        {
            description: null,
            url: screenshot_paid_media_manager,
        },
        {
            description: null,
            url: screenshot_seo,
        },
    ],
    visualizations: dimensionsWithDescriptions.map((dimension) => ({
        key: dimension.key,
        kind: 'bar',
        name: dimension.title,
        description: dimension.description,
        aggregation: 'count',
        breakdown: {
            keys: [dimension.key],
        },
    })),
};
