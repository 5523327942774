import React, { useMemo } from 'react';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import {
    SettingIntegrationListController,
    SettingIntegrationListDeps,
    SettingIntegrationListLoader,
} from './listInterface';
import { SettingIntegrationListContainerProps } from './listProps';
import { SettingIntegrationConfig } from '../integrationConfig';
import { useSearchParams } from 'react-router-dom';
import { SettingAccountIntegrationListRouteParams } from './listSchema';

export function createSettingAccountIntegrationListContainer(
    init: SettingIntegrationConfig,
    config: Pick<SettingAccountItemProviderConfig, 'api'>,
    loader: SettingIntegrationListLoader,
    controller: SettingIntegrationListController
): React.FC<SettingIntegrationListContainerProps> {
    const {
        state: {
            integration: { list: state },
        },
    } = init;
    const {
        api: { useContext },
    } = config;
    return ({ as: Component, children, ...props }) => {
        const [searchParams] = useSearchParams();
        const options = useMemo(() => {
            const parsed = SettingAccountIntegrationListRouteParams.safeParse(
                Object.fromEntries(searchParams.entries())
            );
            return parsed.success ? parsed.data : null;
        }, [searchParams]);

        const viewContext = useContext();
        const viewState = state.useState(viewContext, { search: options?.name ?? '' });
        const viewData = loader.useLoad(viewContext);
        const viewDeps: SettingIntegrationListDeps = {
            list: {
                state: viewState,
            },
        };

        console.log('options', options);
        console.log('viewState', viewState);

        const viewProps = controller.useProps(viewContext, viewDeps, { items: viewData });
        return <Component {...viewProps}>{children}</Component>;
    };
}
