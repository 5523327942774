import { chain } from 'lodash';
import { useMemo, useState } from 'react';
import { assert } from '../../../../util/assert';
import { buildSelectItemProps, SelectItemViewProps } from '../item';
import { SelectControllerConfig } from '../selectConfig';
import { SelectListController } from './selectListInterface';

export function createSelectController<TItem>(
    config: SelectControllerConfig<TItem>
): SelectListController<TItem> {
    const { getSearchTokens: getSearchProps, getItemProps } = config;

    return {
        useProps(deps, props) {
            const [searchTerm, setSearchTerm] = useState('');

            const itemPropsByValue = useMemo(() => {
                return props.items.reduce(
                    (acc, item) => {
                        const itemProps = buildSelectItemProps<TItem>(
                            config,
                            deps.disclosure,
                            props,
                            item
                        );
                        return {
                            ...acc,
                            [itemProps.value]: itemProps,
                        };
                    },
                    {} as Record<string, SelectItemViewProps | undefined>
                );
            }, [deps.disclosure, props, props.items]);

            const searchTokensByIndex = useMemo(() => {
                return props.items.reduce(
                    (acc, item, index) => {
                        const baseProps = getItemProps(item);
                        const itemProps = itemPropsByValue[baseProps.value];
                        if (!itemProps) {
                            return acc;
                        }
                        const tokens = getSearchProps?.(item) ?? [itemProps.label];
                        const term = tokens.map((token) => token.toLowerCase()).join(' ');
                        return {
                            ...acc,
                            [index]: term,
                        };
                    },
                    {} as Record<string, string | undefined>
                );
            }, [props.items, itemPropsByValue]);

            const current = props.value ? itemPropsByValue[props.value] ?? null : null;

            const items = useMemo(
                () =>
                    chain(props.items)
                        .filter((item, index) => {
                            const itemTerm = searchTokensByIndex[index];
                            return itemTerm?.includes(searchTerm) ?? false;
                        })
                        .value(),
                [props.items, searchTerm, searchTokensByIndex]
            );

            return {
                items,
                current,
                getItemProps(item) {
                    const baseProps = getItemProps(item);
                    const itemProps = itemPropsByValue[baseProps.value];
                    assert(itemProps, 'no items props');
                    return itemProps;
                },
                getPopoverProps() {
                    assert(deps.disclosure, `disclosure not passed to select controller`);
                    return {
                        isOpen: deps.disclosure.isOpen,
                        onClose(...args) {
                            deps.disclosure?.onClose(...args);
                        },
                        onOpen: deps.disclosure.onOpen,
                    };
                },
                getSearchInputProps() {
                    return {
                        value: searchTerm,
                        onChange(event) {
                            setSearchTerm(event.target.value.toLowerCase());
                        },
                    };
                },
                getClearButtonProps() {
                    if (props.value === null) {
                        return null;
                    }
                    return {
                        onClick(event) {
                            event.preventDefault();
                            event.stopPropagation();
                            props.onChange(null);
                            deps.disclosure?.onClose();
                        },
                    };
                },
            };
        },
    };
}
