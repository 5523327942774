import React from 'react';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { SettingIntegrationConfig } from '../integrationConfig';
import { SettingIntegrationItemContainerProps } from './itemProps';

export function createSettingIntegrationItemContainer(
    init: Pick<SettingIntegrationConfig, 'state' | 'controller'>,
    config: Pick<SettingAccountItemProviderConfig, 'api'>
): React.FC<SettingIntegrationItemContainerProps> {
    const {
        controller: {
            integration: { item: itemController },
        },
    } = init;
    const {
        api: { useContext },
    } = config;
    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewProps = itemController.useProps(viewContext, props);
        return <Component {...viewProps}>{children}</Component>;
    };
}
