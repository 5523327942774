import React from 'react';
import { ErrorBoundaryProps } from '@sentry/react';
import { ErrorBoundary } from '../../../../infra';
import { InvitationRouteConfig } from './accetRouteConfig';
import { InvitationAcceptRouteSchema } from './invitationSchema';
import {
    Alert,
    AlertIcon,
    AlertDescription,
} from '@chakra-ui/react';
import { Navigate } from 'react-router';

export interface InvitationAcceptRouteProps {   }


const renderError: ErrorBoundaryProps['fallback'] = ({ error, ...rest }) => {
    return <Alert status="error">
            <AlertIcon />
            <AlertDescription>{error.message}</AlertDescription>
    </Alert>
};

const LoginRedirect: React.FC = () => {
    const [shouldRedirect, setRedirect] = React.useState<boolean>(false);
    React.useEffect(() => {
        const handle = setTimeout(() => {
            setRedirect(true);
        }, 2000)
        return () => {
            clearTimeout(handle);
        }
    }, [])
    if(shouldRedirect) {
        return <Navigate to='/login' />
    }
    return <Alert status="success">
            <AlertIcon />
            <AlertDescription>Invitation Already Accepted, Redirecting to login...</AlertDescription>
    </Alert>
}

export function createInvitationAcceptRoute(config: InvitationRouteConfig): React.FC {
    const {
        // components: { layout: Layout },
        hook: {
            useQueryParams
        },
        View,
        controller,
        loader
    } = config;
    
    const Component: React.FC = (props) => {
        const params = useQueryParams(InvitationAcceptRouteSchema);
        const context = React.useMemo(() => ({
            invitationId: params.invitation,
            token: params.token
        }), [params.invitation, params.token])
        const loaded = loader.useVerify(context);
        const controllerProps = controller.useProps(context, { data: loaded });
        if((loaded.invitation.data?.kind === 'external' || loaded.invitation.data?.kind === 'internal') && loaded.invitation.data?.inviteeUserId) {
           // the user already registered. redirect to login
           return <LoginRedirect />
        }
        return (
            <View {...controllerProps}>
                {props.children}
            </View>
            // <Layout.Wrapper style={config.style}>
            //     <Layout.Main>
            //     </Layout.Main>
            // </Layout.Wrapper>
        );
    };
    return (props) => (
        <ErrorBoundary fallback={renderError} >
            <Component {...props}/>
        </ErrorBoundary>
    )
}
