import thumbnail from '../../../../images/report_thumbnail_agency.png';
import screenshot from '../../../../images/report_detail_agency_compensation_model.png';
import screenshot_spend_over_revenue from '../../../../images/report_detail_agency_compensation_spend_over_revenue.png';
import screenshot_spend_over_budget from '../../../../images/report_detail_agency_compensation_spend_over_budget.png';
import screenshot_compensation_model from '../../../../images/report_detail_agency_compensation_model.png';
import { ReportV2Dto } from '../../../api';
import { DatasetSlugs } from '../../dataset';

const dimensionsWithDescriptions = [
    {
        key: 'number_of_agencies',
        title: 'Number of marketing agencies your company currently works with',
        description: null,
    },
    {
        key: 'agency_compensation',
        title: 'Total annual compensation for marketing agencies',
        description: null,
    },
    {
        key: 'compensation_structure',
        title: 'Structure of your agency compensation',
        description: null,
    },
    {
        key: 'satisfaction_rating',
        title: 'Satisfaction with your marketing agencies',
        description: null,
    },
];

export const AgencyCompensationReport: ReportV2Dto = {
    id: 'agency_compensation',
    name: 'Agency Compensation',
    tagline: null,
    description: `Benchmark agency compensation relative to company stage (revenue, ad spend, etc.)`,
    category: 'marketing',
    dataset: DatasetSlugs.AGENCY_COMP,
    traits: [],
    tags: ['compensation'],
    dependencies: [
        {
            kind: 'survey',
            dataset: DatasetSlugs.AGENCY_COMP,
        },
    ],
    thumbnail: {
        description: null,
        url: thumbnail,
    },
    images: [
        {
            description: null,
            url: screenshot_spend_over_revenue,
        },
        {
            description: null,
            url: screenshot_spend_over_budget,
        },
        {
            description: null,
            url: screenshot_compensation_model,
        }
    ],
    visualizations: dimensionsWithDescriptions.map((dimension) => ({
        key: dimension.key,
        kind: 'bar',
        name: dimension.title,
        description: dimension.description,
        aggregation: 'count',
        breakdown: {
            keys: [dimension.key],
        },
    })),
};
