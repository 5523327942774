import { useMemo } from 'react';
import { keyBySafe } from '../../../../util';
import { assert } from '../../../../util/assert';
import { ReportItemAggregate } from '../../../../view/studies';
import { ImageDefinition } from '../../../../view/common';
import { StudiesDetailRouteConfig } from './studiesReportDetailConfig';
import { StudiesDetailLoader } from './studiesReportDetailInterface';
import { StudiesDetailAggregate } from './studiesReportDetailModel';

export function createStudiesDetailLoader(
    config: Pick<StudiesDetailRouteConfig, 'repository'>
): StudiesDetailLoader {
    const {
        repository: {
            dataset: datasetRepository,
            count: countRepository,
            submission: submissionRepository,
            view: viewRepository,
            report: reportRepository,
        },
    } = config;

    return {
        useLoad(context, props): StudiesDetailAggregate {
            const reportQuery = reportRepository.useLookup(context, props.report, {
                suspense: true,
            });
            assert(reportQuery.status === 'success', 'expected suspese');

            const datasetsQuery = datasetRepository.useLookup(
                context,
                reportQuery.data.dataset,
                {
                    suspense: true,
                    retry: false,
                }
            );
            const viewQuery = viewRepository.useFind(context, {}, { suspense: true });
            const submissionQuery = submissionRepository.useFind(
                context,
                {
                    asset: {
                        id: context.workspace.id as number,
                    },
                },
                { suspense: true }
            );

            assert(viewQuery.status === 'success', 'expected suspese');
            assert(datasetsQuery.status === 'success', 'expected suspese');
            assert(submissionQuery.status === 'success', 'expected suspese');

            const countQuery = countRepository.useLookup(
                context,
                [
                    {
                        kind: 'data_ingestion.dataset_member',
                        object: datasetsQuery.data?.id,
                    },
                ],
                { suspense: true }
            );
            assert(countQuery.status === 'success', 'expected suspese');

            const viewsById = useMemo(
                () => keyBySafe(viewQuery.data, (item) => item.id),
                [viewQuery.data]
            );

            const countsByDataset = useMemo(
                () => keyBySafe(countQuery.data, (item) => item.object),
                [countQuery.data]
            );

            const submissionsByDataset = useMemo(
                () => keyBySafe(submissionQuery.data, (item) => item.dataset.id),
                [submissionQuery.data]
            );

            const aggregate = useMemo((): ReportItemAggregate => {
                const submission = submissionsByDataset[datasetsQuery.data.id] ?? null;
                return {
                    asset: {
                        id: context.workspace.id as number,
                    },
                    dataset: datasetsQuery.data,
                    count: {
                        respondents: countsByDataset[datasetsQuery.data.id] ?? null,
                    },
                    submission,
                    report: reportQuery.data,
                    // HACK right now we assume the view is the same as the
                    // dataset
                    view: viewsById[datasetsQuery.data.id] ?? null,
                };
            }, [
                datasetsQuery.data,
                submissionsByDataset,
                reportQuery.data,
                countsByDataset,
                context.workspace.id,
            ]);

            return {
                study: aggregate,
                image: {
                    items: aggregate.report.images.map(
                        (item): ImageDefinition => ({
                            url: item.url,
                            name: item.description,
                        })
                    ),
                },
            };
        },
    };
}
