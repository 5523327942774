import React, { Suspense, useEffect, useRef } from 'react';
import {
    Button,
    VStack,
    Flex,
    Text,
    Grid,
    GridItem,
    Box,
    Center,
    LightMode,
    Heading,
    HStack,
    Image,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import varosLogoSVG from '../../../svg/varos-logo-large.svg';
import { CardSpinner } from '../../../domain';
import type { OnboardingLayout, OnboardingStrategyLayout } from '../../route/onboarding';
import { OnboardingLayoutConfig } from './onboardingLayoutConfig';
import { buildItemProps, buildRouteProps } from './onboardingLayoutFactory';

export function createOnboardingLayout(config: OnboardingLayoutConfig): OnboardingLayout {
    const { UI } = config;
    const ItemInstance: OnboardingStrategyLayout = {
        Container(props) {
            const { content } = buildItemProps(ItemInstance, props);
            // if (content.isLoading) {
            //     return (
            //         <Center h="full" w="full">
            //             <CardSpinner />
            //         </Center>
            //     );
            // }
            return (
                <VStack
                    w="full"
                    py={{ base: 12, lg: 12 }}
                    px={{ base: 12, lg: 12 }}
                    // borderColor="blackAlpha.100"
                    borderColor="#e0e6ea"
                    borderWidth={1}
                    borderStyle="solid"
                    borderRadius="lg"
                    // bg="blackAlpha.50"
                    bg="#f6f8fa"
                >
                    <VStack align="start" spacing={4} w="full">
                        <HStack
                            spacing={8}
                            w="full"
                            justify="space-between"
                            align="start"
                        >
                            <VStack flex={2} align="start" spacing={2}>
                                <Heading>{props.title}</Heading>
                                {props.description && (
                                    <Box color="blackAlpha.700" fontSize="lg">
                                        {props.description}
                                    </Box>
                                )}
                            </VStack>
                            <Box display="flex" flex={1} justifyContent="flex-end">
                                {props.rightContent}
                                {/* <Button
                                    // onClick={props.onSubmit}
                                    minW={16}
                                    colorScheme="green"
                                    rightIcon={<ArrowForwardIcon />}
                                >
                                    Continue
                                </Button> */}
                            </Box>
                        </HStack>
                        <Suspense
                            fallback={
                                <Center w="full" p={12}>
                                    <CardSpinner />
                                </Center>
                            }
                        >
                            <Box w="full">{content.children}</Box>
                        </Suspense>
                    </VStack>
                </VStack>
            );
        },
        Content(props) {
            return <></>;
        },
        Button(props) {
            const { variant, ...rest } = props;
            if (variant === 'continue') {
                return (
                    <UI.AsyncButton
                        minW={16}
                        colorScheme="green"
                        rightIcon={<ArrowForwardIcon />}
                        {...rest}
                    />
                );
            }
            return (
                <UI.AsyncButton
                    {...props}
                    leftIcon={<ArrowBackIcon />}
                    // variant="solid"
                    // bg="white"
                    minW={16}
                />
            );
        },
    };

    const Instance: OnboardingLayout = {
        Item: ItemInstance,
        Container(props) {
            const { navigation, steplist, steps, session, context } = buildRouteProps(
                Instance,
                props
            );
            return (
                <LightMode>
                    <Box bg="white" h="full" color="blackAlpha.900" overflowY="scroll">
                        <Grid
                            h="full"
                            gridTemplateRows="min-content min-content"
                            gap={4}
                            // pb={32}
                        >
                            <GridItem>
                                <Flex
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                    borderColor="whiteAlpha.300"
                                    borderBottomWidth={2}
                                    borderStyle="solid"
                                    p={4}
                                >
                                    <VStack alignItems="flex-start">
                                        <HStack spacing={4}>
                                            {/* <Link to="/u"> */}
                                            <Box w={24}>
                                                <Image src={varosLogoSVG} />
                                            </Box>
                                            {/* </Link> */}
                                            <Box>{navigation.children}</Box>
                                        </HStack>
                                    </VStack>
                                    <VStack alignItems="flex-end">
                                        {session.children}
                                    </VStack>
                                </Flex>
                            </GridItem>
                            <GridItem
                                // mt="6vh"
                                position="relative"
                                pb={32}
                                mx="auto"
                                // minW="50vw"
                            >
                                <VStack
                                    // w="full"
                                    w={{
                                        base: '100vw',
                                        lg: '85vw',
                                        xl: '75vw',
                                        '2xl': '66vw',
                                    }}
                                    // p={12}
                                    // borderColor="#e0e6ea"
                                    // borderWidth={1}
                                    // borderStyle="solid"
                                    // borderRadius="lg"
                                    // bg="#f6f8fa"
                                >
                                    <VStack w="full" spacing={6} align="start">
                                        {context && (
                                            <Box px={{ base: 12, lg: 0 }}>
                                                {context.children}
                                            </Box>
                                        )}
                                        <HStack justify="center" w="full">
                                            <Suspense
                                                fallback={
                                                    <HStack
                                                        h="full"
                                                        align="center"
                                                        p={16}
                                                    >
                                                        <CardSpinner />
                                                    </HStack>
                                                }
                                            >
                                                {steps
                                                    .filter(
                                                        (item) =>
                                                            item.id === steplist.selected
                                                    )
                                                    .map((item) => (
                                                        <React.Fragment key={item.id}>
                                                            {item.children}
                                                        </React.Fragment>
                                                    ))}
                                            </Suspense>
                                        </HStack>
                                    </VStack>
                                </VStack>
                            </GridItem>
                        </Grid>
                    </Box>
                </LightMode>
            );
        },
        Content(props) {
            return <>{props.children}</>;
        },
        Navigation(props) {
            return <>{props.children}</>;
        },
        Session(props) {
            return <>{props.children}</>;
        },
        Context(props) {
            return <>{props.children}</>;
        },
        Step: {
            List(props) {
                return <>{props.children}</>;
            },
            Item(props) {
                return <>{props.children}</>;
            },
        },
        Tab: {
            List(props) {
                return <>{props.children}</>;
            },
            Item(props) {
                return <>{props.children}</>;
            },
        },
    };

    return Instance;
}
