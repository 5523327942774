import { ReportDefinitions } from '../../../config';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { ReportV2Api, ReportV2Dto } from '../../../api';
import { MockEnhancerConfig } from '../mockConfig';

export function createMockReportEnhancer(
    init: MockEnhancerConfig
): ApplicationEntryEnhancer {
    const overrides: ReportV2Dto[] = [...Object.values(ReportDefinitions)];
    return (create) => (config) => {
        function enhanceReportApi(api: ReportV2Api): ReportV2Api {
            return {
                ...api,
                report: {
                    ...api.report,
                    async list(context, query, options) {
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                        return {
                            data: overrides,
                        };
                    },
                    async get(context, id) {
                        const found = overrides.find((candidate) => candidate.id === id);
                        if (found) {
                            await new Promise((resolve) => setTimeout(resolve, 600));
                            return found;
                        }
                        throw new Error(`report ${id} not found`);
                        // return api.report.get(context, id);
                    },
                },
            };
        }

        return create({
            ...config,
            api: {
                ...config.api,
                createReportApiV2(...args) {
                    const api = config.api.createReportApiV2(...args);
                    return enhanceReportApi(api);
                },
            },
        });
    };
}
