import { z } from 'zod';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useRedirect } from '../../../../../../domain';
import { SettingAccountItemProviderConfig } from '../../../../../route';
import { SubscriptionEditFormOptions } from '../../../../../view/billing';
import { SettingSubscriptionConfig } from '../subscriptionConfig';
import {
    SettingSubscriptionEditController,
    SettingSubscriptionEditDeps,
    SettingSubscriptionEditLoader,
} from './editInterface';
import { SettingSubscriptionEditContainerProps } from './editProps';
import { PLAN_URL_KEY } from './editConstant';

export function createSettingSubscriptionEditContainer(
    init: Pick<SettingSubscriptionConfig, 'form' | 'infra' | 'service' | 'repository'>,
    config: Pick<SettingAccountItemProviderConfig, 'api'>,
    loader: SettingSubscriptionEditLoader,
    controller: SettingSubscriptionEditController
): React.FC<SettingSubscriptionEditContainerProps> {
    const {
        infra: { toaster },
        service: {
            subscription: { useUpdate },
        },
        form: {
            subscription: { edit: editForm },
        },
        repository: { method, charge },
    } = init;
    const {
        api: { useContext },
    } = config;

    // TODO integrate this with DI
    const redirectPortal = useRedirect('portal');

    const ParamSchema = z
        .object({
            [PLAN_URL_KEY]: z.string(),
        })
        .partial();

    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const location = useLocation();

        const [searchParams, setSearchParams] = useSearchParams();

        const viewOptions = useMemo<SubscriptionEditFormOptions>(() => {
            const raw = Object.fromEntries(searchParams.entries());
            const parsed = ParamSchema.safeParse(raw);
            return { initialValues: parsed.success ? parsed.data : {} };
        }, [searchParams]);

        const viewData = loader.useLoad(viewContext);
        const viewDeps: SettingSubscriptionEditDeps = {
            searchParams: [searchParams, setSearchParams],
            edit: {
                location: useLocation(),
                form: editForm.useForm(viewContext, viewData, viewOptions),
                mutation: {
                    method: {
                        portal: method.usePortal(viewContext),
                    },
                    update: useUpdate(viewContext, viewData),
                    startPaymentSession: charge.useCreateSession(viewContext),
                },
            },
            toast: toaster.useToast(),
        };
        const viewProps = controller.useProps(viewContext, viewDeps, viewData, {
            onPortal() {
                redirectPortal.actions.redirect({
                    type: 'portal',
                    returnPath: location.pathname,
                });
            },
        });
        return <Component {...viewProps}>{children}</Component>;
    };
}
