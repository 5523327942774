import { useContext } from 'react';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { OrganizationRouteController } from '../../../route';
import { FeatureFlagContext } from '../featureFlagConstant';

export function createFeatureFlagPeersMiddleware(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceController(
            controller: OrganizationRouteController
        ): OrganizationRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const context = useContext(FeatureFlagContext);
                    if (!context) {
                        throw new Error(`feature flag context not found`);
                    }
                    return {
                        ...viewProps,
                        peersUrl: context.flags.peers?.enabled
                            ? viewProps.peersUrl
                            : null,
                    };
                },
            };
        }
        return create({
            ...config,
            route: {
                ...config.route,
                createOrganizationRoute(routeConfig) {
                    return config.route.createOrganizationRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            createController(...args) {
                                const controller = routeConfig.providers.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    });
                },
                createOrganizationWorkspaceRoute(routeConfig) {
                    return config.route.createOrganizationRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            createController(...args) {
                                const controller = routeConfig.providers.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    });
                },
            },
        });
    };
}
